import React from 'react';
import { PartialEarlySettlementDetails } from '@cp-sk/common';
import { Card, Layout } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { Moment } from 'moment';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export type ComparisonSectionProps = {
    partialEarlySettlementDetails: PartialEarlySettlementDetails;
};

export const ComparisonSection: React.FC<ComparisonSectionProps> = ({ partialEarlySettlementDetails }) => {
    const { t, f } = useTranslationWithFormatting('partial-early-settlement');

    const getClassName = (newValue: Moment | number, oldValue: Moment | number): string => {
        return newValue < oldValue ? 'u-text-success' : '';
    };

    const currentContractInformationList: DefinitionListItem[] = [
        {
            label: t('quote-details.comparison.current.monthly-rate'),
            value: f(partialEarlySettlementDetails.monthlyRate, TranslationFormat.CURRENCY),
        },
        {
            label: t('quote-details.comparison.current.remaining-installments'),
            value: f(partialEarlySettlementDetails.instalmentsToSettle, TranslationFormat.NUMBER),
        },
        {
            label: t('quote-details.comparison.current.contract-end-date'),
            value: f(partialEarlySettlementDetails.contractEndDate, TranslationFormat.DATE),
        },
        {
            label: t('quote-details.comparison.current.outstanding-capital'),
            value: f(partialEarlySettlementDetails.outstandingCapital, TranslationFormat.CURRENCY),
        },
    ].filter((item) => !!item.value);

    const newContractInformationList: DefinitionListItem[] = [
        {
            label: t('quote-details.comparison.new.monthly-rate'),
            value: (
                <div
                    className={getClassName(
                        partialEarlySettlementDetails.newMonthlyRate,
                        partialEarlySettlementDetails.monthlyRate ? partialEarlySettlementDetails.monthlyRate : 0,
                    )}
                >
                    {f(partialEarlySettlementDetails.newMonthlyRate, TranslationFormat.CURRENCY)}
                </div>
            ),
        },
        {
            label: t('quote-details.comparison.new.remaining-installments'),
            value: (
                <div
                    className={getClassName(
                        partialEarlySettlementDetails.newInstalmentsToSettle,
                        partialEarlySettlementDetails.instalmentsToSettle,
                    )}
                >
                    {f(partialEarlySettlementDetails.newInstalmentsToSettle, TranslationFormat.NUMBER)}
                </div>
            ),
        },
        {
            label: t('quote-details.comparison.new.contract-end-date'),
            value: (
                <div
                    className={getClassName(
                        formatCpDate(partialEarlySettlementDetails.newContractEndDate).toMoment(),
                        formatCpDate(partialEarlySettlementDetails.contractEndDate).toMoment(),
                    )}
                >
                    {f(partialEarlySettlementDetails.newContractEndDate, TranslationFormat.DATE)}
                </div>
            ),
        },
        {
            label: t('quote-details.comparison.new.outstanding-capital'),
            value: (
                <div
                    className={getClassName(
                        partialEarlySettlementDetails.newOutstandingCapital,
                        partialEarlySettlementDetails.outstandingCapital,
                    )}
                >
                    {f(partialEarlySettlementDetails.newOutstandingCapital, TranslationFormat.CURRENCY)}
                </div>
            ),
        },
    ].filter((item) => !!item.value);

    return (
        <Card element="article">
            <Layout equalHeight divider>
                <Layout.Item default="1/2" s="1/1">
                    <div>
                        <h6>{t('quote-details.comparison.current.headline')}</h6>
                        <DefinitionListHorizontal list={currentContractInformationList} />
                    </div>
                </Layout.Item>
                <Layout.Item default="1/2" s="1/1">
                    <div>
                        <h6>{t('quote-details.comparison.new.headline')}</h6>
                        <DefinitionListHorizontal list={newContractInformationList} />
                    </div>
                </Layout.Item>
            </Layout>
        </Card>
    );
};
