import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AmortizationTable } from '../../components/amortization-table/AmortizationTable';

export const AmortizationTablePage: React.FC = () => {
    const { t } = useTranslation('amortization-details');
    const { encryptedContractNumber } = useParams();
    const title = t('headline');
    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <AmortizationTable encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
