import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Fieldset, Layout, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { IbanStateHandler, ValidatedIbanInput } from 'components/validated-iban-input';

export type IbanViewProps = {
    goToDelete: () => void;
    goToNextStep: () => void;
    onCancel: () => void;
    isValidating: boolean;
    ibanStateHandler: IbanStateHandler;
    deleteInactive?: boolean;
};

export const IbanView: React.FC<IbanViewProps> = ({
    goToNextStep,
    onCancel,
    isValidating,
    ibanStateHandler,
    goToDelete,
    deleteInactive,
}) => {
    const { t } = useTranslation('my-profile-bank-details');

    return (
        <>
            <p dangerouslySetInnerHTML={{ __html: t('edit-view.subtitle') }} />
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedIbanInput
                                isValidating={isValidating}
                                ibanStateHandler={ibanStateHandler}
                                translationPrefix="my-profile-bank-details"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                    <ButtonContainer className={'u-mt'} center>
                        <Button secondary onClick={onCancel} testId="go-back">
                            {t('translation:editable-section-nav.back')}
                        </Button>
                        <Button
                            secondary
                            onClick={goToDelete}
                            testId="delete-button"
                            type="button"
                            disabled={deleteInactive}
                        >
                            {t(`translation:editable-section-nav.delete`)}
                        </Button>
                        <Button onClick={goToNextStep} testId="go-to-confirmation-view">
                            {t('translation:editable-section-nav.submit')}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
            </Fieldset>
        </>
    );
};
