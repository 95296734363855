import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { JointEarlySettlement } from '../../components/contracts/early-settlement/JointEarlySettlement';

export const EarlySettlementPage: React.FC = () => {
    const { t } = useTranslation('joint-early-settlement');
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();
    const title = t('headline');
    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <JointEarlySettlement encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
