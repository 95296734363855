import React from 'react';
import { Layout, ContentSection } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export const EmptyChangeLogs: React.FC = () => {
    const { t } = useTranslation('change-log-request');
    return (
        <ContentSection pageWrapSize="medium">
            <Layout>
                <Layout.Item className={'u-text-center u-font-size-fs6'}>{t('error-message')}</Layout.Item>
            </Layout>
        </ContentSection>
    );
};
