import React, { useState } from 'react';
import { ContentSection, Layout, Logo, PageWrap } from '@vwfs-bronson/bronson-react';
import { RegistrationResult } from '@cp-sk/common';
import { Identification } from './identification';
import { SmsIdentification } from './sms-identification';
import { BusinessFormProps, CustomerType, PersonFormProps } from './identification/types';

type ViewTypes = 'REGISTRATION' | 'SMS_CONFIRMATION';

type CustomerRegistration = {
    customerFormData?: PersonFormProps | BusinessFormProps;
    customerType?: CustomerType;
    registrationResult?: RegistrationResult;
};

export const RegistrationUi: React.FC = () => {
    const [currentView, setCurrentView] = useState<ViewTypes>('REGISTRATION');
    const [customerRegistrationData, setCustomerRegistrationData] = useState<CustomerRegistration>({});

    const onSubmitSuccess = (
        registrationResult: RegistrationResult,
        customerType: CustomerType,
        customerFormData: PersonFormProps | BusinessFormProps,
    ): void => {
        setCustomerRegistrationData({
            customerType,
            registrationResult,
            customerFormData,
        });
        setCurrentView('SMS_CONFIRMATION');
    };

    const { customerType, customerFormData, registrationResult } = customerRegistrationData;

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                <Layout className={'u-mt-large'}>
                    <Layout.Item default={'1/1'}>
                        <Logo className={'u-block-center'} />
                    </Layout.Item>
                    {currentView === 'REGISTRATION' && <Identification onSubmitSuccess={onSubmitSuccess} />}
                    {currentView === 'SMS_CONFIRMATION' &&
                        registrationResult?.customerId &&
                        customerType &&
                        customerFormData && (
                            <SmsIdentification
                                customerType={customerType}
                                customerRegistrationResult={registrationResult}
                                customerFormData={customerFormData}
                            />
                        )}
                </Layout>
            </PageWrap>
        </ContentSection>
    );
};
