import React from 'react';
import { EditStatus } from '@cp-sk/common';
import { useTranslation } from 'react-i18next';
import { DataOverview, DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { isUndefined } from 'lodash';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';

export type ConsultViewProps = {
    iban?: string;
    startEditing: () => void;
    lastEditStatus: EditStatus | undefined;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    iban,
    startEditing,
    lastEditStatus = EditStatus.NOT_PERFORMED,
}) => {
    const { t } = useTranslation('my-profile-bank-details');

    const itemList: DefinitionListItem[] = [
        {
            label: t('iban.label'),
            value: iban || '-',
            testId: 'bank-account-section-iban',
        },
    ];

    const isIbanNotDefined = isUndefined(iban);
    const buttonLabel = t(`button.${isIbanNotDefined ? 'add' : 'edit'}`);

    return (
        <DataOverview
            title={t('headline')}
            buttonLabel={buttonLabel}
            buttonProps={{ onClick: startEditing, testId: 'edit-button' }}
        >
            <DefinitionList split={true} list={itemList} />
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
        </DataOverview>
    );
};
