import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-5/frontend-ui';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useLogin } from '../../auth/useLogin';
import {
    changeLogRequestPagePath,
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    landingPagePath,
    legalNoticePath,
    logoutPath,
    myDocumentsPagePath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    privacyPolicyPath,
    registrationPagePath,
} from './paths';

const pagesWithoutNavbar = [registrationPagePath()];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    registrationPagePath(),
    privacyPolicyPath(),
];

function publicNavigationLinks(t: TFunction, login: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.login'),
            onClick: login,
        },
    ];
}

function privateNavigationLinks(t: TFunction, onLogout: () => void): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: myDocumentsPagePath(),
            label: t('navigation.my-documents'),
        },
        {
            url: changeLogRequestPagePath(),
            label: t('navigation.change-requests'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            url: myRequestsPagePath(),
            label: t('navigation.requests'),
        },
        {
            label: t('navigation.logout'),
            onClick: onLogout,
        },
    ];
}

export type NavigationBarProps = {
    isAuthenticated?: boolean;
};

export const NavigationBar: React.FC<NavigationBarProps> = ({ isAuthenticated }) => {
    const { t } = useTranslation('navigation');
    const login = useLogin();
    const location = useLocation();
    const currentPageName = location.pathname.split('/')[1];

    const { isAuthenticated: isAuthenticatedUser, logout } = useAuthentication();
    const { onAction: onLoginAction } = useAnalyticsActionTracker('login');
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');
    const navigationItems = (
        isAuthenticated || isAuthenticatedUser
            ? privateNavigationLinks(t, () => {
                  onLogoutAction(currentPageName);
                  return logout({ redirectUri: `${window.location.origin}${logoutPath()}?type=manual` });
              })
            : publicNavigationLinks(t, () => {
                  onLoginAction(currentPageName);
                  return login();
              })
    ).map((navItem) => ({
        ...{ isActive: location.pathname === navItem.url },
        ...navItem,
    }));

    const currentPathName = location.pathname;

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const navigation: SiteNavProps = {
        navigationItems,
    };

    const logoRedirectPath = isAuthenticated ? dashboardPagePath() : landingPagePath();

    return (
        <NavigationBarShared
            logoAltText={isAuthenticated || isAuthenticatedUser ? t('navigation.dashboard') : t('navigation.home')}
            navigation={navigation}
            onLogoClickPath={logoRedirectPath}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
        />
    );
};
