import React, { useState } from 'react';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { ContactDetails, EditStatus, getContactDetailsEndpoint } from '@cp-sk/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
    withAutoScroll,
} from '@cp-shared-5/frontend-ui';
import { validationSchema } from './validationSchema';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { NoConnectionView } from '../no-connection-view/NoConnectionView';
import { CpDataApi } from '../../../../cp-xhr';
import { getPhoneWithoutZeroOnBeginning, removeWhitespaces } from '../helper';
import { isEmpty } from 'lodash';

export type EditViewProps = {
    contactDetails?: ContactDetails;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedContactDetails?: ContactDetails) => void;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(({ contactDetails, cancelEditing, finishEditing }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation('my-profile-contact-details');

    const trackingSection = 'Contact';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileContactTypedIn',
    });
    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');

    const getInitialErrors = (values: { [k: string]: string | undefined }) =>
        ['email', 'mobilePhone'].filter((element) => !values[element] || values[element] === '').join(', ');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);

    const { email, mobilePhone, homePhone } = contactDetails || {
        email: '',
        mobilePhone: '',
        homePhone: '',
    };

    const initialValues: ContactDetails = {
        email,
        mobilePhone: getPhoneWithoutZeroOnBeginning(mobilePhone),
        homePhone: getPhoneWithoutZeroOnBeginning(homePhone),
    };

    const onSubmit = (changeValues: ContactDetails): void => {
        const preparedChangeValues = {
            ...changeValues,
            mobilePhone: removeWhitespaces(changeValues.mobilePhone),
            homePhone: removeWhitespaces(changeValues.homePhone),
        };
        setIsSubmitting(true);

        CpDataApi.put(getContactDetailsEndpoint(), preparedChangeValues)
            .then(() => {
                const updatedContact = { ...contactDetails, ...preparedChangeValues };
                finishEditing(EditStatus.SUCCESS, updatedContact);
            })
            .catch(() => {
                finishEditing(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <DataOverview title={t('edit-view.title')}>
            {!contactDetails ? (
                <NoConnectionView cancelEditing={cancelEditing} />
            ) : (
                <>
                    <Notification
                        status={NotificationStatus.info}
                        text={t('consult-view.info-alert')}
                        className={`u-mb`}
                    />
                    <UiBlockingSpinner isBlocking={isSubmitting}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema(t)}
                            onSubmit={onSubmit}
                        >
                            {({ submitForm, errors, touched, values }) => (
                                <Form
                                    onSubmit={preventSubmit()}
                                    data-testid="edit-form"
                                    onChange={() => onTyping(errors, touched)}
                                >
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item>
                                                    <ValidatedInput
                                                        label={t('edit-view.email.label')}
                                                        name="email"
                                                        testId="email"
                                                        type="email"
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t('edit-view.mobile-phone.label')}
                                                        name="mobilePhone"
                                                        testId="mobile-phone"
                                                        addonText={t('edit-view.phone-prefix')}
                                                        reversed
                                                        type="tel"
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t('edit-view.home-phone.label')}
                                                        name="homePhone"
                                                        testId="home-phone"
                                                        addonText={t('edit-view.phone-prefix')}
                                                        reversed
                                                        type="tel"
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                    </Fieldset>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    secondary
                                                    onClick={cancelEditing}
                                                    testId="cancel-button"
                                                    type="button"
                                                >
                                                    {t('translation:editable-section-nav.cancel')}
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        submitForm();
                                                        const initialErrors = getInitialErrors(values);
                                                        if (!isEmpty(errors)) {
                                                            const errorsList = getErrors(errors);
                                                            onValidationError(errorsList);
                                                        } else if (!!initialErrors) {
                                                            onValidationError(initialErrors);
                                                        }
                                                    }}
                                                    testId="submit-button"
                                                    type="submit"
                                                >
                                                    {t('translation:editable-section-nav.submit')}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                            )}
                        </Formik>
                    </UiBlockingSpinner>
                </>
            )}
        </DataOverview>
    );
}, 'ContactSectionEditView');
