import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { getValue } from '@cp-sk/common';
import { CleaveInput, preventSubmit, Spinner } from '@cp-shared-5/frontend-ui';
import { initialValues, PartialPaymentValues, validationSchema } from './validationSchema';
import { ErrorNotification } from '../../../notifications/error/ErrorNotification';

export type PartialPaymentSectionProps = {
    totalDueAmount?: number;
    onSubmit: (paymentAmount: number) => void;
    isSubmitting?: boolean;
    isSubmitError?: boolean;
};

export const PartialPaymentSection: React.FC<PartialPaymentSectionProps> = ({
    totalDueAmount,
    onSubmit,
    isSubmitting,
    isSubmitError,
}) => {
    const [inputVisible, setInputVisible] = useState(false);
    const history = useHistory();
    const { t } = useTranslation('payment-details');
    const translationPrefix = 'overview.partial-payment-section';

    const goBack = () => {
        history.goBack();
    };

    if (!totalDueAmount)
        return (
            <ButtonContainer center className="u-mt">
                <Button inContainer secondary onClick={goBack}>
                    {t('back-button')}
                </Button>
            </ButtonContainer>
        );

    const cleaveOptions = {
        numeral: true,
        delimiter: '.',
        numeralDecimalMark: ',',
    };

    const handleSubmit = (values: PartialPaymentValues) => {
        const paymentAmount = values.paymentAmount ? getValue(values.paymentAmount) || 0 : totalDueAmount;
        onSubmit(paymentAmount);
    };

    return (
        <>
            <h4>{t(`${translationPrefix}.title`)}</h4>
            <p>{t(`${translationPrefix}.description`)}</p>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, totalDueAmount)}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, setFieldTouched, submitForm }) => (
                    <Form onSubmit={preventSubmit()} data-testid="partial-payment-form">
                        <Layout>
                            <Layout.Item default="1/1" xs="1/1">
                                <Button
                                    secondary
                                    onClick={() => {
                                        setInputVisible(!inputVisible);
                                        setFieldValue('paymentAmount', '');
                                        setFieldTouched('paymentAmount', false);
                                    }}
                                    testId="toggle-payment-input-button"
                                >
                                    {inputVisible
                                        ? t(`${translationPrefix}.cancel`)
                                        : t(`${translationPrefix}.button-text`)}
                                </Button>
                            </Layout.Item>
                            {inputVisible && (
                                <Layout.Item default="1/5" m="1/4" s="1/2" xs="1/1">
                                    <CleaveInput
                                        cleaveOptions={cleaveOptions}
                                        name="paymentAmount"
                                        label=""
                                        addonText={t(`${translationPrefix}.currency`)}
                                        placeholder={t(`${translationPrefix}.input-placeholder`)}
                                    />
                                </Layout.Item>
                            )}
                        </Layout>
                        {isSubmitError && <ErrorNotification testId={'submit-error'} className={`u-mt`} />}
                        <ButtonContainer center className="u-mt">
                            <Button inContainer secondary onClick={goBack}>
                                {t('back-button')}
                            </Button>
                            {isSubmitting ? (
                                <Spinner center />
                            ) : (
                                <Button inContainer onClick={submitForm}>
                                    {t('proceed-to-payment-button')}
                                </Button>
                            )}
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
};
