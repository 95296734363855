import { PartialBy } from '../utils';

export enum ChangeStatus {
    APPLIED_EN = 'A',
    APPLIED_SK = 'V',
    PENDING = 'P',
    REJECTED = 'Z',
    EXPIRED = 'E',
}

export interface ChangeRequestLogEntryWithId extends ChangeRequestLogEntry {
    id: string;
}

export interface ChangeRequestLogEntry extends ChangeRequestLogEntryDetails {
    changeStatus: ChangeStatus;
    changeStatusLabel: string;
    dateOfReceipt: string;
    typeOfRequest: string;
}

export interface ChangeRequestLogEntryDetails {
    contractNumber: number | string;
    effectiveDate: string;
    licensePlate: string;
    dateOfApplication: string;
}

export type ChangeRequestLogEntryOptional = PartialBy<
    ChangeRequestLogEntry,
    'contractNumber' | 'licensePlate' | 'dateOfApplication'
>;
