import React from 'react';
import { isEmpty } from 'lodash';
import { ServiceAndMaintenance as ServiceAndMaintenanceType, ServiceAndMaintenanceProductType } from '@cp-sk/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

export const ServiceAndMaintenance: React.FC<{ serviceAndMaintenance?: ServiceAndMaintenanceType }> = ({
    serviceAndMaintenance,
}) => {
    const { t, f } = useTranslationWithFormatting('bundled-products');

    if (!serviceAndMaintenance || isEmpty(serviceAndMaintenance)) {
        return null;
    }

    const { productType, productName, status, startDate, endDate, servicePackage, tireClass } = serviceAndMaintenance;

    const itemList: DefinitionListItem[] = [
        {
            label: t('service-and-maintenance.status'),
            value: status || '-',
            testId: 'service-and-maintenance-status',
        },
        {
            label: t('service-and-maintenance.start-date'),
            value: startDate ? f(startDate, TranslationFormat.DATE) : '-',
            testId: 'service-and-maintenance-start-date',
        },
        {
            label: t('service-and-maintenance.end-date'),
            value: endDate ? f(endDate, TranslationFormat.DATE) : '-',
            testId: 'service-and-maintenance-end-date',
        },
    ];

    if (productType === ServiceAndMaintenanceProductType.S)
        itemList.push({
            label: t('service-and-maintenance.service-package'),
            value: servicePackage || '-',
            testId: 'service-and-maintenance-service-package',
        });
    else
        itemList.push({
            label: t('service-and-maintenance.tire-class'),
            value: tireClass || '-',
            testId: 'service-and-maintenance-tire-class',
        });

    return (
        <div className="u-mt">
            <h5>{productName}</h5>
            <DefinitionList split={true} list={itemList} />
        </div>
    );
};
