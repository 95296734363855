import { LandingPageMarketingCard, getLandingPageMarketingCardEndpoint } from '@cp-sk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LandingPageMarketingCard[]>({
    contentName: 'landingPageMarketingCards',
    contentEndpoint: getLandingPageMarketingCardEndpoint,
});

export default reducer;
export const fetchLandingPageMarketingCards = fetchContent;
