import { formatCpDate } from '@cp-shared-5/common-utilities';
import { LegalFooter as LegalFooterUi, LegalFooterLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useLegalFooter } from './useLegalFooter';

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();
    const currentYear = formatCpDate().toMoment().year();

    return (
        <LegalFooterWithHandlers
            copyrightText={legalFooter?.copyrightText && `${legalFooter?.copyrightText} ${currentYear}`}
            legalText={''}
            isLoading={isLoading}
            links={legalFooter?.links || []}
            hasError={!!loadingError}
            loadingPlaceholder={<LegalFooterLoadingPlaceholder />}
        />
    );
};
