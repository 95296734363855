import { AmortizationDetails, Contract, FinancialDetails } from '@cp-sk/common';
import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { AmortizationTableHeader } from '../header/AmortizationTableHeader';
import { useTranslation } from 'react-i18next';
import { AmortizationTableInstallmentOverview } from '../installment-overview/AmortizationTableInstallmentOverview';

export type AmortizationTableUiProps = {
    contract?: Contract;
    amortizationDetails?: AmortizationDetails[];
    financialDetails?: FinancialDetails;
};

export type AmortizationTableProps = {
    encryptedContractId: string;
};

export const AmortizationTableUi: React.FC<AmortizationTableUiProps> = ({
    contract,
    amortizationDetails,
    financialDetails,
}) => {
    const { t } = useTranslation('amortization-details');
    // Check for contract being available, otherwise it might be that fetching hasn't even started
    if (!contract || !amortizationDetails || !financialDetails) {
        return null;
    }

    const { brandModelType = '', contractNumber = '', contractType, licensePlate, productName } = contract;

    return (
        <Layout>
            <Layout.Item default="1/1">
                <AmortizationTableHeader
                    carInformation={brandModelType}
                    contractCategory={productName}
                    contractNumber={contractNumber}
                    countryCode={t('header.country-code')}
                    registrationNumber={licensePlate}
                    financialDetails={financialDetails}
                />
            </Layout.Item>
            <Layout.Item default="1/1">
                <AmortizationTableInstallmentOverview
                    amortizationDetails={amortizationDetails}
                    contractType={contractType}
                />
            </Layout.Item>
        </Layout>
    );
};
