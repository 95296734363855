import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { LogoutSuccessUi, LogoutSuccessUiProps } from './ui';
import { useLogoutSuccess } from './useLogoutSuccess';
import { useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { useLocation } from 'react-router-dom';

const LogoutWithHandlers = withLoadingAndNoConnectionHandler(LogoutSuccessUi);

export const LogoutSuccess: React.FC = () => {
    const { cmsContent: autoLogoutSuccess, isLoading, loadingError } = useLogoutSuccess();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const logoutType = (searchParams.get('type') || 'auto') as LogoutSuccessUiProps['logoutType'];

    useAnalyticsPageViewTracker('onAutoLogout', !!autoLogoutSuccess, logoutType);

    return (
        <LogoutWithHandlers
            isLoading={isLoading}
            logoutSuccess={autoLogoutSuccess}
            hasError={!!loadingError}
            logoutType={logoutType}
        />
    );
};
