import { LicenseError, LicensePlateError, TechnicalDocumentNumberError } from '../types';
import { defaultFilesInfoError } from './FileInfoMessages';

export const defaultLicenseError: LicenseError = {
    format: 'Wrong license plate format.',
    required: 'License plate is required.',
};

export const defaultTechnicalDocumentNumberError: TechnicalDocumentNumberError = {
    format: 'Wrong number of technical document format.',
    required: 'Number of technical document is required.',
};

export const defaultLicensePlateError: LicensePlateError = {
    newLicensePlate: defaultLicenseError,
    technicalDocumentNumber: defaultTechnicalDocumentNumberError,
    files: defaultFilesInfoError,
};
