import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentDetails } from '@cp-sk/common';
import { PaymentDetailsTable } from './payment-details-table';
import { PartialPaymentSection } from './partial-payment-section';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';

type PaymentDetailsOverviewProps = {
    paymentDetails: PaymentDetails;
    onSubmit: (paymentAmount: number) => void;
    isSubmitting?: boolean;
    isSubmitError?: boolean;
};

export const PaymentDetailsOverview: React.FC<PaymentDetailsOverviewProps> = ({
    paymentDetails,
    onSubmit,
    isSubmitting,
    isSubmitError,
}) => {
    const { t } = useTranslation('payment-details');
    const translationPrefix = 'overview';
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    };
    const DetailsUnavailableAlert = ({ detailsUnavailableAlert }: PaymentDetails): JSX.Element => {
        return (
            <>
                {detailsUnavailableAlert?.headline && detailsUnavailableAlert?.description ? (
                    <Notification status={NotificationStatus.info} headline={detailsUnavailableAlert.headline}>
                        {detailsUnavailableAlert.description}
                    </Notification>
                ) : null}
                <ButtonContainer center className="u-mt">
                    <Button inContainer secondary onClick={goBack}>
                        {t('back-button')}
                    </Button>
                </ButtonContainer>
            </>
        );
    };

    return (
        <div className="u-mv">
            <h4>{t(`${translationPrefix}.title`)}</h4>
            {paymentDetails.detailsAvailable !== false ? (
                <>
                    <p>{t(`${translationPrefix}.description`)}</p>
                    <PaymentDetailsTable paymentDetails={paymentDetails} />
                    <PartialPaymentSection
                        totalDueAmount={paymentDetails.totalDueAmount}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        isSubmitError={isSubmitError}
                    />
                </>
            ) : (
                <DetailsUnavailableAlert {...paymentDetails} />
            )}
        </div>
    );
};
