import React from 'react';
import { isEmpty } from 'lodash';
import { DataOverview } from '@cp-shared-5/frontend-ui';
import { BundledProducts } from '@cp-sk/common';
import { CarInsurance } from '../car-insurance';
import { OtherInsurance } from '../other-insurance';
import { ServiceAndMaintenance } from '../service-and-maintenance';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

export const BundledProductsUi: React.FC<{ bundledProducts?: BundledProducts; isOperativeLeasing: boolean }> = ({
    bundledProducts,
    isOperativeLeasing,
}) => {
    const { t } = useTranslationWithFormatting('bundled-products');
    if (!bundledProducts || isEmpty(bundledProducts)) {
        return null;
    }

    const { carInsurance, otherInsurance, serviceAndMaintenance } = bundledProducts;

    const carInsuranceSection = carInsurance?.length && (
        <DataOverview title={t('car-insurance.headline')} withoutCardEffect={true}>
            {carInsurance?.map((carInsurance, index) => (
                <CarInsurance
                    carInsurance={carInsurance}
                    isOperativeLeasing={isOperativeLeasing}
                    key={`${carInsurance.productName}-${index}`}
                />
            ))}
        </DataOverview>
    );
    const otherInsuranceSection = otherInsurance && (
        <DataOverview title={t('other-insurance.headline')} withoutCardEffect={true}>
            <OtherInsurance otherInsurance={otherInsurance} isOperativeLeasing={isOperativeLeasing} />
        </DataOverview>
    );
    const serviceAndMaintenanceSection =
        isOperativeLeasing && serviceAndMaintenance?.length ? (
            <DataOverview title={t('service-and-maintenance.headline')} withoutCardEffect={true}>
                {serviceAndMaintenance?.map((serviceAndMaintenance, index) => (
                    <ServiceAndMaintenance
                        serviceAndMaintenance={serviceAndMaintenance}
                        key={`${serviceAndMaintenance.productName}-${index}`}
                    />
                ))}
            </DataOverview>
        ) : null;

    return (
        <>
            {carInsuranceSection}
            {otherInsuranceSection}
            {serviceAndMaintenanceSection}
        </>
    );
};
