import { StaticPageLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { LegalNoticeUi } from './ui';
import { useLegalNotice } from './useLegalNotice';

const LegalNoticeWithHandlers = withLoadingAndNoConnectionHandler(LegalNoticeUi);

export const LegalNotice: React.FC = () => {
    const { cmsContent: legalNotice, isLoading, loadingError } = useLegalNotice();

    return (
        <LegalNoticeWithHandlers
            isLoading={isLoading}
            legalNotice={legalNotice}
            hasError={!!loadingError}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
