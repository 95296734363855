import React, { useState } from 'react';
import {
    Button,
    ButtonContainer,
    DataOverview,
    Fieldset,
    Form,
    Layout,
    OrderedList,
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    ChangePrivateIdentificationRequest,
    DATE_FORMAT,
    EditStatus,
    FileInfo,
    getPrivateIdentificationEndpoint,
    IdCardType,
    Identification,
    PrivateCustomerData,
} from '@cp-sk/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    ValidatedDate,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { CpDataApi } from '../../../../../cp-xhr';
import { SummaryView } from '../summary-view/SummaryView';
import { uploadFilesDirectToMediaStorage } from '@cp-shared-5/frontend-integration';
import { FormValues, getInitialValues } from './initialValues';
import { TitleSelection } from './title-selection';
import { IdCardTypeSelection } from '../../../id-card-type-selection/IdCardTypeSelection';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import { DOCTYPE_ID_BACK, DOCTYPE_ID_FRONT } from '../../../addresses-section/utils';
import { isEmpty } from 'lodash';
import { getErrors, IDENTIFICATION_SECTION, useOnEditIdentificationActionTrackers } from '../trackingUtils';
import { FileUpload } from '../../../../file-upload';

export type PrivateFormProps = {
    identification: Identification;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedIdentification?: PrivateCustomerData) => void;
};

export const PrivateForm: React.FC<PrivateFormProps> = ({ identification, cancelEditing, finishEditing }) => {
    const { t, i18n } = useTranslation('my-profile');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const translationPrefix = 'identification.edit-view.private';
    const language = i18n.languages[0];

    const { onTyping, onValidationError, onShowConfirmation, onConfirmationCancel } =
        useOnEditIdentificationActionTrackers();

    const getInitialErrors = (values: { [k: string]: string | File[] | object }): string => {
        const notPrefilledErrorsList = ['title', 'firstName', 'surname']
            .filter((element: string): boolean => !values[element])
            .join(', ');
        const errors = 'idCard.type, idCard.number, idCard.issueDate, idCard.issueCity, frontOfDocument';
        return !!notPrefilledErrorsList ? `${notPrefilledErrorsList}, ${errors}` : errors;
    };

    const {
        title = '',
        firstName = '',
        surname = '',
        birthDate = '',
        idCardNumber = '',
    } = identification.data as PrivateCustomerData;

    const initialValues: FormValues = getInitialValues(title, firstName, surname, idCardNumber);

    const onSubmit = (): void => {
        onShowConfirmation(IDENTIFICATION_SECTION);
        setShowConfirmation(true);
    };

    const onConfirm = (values: FormValues): void => {
        setShowConfirmation(false);
        setIsSubmitting(true);
        const { title, firstName, surname, idCard, frontOfDocument, backOfDocument } = values;

        const doAfterSuccessfulUpload = (frontOfDocumentId: string, backOfDocumentId?: string): void => {
            const files: FileInfo[] = [
                {
                    docTypeId: DOCTYPE_ID_FRONT,
                    fileId: frontOfDocumentId,
                },
            ];
            if (backOfDocumentId) {
                files.push({
                    docTypeId: DOCTYPE_ID_BACK,
                    fileId: backOfDocumentId,
                });
            }
            const body: ChangePrivateIdentificationRequest = {
                title,
                firstName,
                surname,
                idCard: {
                    type: idCard.type as IdCardType,
                    number: idCard.number,
                    issueDate: formatCpDate(idCard.issueDate, DATE_FORMAT).toCpDate(),
                    issueCity: idCard.issueCity,
                },
                files,
            };

            CpDataApi.put(getPrivateIdentificationEndpoint(), body)
                .then(() => {
                    finishEditing(EditStatus.SUCCESS, { title, firstName, surname, birthDate, idCardNumber });
                })
                .catch(() => {
                    finishEditing(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        const doWhenError = (): void => {
            setIsSubmitting(false);
            finishEditing(EditStatus.ERROR);
        };

        const promises: Array<Promise<string[]>> = [uploadFilesDirectToMediaStorage(frontOfDocument, CpDataApi)];
        if (backOfDocument.length) {
            promises.push(uploadFilesDirectToMediaStorage(backOfDocument, CpDataApi));
        }

        Promise.all(promises)
            .then(([frontOfDocumentId, backOfDocumentId]) =>
                doAfterSuccessfulUpload(frontOfDocumentId[0], backOfDocumentId && backOfDocumentId[0]),
            )
            .catch(() => {
                doWhenError();
            });
    };

    const onClose = (): void => {
        onConfirmationCancel(IDENTIFICATION_SECTION);
        setShowConfirmation(false);
    };

    const getDescription = (): React.ReactNode => {
        const keys = ['step-1', 'step-2', 'step-3', 'step-4'];
        return (
            <OrderedList>
                {keys.map(
                    (key: string): React.ReactNode => (
                        <OrderedList.Item className={'u-mb-xsmall'} key={key}>
                            {t(`${translationPrefix}.description.${key}`)}
                        </OrderedList.Item>
                    ),
                )}
            </OrderedList>
        );
    };

    return (
        <DataOverview title={t(`${translationPrefix}.headline`)}>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={onSubmit}>
                    {({ submitForm, values, errors, touched }): React.ReactNode => (
                        <Form
                            onSubmit={preventSubmit()}
                            data-testid="edit-form"
                            onChange={(): void => onTyping(errors, touched)}
                        >
                            {showConfirmation ? (
                                <SummaryView
                                    customerType={'Private'}
                                    newData={values}
                                    onConfirm={(): void => onConfirm(values)}
                                    onClose={onClose}
                                />
                            ) : (
                                <>
                                    <Notification
                                        status={NotificationStatus.info}
                                        text={t(`${translationPrefix}.info`)}
                                        className={`u-mb`}
                                    />
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/1" s="1/1">
                                                    {getDescription()}
                                                </Layout.Item>
                                            </Layout>
                                            <Layout>
                                                <Layout.Item default="1/3" s="1/1">
                                                    <TitleSelection name={'title'} testId={'title'} />
                                                </Layout.Item>
                                                <Layout.Item default="1/3" s="1/1">
                                                    <ValidatedInput
                                                        label={t(`${translationPrefix}.first-name.label`)}
                                                        name="firstName"
                                                        testId="first-name"
                                                        type="text"
                                                        isMandatory
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/3" s="1/1">
                                                    <ValidatedInput
                                                        label={t(`${translationPrefix}.surname.label`)}
                                                        name="surname"
                                                        testId="surname"
                                                        type="text"
                                                        isMandatory
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/3" s="1/1">
                                                    <IdCardTypeSelection
                                                        label={t(`${translationPrefix}.id-card-type.label`)}
                                                        name={'idCard.type'}
                                                        testId={'id-card-type'}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t(`${translationPrefix}.id-card-number.label`)}
                                                        name="idCard.number"
                                                        testId="id-card-number"
                                                        type="text"
                                                        isMandatory
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedDate
                                                        name={'idCard.issueDate'}
                                                        label={t(`${translationPrefix}.id-card-issue-date.label`)}
                                                        language={language}
                                                        outputDateFormat={'d.m.Y'}
                                                        isMandatory
                                                        value={values.idCard.issueDate}
                                                        testId={'id-card-issue-date'}
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        name="idCard.issueCity"
                                                        testId="id-card-issue-city"
                                                        isMandatory
                                                        type="text"
                                                        label={t(`${translationPrefix}.id-card-issue-city.label`)}
                                                        tooltip={t(`${translationPrefix}.id-card-issue-city.tooltip`)}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                        <FileUpload
                                            name={'frontOfDocument'}
                                            label={t(`${translationPrefix}.front-of-document.label`)}
                                        />
                                        <FileUpload
                                            name={'backOfDocument'}
                                            label={t(`${translationPrefix}.back-of-document.label`)}
                                            isMandatory={false}
                                        />
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    secondary
                                                    onClick={(): void => {
                                                        cancelEditing();
                                                    }}
                                                    testId="cancel-button"
                                                    type="button"
                                                >
                                                    {t(`translation:editable-section-nav.cancel`)}
                                                </Button>
                                                <Button
                                                    onClick={async (): Promise<void> => {
                                                        await submitForm();
                                                        const initialErrors = getInitialErrors(values);
                                                        if (!isEmpty(errors)) {
                                                            const errorsList = getErrors(errors);
                                                            onValidationError(errorsList);
                                                        } else if (!values.frontOfDocument.length) {
                                                            onValidationError(initialErrors);
                                                        }
                                                    }}
                                                    testId="submit-button"
                                                    type="submit"
                                                >
                                                    {t(`translation:editable-section-nav.change`)}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </UiBlockingSpinner>
        </DataOverview>
    );
};
