import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from '@cp-shared-5/frontend-integration';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-5/common-utilities';
import { BankData, getIbanValidationEndpoint, SignedData } from '@cp-shared-5/apis';
import { IbanError, ibanValidationUtils, IbanValidator, OverpaymentManagementError } from '@cp-sk/common';
import { IbanStateHandler, SetIsValidating } from './types';

type IbanValidationServiceError = IbanError | 'INCORRECT_IBAN';

export const getValidationSchema = (
    t: TFunction,
    setIsValidating: SetIsValidating,
    ibanStateHandler: IbanStateHandler,
) => {
    const translationPrefix = 'validation';
    const messages: OverpaymentManagementError = {
        iban: { invalidFormat: t(`${translationPrefix}.invalid-iban`) },
    };

    const { savedIban, setSavedIban, setSignedBankData } = ibanStateHandler;
    const { stripWhitespaces } = ibanValidationUtils;

    const getIbanError = (errorCode: string, iban?: string): Yup.ValidationError => {
        switch (errorCode) {
            case 'INCORRECT_IBAN':
                return new Yup.ValidationError(messages.iban.invalidFormat, iban, 'iban');
            default:
                return new Yup.ValidationError(t(`${translationPrefix}.iban-validator-unavailable`), iban, 'iban');
        }
    };

    const ibanValidation = IbanValidator(messages);

    return Yup.object().shape({
        iban: ibanValidation.test('asyncIban', messages.iban.invalidFormat, async (iban?: string) => {
            if (!ibanValidation.isValidSync(iban)) {
                setSignedBankData(undefined);
                setSavedIban({});
                return true;
            }
            if (savedIban.iban === stripWhitespaces(iban)) {
                if (!savedIban.error) {
                    return true;
                }
                return getIbanError(savedIban.error, savedIban.iban);
            }
            await setIsValidating(true);
            return await CpDataApi.post(getIbanValidationEndpoint(), { iban })
                .then(({ data }: { data: SignedData<BankData> }) => {
                    const { isValid } = data.data;
                    setSignedBankData(isValid ? data : undefined);
                    setSavedIban({ iban, error: isValid ? undefined : 'INCORRECT_IBAN' });
                    return isValid;
                })
                .catch((error) => {
                    const errorCode =
                        parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationServiceError>>(error).code;
                    setSavedIban({ iban, error: errorCode });
                    return getIbanError(errorCode, iban);
                })
                .finally(() => {
                    setIsValidating(false);
                });
        }),
    });
};
