import { getContractDetailsEndpoint } from './contracts';

export function getContractDurationChangeEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/change-contract-duration`;
}

export function getCalculateContractDurationChangeEndpoint(contractId: string): string {
    return `${getContractDurationChangeEndpoint(contractId)}/calculate`;
}

export function getAcceptContractDurationChangeEndpoint(contractId: string): string {
    return `${getContractDurationChangeEndpoint(contractId)}/accept`;
}
