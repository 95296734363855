import * as Yup from 'yup';
import { OverpaymentManagementError } from '../types';
import { defaultIbanError } from '../messages';
import { ibanValidationUtils } from '../utils';
import { OverpaymentManagement } from '../../apis';

export const IbanValidator = (errors: OverpaymentManagementError = defaultIbanError): Yup.StringSchema => {
    const { isValidCountryCode, isValidLength, stripWhitespaces } = ibanValidationUtils;

    return Yup.string()
        .required(errors.iban.invalidFormat)
        .test('validCountryCode', errors.iban.invalidFormat, isValidCountryCode)
        .matches(RegExp('^[A-Z]{2}[a-zA-Z0-9_ ]*$'), errors.iban.invalidFormat)
        .test('ibanLength', errors.iban.invalidFormat, isValidLength)
        .transform((value: string): string => stripWhitespaces(value));
};

export const OverpaymentManagementValidator = (
    errors: OverpaymentManagementError = defaultIbanError,
): Yup.SchemaOf<OverpaymentManagement> => {
    return Yup.object()
        .shape({
            iban: IbanValidator(errors),
        })
        .defined();
};
