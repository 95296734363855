import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractStatus, formatAsCurrency, formatAsDate } from '@cp-sk/common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-5/frontend-ui';
import { getInactiveContractSummary } from '../utils';
import { CPDate } from '@cp-shared-5/common-utilities';

export type OperativeLeasingProps = {
    isActive: boolean;
    nextPaymentDate?: CPDate;
    nextPaymentAmount?: number;
    lastPaymentDate?: CPDate;
    status?: ContractStatus;
    startDate?: CPDate;
    endDate?: CPDate;
};

export const OperativeLeasing: React.FC<OperativeLeasingProps> = ({
    isActive,
    nextPaymentDate,
    nextPaymentAmount,
    lastPaymentDate,
    status,
    startDate,
    endDate,
}) => {
    const { t } = useTranslation('financial-contract-header');

    const paymentDateValue = nextPaymentDate ? formatAsDate(nextPaymentDate) : '-';
    const nextPaymentAmountValue = nextPaymentAmount ? formatAsCurrency(nextPaymentAmount) : '-';
    const lastPaymentDateValue = lastPaymentDate ? formatAsDate(lastPaymentDate) : '-';

    const activeItems: Array<ContractSummaryItemProps> = [
        { value: paymentDateValue || '-', label: t('next-payment-date'), testClass: 'next-payment-date' },
        {
            value: nextPaymentAmountValue,
            label: t('next-payment-amount-vat'),
            testClass: 'next-payment-amount',
        },
        {
            value: lastPaymentDateValue,
            label: t('last-payment-date'),
            testClass: 'last-payment-date',
        },
    ];

    return (
        <ContractSummary items={isActive ? activeItems : getInactiveContractSummary(t, status, startDate, endDate)} />
    );
};
