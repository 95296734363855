import { useGetContractBasedApiData } from '@cp-shared-5/frontend-integration';
import { AmortizationTableLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { dashboardPagePath } from 'components/navigation/paths';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContractDetails } from '../contracts/contract-details';
import { useContract } from '../contracts/useContract';
import { PaymentOverviewDataSelector } from './PaymentOverviewSelector';
import { fetchPaymentOverview } from './PaymentOverviewSlice';
import { PaymentOverviewUi } from './ui/PaymentOverviewUi';

const PaymentOverviewWithHandlers = withLoadingAndNoConnectionHandler(PaymentOverviewUi);

export type PaymentOverviewProps = {
    encryptedContractNumber: string;
};

export const PaymentOverview: React.FC<PaymentOverviewProps> = ({ encryptedContractNumber }) => {
    const [loadingStarted, setLoadingStarted] = useState(false);
    const history = useHistory();

    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractNumber, { encryptedContractNumber: true });

    const onLoading = useCallback(() => {
        if (isLoadingContracts) setLoadingStarted(true);
    }, [isLoadingContracts]);

    useEffect(() => {
        onLoading();
    }, [onLoading]);

    const checkContract = useCallback(() => {
        if (loadingStarted && !isLoadingContracts && !loadingErrorContract && !contract) {
            history.push(dashboardPagePath());
        }
    }, [isLoadingContracts, loadingErrorContract, loadingStarted, contract, history]);

    useEffect(() => {
        checkContract();
    }, [checkContract]);

    const {
        data: contractDetails,
        isLoading: isLoadingContractDetails,
        loadingError: loadingErrorContractDetails,
    } = useContractDetails(contract?.contractNumber || '');

    const {
        data: PaymentOverviewDetails,
        isLoading: isLoadingPaymentOverviewDetails,
        loadingError: loadingPaymentOverviewDetails,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchPaymentOverview,
        PaymentOverviewDataSelector,
        contract?._links?.paymentOverview,
    );

    const isLoading = isLoadingContracts || isLoadingPaymentOverviewDetails || isLoadingContractDetails;
    const hasError = !!loadingErrorContract || !!loadingPaymentOverviewDetails || !!loadingErrorContractDetails;

    return (
        <PaymentOverviewWithHandlers
            contract={contract}
            PaymentOverviewDetails={PaymentOverviewDetails}
            financialDetails={contractDetails?.financial}
            isLoading={isLoading}
            hasError={!!hasError}
            loadingPlaceholder={<AmortizationTableLoadingPlaceholder tileElements={3} />}
        />
    );
};
