import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading } from '@vwfs-bronson/bronson-react';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { VehicleDetails, EditStatus } from '@cp-sk/common';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';

type ConsultViewProps = {
    vehicleDetails: VehicleDetails;
    lastEditStatus: EditStatus;
    startEditing: () => void;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ vehicleDetails, lastEditStatus, startEditing }) => {
    const { t } = useTranslation('vehicle-details');

    const itemList: DefinitionListItem[] = [
        {
            label: t('license-plate'),
            value: vehicleDetails.licensePlate || '-',
            testId: 'vehicle-details-license-plate',
        },
    ];

    return (
        <>
            <Heading className={'c-data-overview__header u-pt'} level={'6'}>
                {t('license-plate')}
                <Button
                    element="a"
                    link
                    small
                    className="c-data-overview__button u-mb-none u-mt-none u-mr-small"
                    icon="true"
                    iconReversed
                    onClick={startEditing}
                    testId={'edit-button'}
                >
                    {t('translation:editable-section-nav.start')}
                </Button>
            </Heading>
            <DefinitionList split={true} list={itemList} />
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
        </>
    );
};
