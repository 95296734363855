import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { FormView, FormViewProps } from '../form-view';
import { RequestMarketingCard } from '../marketing-card';

export const RequestUi: React.FC<FormViewProps> = ({ contracts }) => {
    return (
        <Layout>
            <Layout.Item default={'2/3'} m={'1/1'}>
                <FormView contracts={contracts} />
            </Layout.Item>
            <Layout.Item default={'1/3'} m={'1/1'}>
                <RequestMarketingCard />
            </Layout.Item>
        </Layout>
    );
};
