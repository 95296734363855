import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChangeContractDuration } from 'components/contracts/change-contract-duration';

export const ChangeContractDurationPage: React.FC = () => {
    const { t } = useTranslation('change-contract-duration');
    const title = t('headline');
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <ChangeContractDuration encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
