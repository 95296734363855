import React from 'react';
import { DescriptionListAccordion, SectionHeading } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { isEmpty } from 'lodash';
import { CalculatedMileage } from '@cp-sk/common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

type NewContractDetailsProps = {
    calculatedTerms?: CalculatedMileage;
};

export const NewContractDetails: React.FC<NewContractDetailsProps> = ({ calculatedTerms }) => {
    const { t, f } = useTranslationWithFormatting('change-mileage');

    const resetTextTransformStyle = {
        textTransform: 'none' as const,
    };

    return (
        <div>
            <SectionHeading level="3" subtitle={t('new-terms.text')}>
                {t('new-terms.headline')}
            </SectionHeading>

            <DescriptionListAccordion
                headerSplit
                totalLabel={t('new-terms.total-settlement-amount')}
                totalValue={f(calculatedTerms?.totalSettlementAmount, TranslationFormat.CURRENCY)}
            >
                {(isEmpty(calculatedTerms) || (calculatedTerms && calculatedTerms?.administrationFee > 0)) && (
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        icon="semantic-info"
                        tooltip={
                            <div
                                style={resetTextTransformStyle}
                                dangerouslySetInnerHTML={{
                                    __html: t('new-terms.administration-fee.tooltip'),
                                }}
                            />
                        }
                        title={<span style={resetTextTransformStyle}>{t('new-terms.administration-fee.text')}</span>}
                        titleSplit={f(calculatedTerms?.administrationFee, TranslationFormat.CURRENCY)}
                    />
                )}
                <DescriptionListAccordion.Item
                    nonCollapsable
                    title={<span style={resetTextTransformStyle}>{t('new-terms.new-monthly-rate')}</span>}
                    titleSplit={f(calculatedTerms?.newMonthlyRate, TranslationFormat.CURRENCY)}
                />

                {(isEmpty(calculatedTerms) ||
                    (calculatedTerms && typeof calculatedTerms?.totalDueAmount === 'number')) && (
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        icon="semantic-info"
                        title={<span style={resetTextTransformStyle}>{t('new-terms.total-due-amount.text')}</span>}
                        tooltip={<div style={resetTextTransformStyle}>{t('new-terms.total-due-amount.tooltip')}</div>}
                        titleSplit={f(calculatedTerms?.totalDueAmount, TranslationFormat.CURRENCY)}
                    />
                )}
                <DescriptionListAccordion.Item
                    nonCollapsable
                    title={<span style={resetTextTransformStyle}>{t('new-terms.settlement-date')}</span>}
                    titleSplit={
                        calculatedTerms?.settlementDate &&
                        formatCpDate(calculatedTerms?.settlementDate).format('DD.MM.YYYY')
                    }
                />
            </DescriptionListAccordion>
        </div>
    );
};
