import React from 'react';
import { ChangeMileage } from 'components/contracts/change-mileage';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ChangeMileagePage: React.FC = () => {
    const { t } = useTranslation('change-mileage');
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <ChangeMileage encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
