import { IdCardError } from '../types';
import { IdCardType } from '../../apis';

export const defaultIdCardError: IdCardError = {
    issueCity: {
        required: 'Card ID issue city is required.',
    },
    issueDate: { required: 'Card ID issue date is required.', valid: 'Card ID issue date is invalid.' },
    number: {
        matches: "Card ID number isn't match to pattern.",
        required: 'Card ID number is required.',
        requiredForPassport: 'Card ID number is required.',
        unknownType: 'Card ID type is undefined, then number is invalid.',
    },
    type: { oneOf: `Card ID type must be one of: ${Object.values(IdCardType)}`, required: 'Card ID type is required.' },
};
