import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Fieldset, Layout, ButtonContainer, Heading } from '@vwfs-bronson/bronson-react';
import { IbanStateHandler, ValidatedIbanInput } from 'components/validated-iban-input';

export type IbanViewProps = {
    goToNextStep: () => void;
    onCancel: () => void;
    isValidating: boolean;
    ibanStateHandler: IbanStateHandler;
};

export const IbanView: React.FC<IbanViewProps> = ({ goToNextStep, onCancel, isValidating, ibanStateHandler }) => {
    const { t } = useTranslation('overpayment-management');

    return (
        <>
            <Heading level={4}>{t('subtitle')}</Heading>
            <p dangerouslySetInnerHTML={{ __html: t('description') }} />
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedIbanInput
                                isValidating={isValidating}
                                ibanStateHandler={ibanStateHandler}
                                translationPrefix="overpayment-management"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                    <ButtonContainer className={'u-mt'} center>
                        <Button secondary onClick={onCancel} testId="go-back">
                            {t('translation:editable-section-nav.back')}
                        </Button>
                        <Button onClick={goToNextStep} testId="go-to-confirmation-view">
                            {t('translation:editable-section-nav.submit')}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
            </Fieldset>
        </>
    );
};
