export function getBusinessRegistrationEndpoint(): string {
    return '/registration/business';
}

export function getPersonRegistrationEndpoint(): string {
    return '/registration/person';
}

export function getRegistrationVerifyEndpoint(): string {
    return '/registration/verify';
}

export function getRegistrationEndpoint(): string {
    return '/registration';
}
