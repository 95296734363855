import { maxFileSize } from '../../config';

export const VALID_FILE_FORMATS = ['pdf', 'jpeg', 'jpg', 'png', 'tiff'];

const getFileType = (name: string): string => {
    const lastDot = name.lastIndexOf('.');
    return name.substring(lastDot + 1).toLowerCase() || '';
};

export const areAllFilesValid = (files: File[]): boolean => {
    if (!files.length) {
        return true;
    }
    return !files.find(
        ({ name, size }) =>
            maxFileSize < size || !VALID_FILE_FORMATS.some((type: string) => type === getFileType(name)),
    );
};
