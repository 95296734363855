import { createGetContractBasedDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { PaymentDetails } from '@cp-sk/common';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<PaymentDetails, DefaultBusinessMarketApiErrorCode>({
    dataName: 'paymentDetails',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then((response) => response.data);
    },
});

export default reducer;
export const fetchPaymentDetails = fetchData;
