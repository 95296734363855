import { TFunction } from 'i18next';
import { PaymentDetailsItem } from '@cp-sk/common';
import { Cell, TableHeaderEntry } from '@cp-shared-5/frontend-ui';
import { TranslationFormat } from '../../../../localization/useTranslationWithFormatting';

export const generateRow = (f: Function, row: PaymentDetailsItem): Cell[] => [
    row.paymentSequence || '-',
    f(row.date, TranslationFormat.DATE) || '-',
    { value: row.description || '-', hiddenForMobile: true },
    {
        value: f(row.totalAmount, TranslationFormat.CURRENCY) || '-',
        hiddenForMobile: true,
        nowrap: true,
        textAlign: 'right',
    },
    { value: f(row.outstandingAmount, TranslationFormat.CURRENCY) || '-', nowrap: true, textAlign: 'right' },
];

export const getSummaryRow = (f: Function, label: string, totalAmount?: number): Cell[] => [
    label,
    '',
    { value: '', hiddenForMobile: true },
    { value: '', hiddenForMobile: true },
    { value: f(totalAmount, TranslationFormat.CURRENCY), textAlign: 'right' },
];

export const getColumnHeadings = (t: TFunction): TableHeaderEntry[] => {
    const tableHeadingPrefix = 'overview.table-headings';
    return [
        t(`${tableHeadingPrefix}.payment-sequence`),
        t(`${tableHeadingPrefix}.date`),
        { heading: t(`${tableHeadingPrefix}.description`), hiddenForMobile: true },
        { heading: t(`${tableHeadingPrefix}.total-amount`), hiddenForMobile: true },
        t(`${tableHeadingPrefix}.outstanding-amount`),
    ];
};
