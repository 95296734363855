import { FileInfoError, FilesInfoError } from '../types';
import { defaultFileInfoError, defaultFilesInfoError } from '../messages';
import * as Yup from 'yup';
import { FileInfo, WithFileInfos } from '../../apis';

export const fileInfoValidator = (errors: FileInfoError = defaultFileInfoError): Yup.SchemaOf<FileInfo> =>
    Yup.object().shape({
        docTypeId: Yup.number().required(errors.docTypeId.required),
        fileId: Yup.string().required(errors.fileId.required),
    });

type FilesInfoValidatorConfig = {
    min: number;
    max: number;
};

export const filesInfoValidator = (
    errors: FilesInfoError = defaultFilesInfoError,
    config: FilesInfoValidatorConfig = { min: 1, max: 2 },
): Yup.SchemaOf<WithFileInfos> =>
    Yup.object().shape({
        files: Yup.array()
            .of(fileInfoValidator(errors.file))
            .min(config.min, errors.min)
            .max(config.max, errors.max)
            .required(errors.required),
    });
