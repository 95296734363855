import React from 'react';
import { Button, ButtonContainer, Heading } from '@vwfs-bronson/bronson-react';
import { DefinitionListHorizontal, DefinitionListItem, DataOverview } from '@cp-shared-5/frontend-ui';
import { useTranslation } from 'react-i18next';
import { dashboardPagePath } from '../navigation/paths';
import { useHistory } from 'react-router';

type ConfirmationViewProps = {
    items: DefinitionListItem[];
    headline?: string;
    subHeadline?: string;
    onConfirm: Function;
    onCancel: Function;
    description?: string;
    withoutCardEffect?: boolean;
    isSuccess?: boolean;
};

export const ConfirmationView: React.FC<ConfirmationViewProps> = ({
    items,
    headline,
    subHeadline,
    onConfirm,
    onCancel,
    description,
    withoutCardEffect,
    isSuccess = false,
}) => {
    const history = useHistory();
    const { t } = useTranslation('translation');

    const renderIfWithDashboardButtonButtons = (): JSX.Element => {
        if (!isSuccess) {
            return (
                <ButtonContainer center className={'u-mt-large'}>
                    <Button secondary onClick={onCancel} testId="cancel-button" type="btn">
                        {t('editable-section-nav.cancel')}
                    </Button>
                    <Button onClick={onConfirm} testId="confirm-button" type="submit">
                        {t('editable-section-nav.submit')}
                    </Button>
                </ButtonContainer>
            );
        }
        return (
            <ButtonContainer center className={'u-mt-large'}>
                <Button onClick={(): void => history.push(dashboardPagePath())} testId="dashboard-button" type="btn">
                    {t('editable-section-nav.dashboard')}
                </Button>
            </ButtonContainer>
        );
    };

    return (
        <>
            {!isSuccess && (
                <>
                    {headline && <Heading level={1}>{headline}</Heading>}
                    {description && <p className={'u-mb'}>{description}</p>}
                    <DataOverview
                        withoutCardEffect={withoutCardEffect}
                        title={subHeadline}
                        buttonLabel={t('editable-section-nav.start')}
                        buttonProps={{ onClick: onCancel, testId: 'edit-button' }}
                    >
                        <DefinitionListHorizontal list={items.filter((item) => item.value)} />
                    </DataOverview>
                </>
            )}
            {renderIfWithDashboardButtonButtons()}
        </>
    );
};
