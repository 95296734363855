import { DefinitionListItem } from '@cp-shared-5/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationView } from '../../../../../../confirmation-view';

type ConfirmationViewProps = {
    newLicensePlate: string;
    technicalDocumentNumber: string;
    onConfirm: () => void;
    onClose: () => void;
};

export const SummaryView: React.FC<ConfirmationViewProps> = ({
    newLicensePlate,
    technicalDocumentNumber,
    onConfirm,
    onClose,
}) => {
    const { t } = useTranslation('vehicle-details');
    const translationPrefix = 'edit-view';

    const itemsList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.license-plate.label`),
            value: newLicensePlate,
            testId: 'new-license-plate',
        },
        {
            label: t(`${translationPrefix}.technical-document-number.label`),
            value: technicalDocumentNumber,
            testId: 'technical-document-number',
        },
    ];

    return (
        <ConfirmationView
            items={itemsList}
            onConfirm={onConfirm}
            onCancel={onClose}
            subHeadline={t(`${translationPrefix}.confirmation-text`)}
        />
    );
};
