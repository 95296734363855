import React from 'react';
import { DefinitionTable, LicensePlate } from '@vwfs-bronson/bronson-react';
import { DividedContractHeader } from '@cp-shared-5/frontend-ui';

import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';

export type PaymentDetailsHeaderProps = {
    carInformation: string;
    productName: string;
    contractNumber: string;
    registrationNumber?: string;
    totalDueAmount?: number;
};
export const PaymentDetailsContractHeader: React.FC<PaymentDetailsHeaderProps> = ({
    carInformation,
    productName,
    contractNumber,
    registrationNumber,
    totalDueAmount,
}) => {
    const { t, f } = useTranslationWithFormatting('payment-details');

    const totalDueAmountPart = totalDueAmount && (
        <DefinitionTable>
            <DefinitionTable.Section
                summary
                summaryTitle={t('total-due-amount')}
                summaryData={f(totalDueAmount, TranslationFormat.CURRENCY)}
            />
        </DefinitionTable>
    );

    const licensePlate = registrationNumber && (
        <LicensePlate
            countryCode={t('country-code')}
            size={'small'}
            registrationNumber={registrationNumber}
            horizontalStripEu={true}
            euStars={true}
        />
    );

    return (
        <DividedContractHeader
            carInformation={carInformation}
            contractNumber={contractNumber}
            contractCategory={productName}
            rightSideContent={totalDueAmountPart}
            contractIdentifier={licensePlate}
        />
    );
};
