import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { EditStatus } from '@cp-sk/common';

export const NotificationForLastEditStatus: React.FC<{ lastEditStatus: EditStatus }> = ({ lastEditStatus }) => {
    const { t } = useTranslation('my-profile-contact-details');

    switch (lastEditStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('consult-view.edit-status.success.title')}
                    text={t('consult-view.edit-status.success.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification
                    status={NotificationStatus.error}
                    text={t('consult-view.edit-status.error.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        default: {
            return null;
        }
    }
};
