import React from 'react';
import { ContractDetails, ContractWithLinks } from '@cp-sk/common';
import { FinancialDetails } from '../financial-details';
import { VehicleDetails } from '../vehicle-details';
import { ContractParties } from '../contract-parties';
import { BundledProducts } from '../../bundled-products';

type ContractDetailsUiProps = {
    contract: ContractWithLinks;
    contractDetails?: ContractDetails;
    isLoading: boolean;
    hasError: boolean;
};

export const ContractDetailsUi: React.FC<ContractDetailsUiProps> = ({
    contract,
    contractDetails,
    isLoading,
    hasError,
}) => {
    const {
        contractType,
        encryptedContractNumber,
        contractNumber,
        paymentDateAvailable,
        changeContractDurationAvailable,
        changeMileageAvailable,
    } = contract;

    return (
        <>
            <FinancialDetails
                financialDetails={contractDetails?.financial}
                contractType={contractType}
                changeMileageAvailable={changeMileageAvailable}
                paymentDateAvailable={paymentDateAvailable}
                encryptedContractNumber={encryptedContractNumber || ''}
                changeContractDurationAvailable={changeContractDurationAvailable}
                isLoading={isLoading}
                hasError={hasError}
            />
            <BundledProducts contractNumber={contractNumber || ''} contractType={contractType} />
            <VehicleDetails
                vehicleDetails={contractDetails?.vehicle}
                isLoading={isLoading}
                hasError={hasError}
                encryptedContractNumber={encryptedContractNumber || ''}
            />
            <ContractParties
                contractPartiesDetails={contractDetails?.contractParties}
                isLoading={isLoading}
                hasError={hasError}
            />
        </>
    );
};
