import { createGetContractBasedDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { VehicleImageServiceResponse } from '@cp-shared-5/apis';
import { AxiosRequestConfig } from 'axios';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    VehicleImageServiceResponse,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'vehicleImages',
    fetchCallback(link: string, requestConfig?: AxiosRequestConfig) {
        return CpDataApi.get(link, requestConfig).then((response) => response.data);
    },
});

export default reducer;
export const fetchVehicleImage = fetchData;
