import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { EditStatus } from '@cp-sk/common';

export const NotificationForLastEditStatus: React.FC<{ lastEditStatus?: EditStatus }> = ({ lastEditStatus }) => {
    const { t } = useTranslation('my-profile-addresses-details');

    switch (lastEditStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('consult-view.edit-status.success.title')}
                    text={t('consult-view.edit-status.success.text')}
                    testId={'lastEditStatus-edit-success'}
                />
            );
        }
        case EditStatus.DELETE_SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('consult-view.fiscal.delete-success-message')}
                    testId={'lastEditStatus-delete-success'}
                />
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification
                    status={NotificationStatus.error}
                    text={t('consult-view.edit-status.error.text')}
                    testId={'lastEditStatus-error'}
                />
            );
        }
        default: {
            return null;
        }
    }
};
