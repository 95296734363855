import * as Yup from 'yup';
import { ChangePaymentDateValidatorErrors } from '../types';
import { ChangePaymentDateError } from '../messages';

export type changePaymentDateFormTypes = {
    paymentDay: number;
};

export const changePaymentDateValidationSchema = (
    errors: ChangePaymentDateValidatorErrors = ChangePaymentDateError,
): Yup.SchemaOf<changePaymentDateFormTypes> =>
    Yup.object().shape({
        paymentDay: Yup.number()
            .required(errors.paymentDay.required)
            .oneOf([5, 10, 15, 20, 25, 30], errors.paymentDay.oneOf),
    });
