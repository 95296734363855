import {
    Contract,
    FinancialDetails,
    getPaymentOverviewPdfDownloadEndpoint,
    PaymentOverviewDetails,
} from '@cp-sk/common';
import { Layout, Modal, Link } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { AmortizationTableHeader } from '../../amortization-table/header/AmortizationTableHeader';
import { PaymentOverviewTable } from '../payment-overview-table/PaymentOverviewTable';
import { Spinner } from '@cp-shared-5/frontend-ui';
import { compareFunction, generateRow, getColumnHeadings, ToColumnValues } from '../payment-overview-table/utils';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import { saveAs as downloadFileAs } from 'file-saver';
import { useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { CpDataApi } from 'cp-xhr';
import base64ToBlob from 'b64-to-blob';

export type PaymentOverviewUi = {
    contract?: Contract;
    PaymentOverviewDetails?: PaymentOverviewDetails[];
    financialDetails?: FinancialDetails;
};

export const PaymentOverviewUi: React.FC<PaymentOverviewUi> = ({
    contract,
    PaymentOverviewDetails,
    financialDetails,
}) => {
    const { t, f } = useTranslationWithFormatting('payment-overview');
    const [downloadError, setDownloadError] = useState<boolean>(false);
    const [isCsvDownloading, setIsCsvDownloading] = useState<boolean>(false);
    const [isPdfDownloading, setIsPdfDownloading] = useState<boolean>(false);
    // Check for contract being available, otherwise it might be that fetching hasn't even started
    if (!contract || !PaymentOverviewDetails || !financialDetails || !contract.encryptedContractNumber) {
        return null;
    }

    const { brandModelType = '', contractNumber = '', licensePlate, productName, encryptedContractNumber } = contract;

    const downloadAsCsv = (): void => {
        setIsCsvDownloading(true);
        const sortedRows = [...PaymentOverviewDetails].sort((row1, row2) => compareFunction(row1, row2));
        const rowsWithValuesOnly = sortedRows.map(
            (row): ToColumnValues =>
                generateRow(row, f).map((cell): string => (typeof cell === 'string' ? cell : cell.value)),
        );
        //this needs to be added at the beginning of the csv file to ensure utf-8 coding is correct
        const csvBOM = '\ufeff';
        const csvString = [
            getColumnHeadings(t).map((item): string => (typeof item === 'string' ? item : item?.heading)),
            ...rowsWithValuesOnly,
        ]
            .map((row) => Object.values(row).join(';'))
            .join('\n');
        const fileName = `${t('table.filename')} ${contractNumber}_${formatCpDate().format('DDMMYYYY')}.csv`;
        const csvBlob = new Blob([csvBOM + csvString], { type: 'text/csv' });
        downloadFileAs(csvBlob, fileName);
        setIsCsvDownloading(false);
    };

    const downloadAsPdf = (): void => {
        setIsPdfDownloading(true);
        CpDataApi.get(getPaymentOverviewPdfDownloadEndpoint(encryptedContractNumber))
            .then((response) => {
                const fileContent = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                const filename = `${t('table.filename')} ${contractNumber}_${formatCpDate().format('DDMMYYYY')}.pdf`;
                downloadFileAs(pdfBlob, filename);
                setIsPdfDownloading(false);
            })
            .catch(() => {
                setDownloadError(true);
                setIsPdfDownloading(false);
            });
    };

    return (
        <>
            <Layout>
                <Layout.Item default="1/1">
                    <AmortizationTableHeader
                        carInformation={brandModelType}
                        contractCategory={productName}
                        contractNumber={contractNumber}
                        countryCode={t('header.country-code')}
                        registrationNumber={licensePlate}
                        financialDetails={financialDetails}
                    />
                </Layout.Item>
                <Layout.Item default="1/1" className="u-mt">
                    {isCsvDownloading ? (
                        <Spinner small center={false} />
                    ) : (
                        <Link onClick={downloadAsCsv} testId={'download-csv-link'} normalLink icon="download">
                            {t('table.download.link.download-as-csv')}
                        </Link>
                    )}
                    {isPdfDownloading ? (
                        <Spinner small center={false} className="u-ml-small c-link" />
                    ) : (
                        <Link
                            onClick={downloadAsPdf}
                            className="u-ml-small"
                            testId={'download-pdf-link'}
                            normalLink
                            icon="download"
                        >
                            {t('table.download.link.download-as-pdf')}
                        </Link>
                    )}
                </Layout.Item>
                <Layout.Item default="1/1">
                    <PaymentOverviewTable PaymentOverviewDetails={PaymentOverviewDetails} />
                </Layout.Item>
            </Layout>
            <Modal
                shown={!!downloadError}
                title={t('table.download.error=modal.title')}
                onClose={(): void => setDownloadError(false)}
                status="error"
                testId={'download-failure-modal'}
            >
                {t('table.download.error=modal.text')}
            </Modal>
        </>
    );
};
