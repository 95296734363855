import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import { parseErrorResponse } from '@cp-shared-5/frontend-integration';
import { Spinner, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import {
    CustomerType as CommonCustomerType,
    getRegistrationVerifyEndpoint,
    RegistrationError,
    RegistrationResult,
    RegistrationSmsVerify,
    RegistrationSmsVerifyError,
} from '@cp-sk/common';
import { dashboardPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useUpdateCustomerType } from '../../../customer-type/useUpdateCustomerType';
import { handleSubmit as resendBusinessFormData } from '../identification/business-identification-form/utils';
import { handleSubmit as resendPersonFormData } from '../identification/person-identification-form/utils';
import { BusinessFormProps, CustomerType as ViewCustomerType, PersonFormProps } from '../identification/types';
import { FormProps, SmsIdentificationForm } from './sms-identification-form';

type SmsIdentificationProps = {
    customerType: ViewCustomerType;
    customerRegistrationResult: RegistrationResult;
    customerFormData: PersonFormProps | BusinessFormProps;
};

type RequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError | RegistrationSmsVerifyError>;
    errorMessage?: string;
};

export const SmsIdentification: React.FC<SmsIdentificationProps> = ({
    customerType,
    customerRegistrationResult: { phoneNumberEnding },
    customerFormData,
}) => {
    const [request, setRequest] = useState<RequestProps>({ isLoading: false });
    const { customerTypeData, updateCustomerType } = useUpdateCustomerType();

    useAnalyticsPageViewTracker('enterSmsCode', !customerTypeData);
    const { onAction: trackOnSuccess } = useAnalyticsActionTracker('onEnterSmsCodeSuccess');
    const { onAction: trackOnError } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');

    const history = useHistory();

    const handleResendCode = () => {
        setRequest({
            ...request,
            isLoading: true,
        });
        const onSuccess = () => {
            setRequest({
                isLoading: false,
            });
        };
        const onError = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => {
            setRequest({
                isLoading: false,
                errorCode,
                errorMessage,
            });
        };
        switch (customerType) {
            case ViewCustomerType.PRIVATE:
                resendPersonFormData(customerFormData as PersonFormProps, onSuccess, onError);
                break;
            case ViewCustomerType.BUSINESS:
                resendBusinessFormData(customerFormData as BusinessFormProps, onSuccess, onError);
                break;
        }
    };

    const customerTypeMapper = (customerType: ViewCustomerType): CommonCustomerType =>
        customerType === ViewCustomerType.PRIVATE ? 'Private' : 'Business';

    const handleSmsCodeConfirmation = (values: FormProps): void => {
        setRequest({ isLoading: true });
        const requestBody: RegistrationSmsVerify = {
            smsToken: values.smsToken,
        };
        CpDataApi.post(getRegistrationVerifyEndpoint(), requestBody)
            .then(() => {
                setRequest({ isLoading: false });
                trackOnSuccess();
                updateCustomerType(customerTypeMapper(customerType));
                history.push(dashboardPagePath());
            })
            .catch((error) => {
                const errorCode = parseErrorResponse<RegistrationSmsVerifyError>(error).code;
                const errorMessage = error.response.data?.message;
                setRequest({
                    isLoading: false,
                    errorCode,
                    errorMessage,
                });
                trackOnError();
            });
    };

    const { isLoading, errorCode, errorMessage } = request;

    return (
        <>
            {isLoading && <Spinner fullPage={true} />}
            <SmsIdentificationForm
                phoneNumberEnding={phoneNumberEnding}
                onResendCode={handleResendCode}
                onSmsCodeConfirmation={handleSmsCodeConfirmation}
                errorCode={errorCode}
                errorMessage={errorMessage}
            />
        </>
    );
};
