import { TFunction } from 'i18next';
import { isEqual } from 'lodash';
import { PaymentDetailsPaymentResult } from '@cp-sk/common';
import { PaymentPageProps } from '../PaymentPage';
import { DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { TranslationFormat } from '../../../localization/useTranslationWithFormatting';

const emptyData: PaymentDetailsPaymentResult = { payBySquareImage: {} };

export const getPaymentDetailsPaymentPageProps = (
    t: TFunction,
    f: Function,
    handleBackToPreviousView: Function,
    data?: PaymentDetailsPaymentResult,
): PaymentPageProps => {
    if (!data || isEqual(data, emptyData)) {
        return { manualBankTransferList: [], payBySquareList: [], isError: true, handleBackToPreviousView };
    }

    const translationPrefix = 'payment-page';
    const headline = t(`${translationPrefix}.title`);
    const description = t(`${translationPrefix}.description`);
    const noValuePlaceholder = '-';
    const { bankName, iban, referenceCode, payBySquareImage, paymentAmount, totalDueAmount } = data;

    const payBySquareList: DefinitionListItem[] = [
        {
            label: t('total-due-amount'),
            value: f(totalDueAmount, TranslationFormat.CURRENCY) || noValuePlaceholder,
            testId: 'total-due-amount',
        },
        {
            label: t(`${translationPrefix}.payment-amount`),
            value: f(paymentAmount, TranslationFormat.CURRENCY) || noValuePlaceholder,
            testId: 'payment-amount',
        },
    ];

    const manualBankTransferList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.bank-name`),
            value: bankName || noValuePlaceholder,
            testId: 'bank-name',
        },
        {
            label: t(`${translationPrefix}.iban`),
            value: f(iban, TranslationFormat.IBAN) || noValuePlaceholder,
            testId: 'iban',
        },
        {
            label: t(`${translationPrefix}.reference-code`),
            value: referenceCode || noValuePlaceholder,
            testId: 'reference-code',
        },
        ...payBySquareList,
    ];

    return {
        headline,
        description,
        manualBankTransferList,
        payBySquareList,
        handleBackToPreviousView,
        payBySquareImage,
    };
};
