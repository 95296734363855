import { Greeting } from '@cp-sk/common';

export const GreetingForPrivateCustomer: Greeting = {
    message: 'Vitajte pán Frátrič',
    customerType: 'Private',
};

export const GreetingForBusinessCustomer: Greeting = {
    message: 'Vitajte ABS s.r.o.',
    customerType: 'Business',
};

export const EmptyGreetingMessage: Greeting = {
    message: '',
    customerType: 'Private',
};
