import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-5/frontend-ui';
import { NavigationBar } from 'components/navigation/NavigationBar';
import { LegalFooter } from 'components/footer/legal-footer';
import { IconFooter } from 'components/footer/icon-footer';
import { registrationPagePath } from '../../components/navigation/paths';
import { useCustomerType } from '../../components/customer-type';
import { getUserRegistryStatusEndpoint, UserRegistrationResult } from '@cp-sk/common';
import { CpDataApi } from 'cp-xhr';
import { Chatbot } from '../../components/chatbot/Chatbot';

// eslint-disable-next-line react/display-name
const MasterPageComponent: React.FC<{ isAuthenticated: boolean | undefined }> = memo(
    ({ isAuthenticated, children }) => {
        const { t } = useTranslation();

        const { data: customerType } = useCustomerType(!!isAuthenticated);

        const userGroup = !!customerType ? customerType : undefined;

        return (
            <AnalyticsProvider
                market={'sk'}
                isAuthenticated={isAuthenticated}
                version={'1'}
                releaseDate={'2020-12-01'}
                language={'sk'}
                registrationPath={registrationPagePath()}
                loginPath={'/authentication/callback'}
                userGroup={userGroup}
                brand={'vwfs'}
            >
                <MasterPageShared
                    navigationBar={<NavigationBar isAuthenticated={isAuthenticated} />}
                    iconFooter={<IconFooter />}
                    legalFooter={<LegalFooter />}
                    label={t('backToTop')}
                >
                    {children}
                </MasterPageShared>
            </AnalyticsProvider>
        );
    },
);

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    const [isRegistered, setIsRegistered] = useState<boolean | undefined>();

    useEffect(() => {
        CpDataApi.get<UserRegistrationResult>(getUserRegistryStatusEndpoint())
            .then((res) => {
                setIsRegistered(res?.data?.isRegistered);
            })
            .catch(() => {
                setIsRegistered(false);
            });
    }, []);

    return (
        <MasterPageComponent isAuthenticated={isAuthenticated && isRegistered}>
            {isAuthenticated && <Chatbot isRegistered={isRegistered} />}
            {children}
        </MasterPageComponent>
    );
};
