import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { formatAsDistance, formatAsEmission } from '@cp-sk/common';

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'sk',
        load: 'languageOnly',
        preload: ['sk'],
        ns: [
            'amortization-details',
            'bundled-products',
            'contract-parties',
            'contracts',
            'dashboard',
            'early-settlement',
            'file-upload',
            'financial-contract-header',
            'financial-details',
            'my-documents',
            'my-profile-addresses-details',
            'my-profile-contact-details',
            'my-profile-marketing-consent-settings',
            'my-profile',
            'navigation',
            'payment-details',
            'payment-page',
            'registration',
            'request',
            'translation',
            'vehicle-details',
            'change-contract-duration',
        ],
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format(value, format) {
                if (format === 'distance') {
                    return formatAsDistance(value);
                }
                if (format === 'emission') {
                    return formatAsEmission(value);
                }
                return value;
            },
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
        whitelist: ['en', 'sk'],
        lng: 'sk',
    });

export default i18n;
