import { getGreetingEndpoint, Greeting } from '@cp-sk/common';
import { getSimpleDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<Greeting, DefaultBusinessMarketApiErrorCode>({
    dataName: 'greeting',
    fetchCallback() {
        return CpDataApi.get(getGreetingEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchGreeting = fetchData;
