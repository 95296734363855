import { ContentSection } from '@vwfs-bronson/bronson-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProofOfCarOwnership } from '../../components/proof-of-car-ownership';
import { useContract } from '../../components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from '../../components/integration-wrapper';

const ProofOfCarOwnershipWithHandlers = withLoadingAndNoConnectionHandler(ProofOfCarOwnership);

export const ProofOfCarOwnershipPage: React.FC = () => {
    const { encryptedContractNumber } = useParams();
    const [loadingStarted, setLoadingStarted] = useState(false);

    const {
        data: contract,
        isLoading,
        loadingError,
    } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });

    const onLoading = useCallback(() => {
        if (isLoading) setLoadingStarted(true);
    }, [isLoading]);

    useEffect(() => {
        onLoading();
    }, [onLoading]);

    const isAllowed = contract?.carOwnershipAlert?.headline || contract?.carOwnershipAlert?.description;

    return (
        <ContentSection pageWrapSize="medium">
            <ProofOfCarOwnershipWithHandlers
                isLoading={isLoading}
                hasError={!!loadingError || (loadingStarted && !isLoading && !isAllowed)}
                encryptedContractNumber={encryptedContractNumber}
            />
        </ContentSection>
    );
};
