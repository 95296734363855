import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-5/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { ContractWithLinks } from '@cp-sk/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

type ContractTransformer = (contracts?: ContractWithLinks[]) => ContractWithLinks | undefined;

function getFilterForContractNumber(contractNumber: string, encryptedContractNumber?: boolean): ContractTransformer {
    return (contracts?: ContractWithLinks[]): ContractWithLinks | undefined =>
        contracts?.filter((contract) => {
            const filterVal = encryptedContractNumber ? contract.encryptedContractNumber : contract.contractNumber;
            return filterVal === contractNumber;
        })[0];
}

type ConfigProps = {
    encryptedContractNumber?: boolean;
};

/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractNumber
 * @param config
 */
export function useContract(
    contractNumber: string,
    config: ConfigProps = {},
): AbstractDataState<ContractWithLinks | undefined, DefaultBusinessMarketApiErrorCode> {
    const { encryptedContractNumber } = config;
    const contractFilter = useMemo(
        () => getFilterForContractNumber(contractNumber, encryptedContractNumber),
        [contractNumber, encryptedContractNumber],
    );
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
