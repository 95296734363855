import { ContractWithLinks } from '@cp-sk/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-5/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';

export function useContracts(): AbstractDataState<ContractWithLinks[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchContracts, ContractsDataSelector);
}
