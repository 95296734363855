import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BankData, SignedData } from '@cp-shared-5/apis';
import { UiBlockingSpinner, withAutoScroll } from '@cp-shared-5/frontend-ui';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import { CpDataApi } from 'cp-xhr';
import { EditStatus, getChangeIbanEndpoint, ibanValidationUtils, getDeleteIbanEndpoint } from '@cp-sk/common';
import { IbanView } from './iban-view';
import { SummaryView } from './summary-view';
import { DeleteConfirmation } from './delete-confirmation';
import { getValidationSchema } from 'components/validated-iban-input/validationSchema';
import { IbanStateHandler, InitialValues } from 'components/validated-iban-input';

export type EditViewProps = {
    iban?: string;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedIban?: string) => void;
};

type EditIbanView = 'IBAN_VIEW' | 'SUMMARY_VIEW' | 'DELETE_VIEW';

export const EditView: React.FC<EditViewProps> = withAutoScroll(({ iban, cancelEditing, finishEditing }) => {
    const { t } = useTranslation('my-profile-bank-details');
    const detailedTranslationPrefix = 'edit-view';

    const [currentView, setCurrentView] = useState<EditIbanView>('IBAN_VIEW');

    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});

    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const ibanStateHandler: IbanStateHandler = {
        signedBankData,
        setSignedBankData,
        savedIban,
        setSavedIban,
    };

    const initialValues: InitialValues = { iban: iban ?? '' };
    const { t: tValidation } = useTranslation('iban-validation');
    const validationSchema = getValidationSchema(tValidation, setIsValidating, ibanStateHandler);

    const onSubmit = (values: InitialValues) => {
        setIsSubmitting(true);
        CpDataApi.put(getChangeIbanEndpoint(), {
            iban: ibanValidationUtils.stripWhitespaces(values.iban),
        })
            .then(() => {
                finishEditing(EditStatus.SUCCESS, values.iban);
            })
            .catch(() => {
                finishEditing(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const onDelete = (): void => {
        if (!iban) {
            finishEditing(EditStatus.ERROR);
            return;
        }

        setIsSubmitting(true);
        CpDataApi.delete(getDeleteIbanEndpoint(iban))
            .then(() => {
                finishEditing(EditStatus.DELETE_SUCCESS, '');
            })
            .catch(() => {
                finishEditing(EditStatus.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <DataOverview title={t(`${detailedTranslationPrefix}.title`)}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnBlur={true}
            >
                {({ handleSubmit, values, validateForm, setFieldTouched }) => (
                    <Form onSubmit={(e) => e.preventDefault()} data-testid="iban-form">
                        <UiBlockingSpinner isBlocking={isSubmitting}>
                            {currentView === 'IBAN_VIEW' && (
                                <IbanView
                                    isValidating={isValidating}
                                    ibanStateHandler={ibanStateHandler}
                                    onCancel={cancelEditing}
                                    goToNextStep={async () => {
                                        setFieldTouched('iban', true);
                                        const errors = await validateForm();
                                        if (!errors.iban) setCurrentView('SUMMARY_VIEW');
                                    }}
                                    goToDelete={() => {
                                        setCurrentView('DELETE_VIEW');
                                    }}
                                    deleteInactive={!iban}
                                />
                            )}
                            {currentView === 'SUMMARY_VIEW' && (
                                <SummaryView
                                    goToPreviousStep={() => {
                                        setCurrentView('IBAN_VIEW');
                                        setLastEditStatus(EditStatus.NOT_PERFORMED);
                                    }}
                                    onSubmit={handleSubmit}
                                    iban={values.iban}
                                    lastEditStatus={lastEditStatus}
                                />
                            )}
                            {currentView === 'DELETE_VIEW' && (
                                <DeleteConfirmation
                                    onCancel={() => {
                                        setCurrentView('IBAN_VIEW');
                                        setLastEditStatus(EditStatus.NOT_PERFORMED);
                                    }}
                                    onConfirm={onDelete}
                                />
                            )}
                        </UiBlockingSpinner>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
}, 'BankSectionEditView');
