import React from 'react';
import { useTranslation } from 'react-i18next';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { BundledProductsUi } from './ui';
import { AccordionItem } from '@cp-shared-5/frontend-ui';
import { useBundledProducts } from './useBundledProducts';
import {
    BundledProducts as BundledProductsType,
    BundledProductStatusCode,
    CarInsurance,
    ContractType,
    OtherInsurance,
    ServiceAndMaintenance,
} from '@cp-sk/common';

const BundledProductsWithHandlers = withLoadingAndNoConnectionHandler(BundledProductsUi);

export const BundledProducts: React.FC<{ contractNumber: string; contractType: ContractType }> = ({
    contractNumber,
    contractType,
}) => {
    const { t } = useTranslation('bundled-products');

    const { data: products, isLoading, loadingError } = useBundledProducts(contractNumber);

    const areAllFieldsEmpty = !products?.carInsurance && !products?.otherInsurance && !products?.serviceAndMaintenance;

    const isActiveProduct = (product: CarInsurance | OtherInsurance | ServiceAndMaintenance) =>
        product.statusCode === BundledProductStatusCode.A;

    const getActiveProducts = (): BundledProductsType => {
        return {
            carInsurance: products?.carInsurance?.filter((item) => isActiveProduct(item)),
            otherInsurance:
                products?.otherInsurance && isActiveProduct(products?.otherInsurance)
                    ? products?.otherInsurance
                    : undefined,
            serviceAndMaintenance: products?.serviceAndMaintenance?.filter((item) => isActiveProduct(item)),
        };
    };

    const activeProducts = getActiveProducts();

    const hasActiveProducts = (): boolean =>
        !!(
            activeProducts.carInsurance?.length ||
            activeProducts.otherInsurance ||
            activeProducts.serviceAndMaintenance?.length
        );

    return (!loadingError && !areAllFieldsEmpty && hasActiveProducts()) || loadingError ? (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <div>
                <BundledProductsWithHandlers
                    isLoading={isLoading}
                    bundledProducts={activeProducts}
                    isOperativeLeasing={contractType === ContractType.OPERATIVE_LEASING}
                    hasError={!!loadingError}
                />
            </div>
        </AccordionItem>
    ) : null;
};
