import React from 'react';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    BusinessCustomerData,
    CustomerType,
    EditStatus,
    formatAsDate,
    Identification,
    PrivateCustomerData,
} from '@cp-sk/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';

export type ConsultViewProps = {
    identification: Identification;
    lastEditStatus?: EditStatus;
    startEditing?: (customerType: CustomerType) => void;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ identification, lastEditStatus, startEditing }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'identification.consult-view';

    const getIdentificationList = (): DefinitionListItem[] => {
        const noValuePlaceholder = '-';
        let prefix;
        switch (identification.customerType) {
            case 'Private':
                prefix = `${translationPrefix}.items.label.privateCustomerData`;
                const { title, firstName, surname, birthDate, idCardNumber } =
                    identification.data as PrivateCustomerData;
                return [
                    {
                        label: t(`${prefix}.title`),
                        value: title ?? noValuePlaceholder,
                        testId: 'identification-details-title',
                    },
                    {
                        label: t(`${prefix}.name`),
                        value: `${title ?? ''} ${firstName ?? ''} ${surname ?? ''}`,
                        testId: 'identification-details-name',
                    },
                    {
                        label: t(`${prefix}.birthDate`),
                        value: birthDate ? formatAsDate(birthDate) : noValuePlaceholder,
                        testId: 'identification-details-birth-date',
                    },
                    {
                        label: t(`${prefix}.idCardNumber`),
                        value: idCardNumber ?? noValuePlaceholder,
                        testId: 'identification-details-id',
                    },
                ];
            case 'Business':
                prefix = `${translationPrefix}.items.label.businessCustomerData`;
                const { companyName, companyNumber, vatNumber } = identification.data as BusinessCustomerData;
                return [
                    {
                        label: t(`${prefix}.companyName`),
                        value: companyName ?? noValuePlaceholder,
                        testId: 'identification-details-company-name',
                    },
                    {
                        label: t(`${prefix}.companyNumber`),
                        value: companyNumber ?? noValuePlaceholder,
                        testId: 'identification-details-company-number',
                    },
                    {
                        label: t(`${prefix}.vatNumber`),
                        value: vatNumber ?? noValuePlaceholder,
                        testId: 'identification-details-vat',
                    },
                ];
            default:
                return [];
        }
    };

    const identificationList: DefinitionListItem[] = getIdentificationList();

    return (
        <DataOverview
            title={t(`${translationPrefix}.title`)}
            buttonLabel={t(`${translationPrefix}.edit`)}
            buttonProps={{
                onClick: (): void => {
                    startEditing && startEditing(identification.customerType);
                },
                testId: 'edit-button',
            }}
        >
            <DefinitionList split={true} list={identificationList} />
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
        </DataOverview>
    );
};
