import React, { useState, useEffect } from 'react';

import { ButtonContainer, Button, Card, Layout, LicensePlate as LicensePlateShared } from '@vwfs-bronson/bronson-react';
import { ContractHeader, Notification, NotificationStatus, Spinner } from '@cp-shared-5/frontend-ui';
import { ContractWithLinks, GeneratePaymentResult, getGeneratePaymentEndpoint } from '@cp-sk/common';
import { useTranslation } from 'react-i18next';
import { PaymentDetails } from './payment-details';
import { CpDataApi } from 'cp-xhr';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { dashboardPagePath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';

type OfflinePaymentProps = {
    contractDetails: ContractWithLinks;
    encryptedContractNumber: string;
    totalSettlementAmount?: number;
};

export const OfflinePayment: React.FC<OfflinePaymentProps> = ({
    contractDetails,
    encryptedContractNumber,
    totalSettlementAmount,
}) => {
    const history = useHistory();
    const { t } = useTranslation('partial-early-settlement');
    const [paymentDetails, setPaymentDetails] = useState<GeneratePaymentResult>();
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [fetchingPaymentDetailsError, setFetchingPaymentDetailsError] = useState<boolean>(false);

    useEffect(() => {
        setIsFetching(true);

        const requestBody = {
            referenceCode: contractDetails?.contractNumber,
            paymentAmount: totalSettlementAmount,
        };

        CpDataApi.post(getGeneratePaymentEndpoint(encryptedContractNumber), requestBody)
            .then((response) => {
                setPaymentDetails(response.data);
            })
            .catch(() => {
                setPaymentDetails(undefined);
                setFetchingPaymentDetailsError(true);
            })
            .finally(() => setIsFetching(false));
    }, [contractDetails, totalSettlementAmount, encryptedContractNumber]);

    const LicensePlate = contractDetails?.licensePlate ? (
        <LicensePlateShared
            countryCode={t('country-code')}
            size={'small'}
            registrationNumber={contractDetails?.licensePlate}
            horizontalStripEu={true}
            euStars={true}
        />
    ) : null;

    return (
        <>
            {isFetching ? (
                <Spinner fullPage />
            ) : (
                <>
                    {fetchingPaymentDetailsError ? (
                        <NoConnectionNotification testId={'no-connection'} />
                    ) : (
                        <>
                            <Layout.Item default="1/1">
                                <Card element="article">
                                    <ContractHeader
                                        carInformation={contractDetails.brandModelType!}
                                        contractCategory={contractDetails.productName}
                                        contractNumber={contractDetails.contractNumber!}
                                        contractIdentifier={LicensePlate}
                                    />
                                </Card>
                            </Layout.Item>
                            <PaymentDetails paymentDetails={paymentDetails} />
                            <Layout.Item default="1/1" className="u-mt">
                                <Notification
                                    status={NotificationStatus.warning}
                                    headline={t('offline-payment.warning.headline')}
                                    text={t('offline-payment.warning.text')}
                                    testId={'offlinePaymentNotification'}
                                ></Notification>
                            </Layout.Item>
                        </>
                    )}
                    <Layout center className="u-mh-xxsmall">
                        <Layout.Item default="1/3" xs="1/1">
                            <ButtonContainer center className="u-mt">
                                <Button
                                    onClick={(): void => history.push(dashboardPagePath())}
                                    testId="offlinePaymentProceedButton"
                                >
                                    {t('offline-payment.dashboard-button')}
                                </Button>
                            </ButtonContainer>
                        </Layout.Item>
                    </Layout>
                </>
            )}
        </>
    );
};
