import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) => {
    const requiredEmailError = 'edit-view.email.validation.required';
    const invalidEmailError = 'edit-view.email.validation.invalid';
    const requiredMobilePhoneError = 'edit-view.mobile-phone.validation.required';
    const invalidMobilePhoneError = 'edit-view.mobile-phone.validation.invalid';
    const beginsWithZeroMobilePhoneError = 'edit-view.mobile-phone.validation.begins-with-zero';
    const invalidHomePhoneError = 'edit-view.home-phone.validation.invalid';
    const beginsWithZeroMobileHomeError = 'edit-view.home-phone.validation.begins-with-zero';
    return Yup.object().shape({
        email: Yup.string().trim().required(t(requiredEmailError)).email(t(invalidEmailError)),
        mobilePhone: Yup.string()
            .trim()
            .required(t(requiredMobilePhoneError))
            .min(9, t(invalidMobilePhoneError))
            .max(11, t(invalidMobilePhoneError))
            .matches(/^[^0].*/, t(beginsWithZeroMobilePhoneError))
            .matches(/^((\d{3}?[ ]?){3})$/, t(invalidMobilePhoneError)),
        homePhone: Yup.string()
            .trim()
            .min(9, t(invalidHomePhoneError))
            .max(27, t(invalidHomePhoneError))
            .matches(/^[^0].*/, t(beginsWithZeroMobileHomeError))
            .matches(/^(\d{3}?[ ]?){3,7}$/, t(invalidHomePhoneError)),
    });
};
