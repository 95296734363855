import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetails } from '@cp-sk/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { getPhoneWithoutZeroOnBeginning } from '../helper';

export const ContactList: React.FC<{ contactDetails: ContactDetails }> = ({ contactDetails }) => {
    const { t } = useTranslation('my-profile-contact-details');
    const { mobilePhone, homePhone, email } = contactDetails;

    const getFullPhone = (phone?: string): string => {
        if (phone) {
            const withoutZeroOnBeginning = getPhoneWithoutZeroOnBeginning(phone);
            return `+421 ${withoutZeroOnBeginning}`;
        } else {
            return '-';
        }
    };

    const contactList: DefinitionListItem[] = [
        {
            label: t('consult-view.email.label'),
            value: email || '-',
            testId: 'contact-details-email',
        },
        {
            label: t('consult-view.mobile-phone.label'),
            value: getFullPhone(mobilePhone),
            testId: 'contact-details-mobile-phone',
        },
        {
            label: t('consult-view.home-phone.label'),
            value: getFullPhone(homePhone),
            testId: 'contact-details-home-phone',
        },
    ];

    return <DefinitionList split={true} list={contactList} />;
};
