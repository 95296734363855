import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataOverview, Checkbox } from '@vwfs-bronson/bronson-react';
import { EditStatus, MarketingSettingsWithCustomerType } from '@cp-sk/common';
import { NotificationForEditStatus } from './NotificationForEditStatus';

type ConsultViewProps = {
    marketingConsentSettings?: MarketingSettingsWithCustomerType;
    startEditing: () => void;
    editStatus: EditStatus | undefined;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    marketingConsentSettings,
    startEditing,
    editStatus = EditStatus.NOT_PERFORMED,
}) => {
    const { t } = useTranslation('my-profile-marketing-consent-settings');
    if (!marketingConsentSettings || marketingConsentSettings.customerType === 'Business') {
        return null;
    }

    const { email, phone, sms, post } = marketingConsentSettings;
    const marketingData: Array<{ key: string; value: boolean | undefined }> = [
        { key: 'email', value: email },
        { key: 'phone', value: phone },
        { key: 'sms', value: sms },
        { key: 'post', value: post },
    ];

    return (
        <DataOverview
            title={t('consult-view.title')}
            buttonLabel={t('translation:editable-section-nav.start')}
            buttonProps={{ onClick: startEditing, testId: 'edit-button' }}
        >
            <div className={'u-mb'}> {t('consult-view.description')} </div>
            {marketingData.map(({ key, value }) => (
                <div className={'u-mb-xsmall'} key={key}>
                    <Checkbox checked={value} disabled>
                        {t(key)}
                    </Checkbox>
                </div>
            ))}
            <NotificationForEditStatus editStatus={editStatus} />
        </DataOverview>
    );
};
