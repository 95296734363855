import { VehicleImageServiceResponse, VehicleImageView, VehicleServiceResponse } from '@cp-shared-5/apis';

export const VehicleDetailsWithAllValues: VehicleServiceResponse = {
    brand: 'Volkswagen',
    modelName: 'Golf',
    modelVariant: 'GTI',
    engineTechnology: 'TDI',
    fuelType: 'diesel',
    transmission: 'manual',
    bodyColorName: 'orange',
    bodyColorFinish: 'metallic',
    trim: 'highline',
    modelYear: 2018,
    engineSize: {
        value: '1.0',
        unit: 'l',
    },
    enginePower: {
        value: '62',
        unit: 'kW',
    },
    drivetrain: 'frontWheelDrive',
};

export const VehicleDetailsWithoutOneBodyColor: VehicleServiceResponse = {
    ...VehicleDetailsWithAllValues,
    bodyColorFinish: undefined,
};

export const VehicleDetailsWithMissingValues: VehicleServiceResponse = {
    brand: 'Volkswagen',
    modelName: 'GTI',
    modelVariant: '',
    engineTechnology: 'TDI',
};

export const VehicleImageWithAllValues: VehicleImageServiceResponse = {
    vin: 'SOME_VIN',
    images: [
        { url: 'some_url_1', view: VehicleImageView.EXTERIOR_FRONT_RIGHT },
        { url: 'some_url_2', view: VehicleImageView.EXTERIOR_SIDE_RIGHT },
        { url: 'some_url_3', view: VehicleImageView.EXTERIOR_BACK_RIGHT },
        { url: 'some_url_4', view: VehicleImageView.EXTERIOR_BACK_LEFT },
        { url: 'some_url_5', view: VehicleImageView.EXTERIOR_SIDE_LEFT },
        {
            url: '/contents/vwfs/vw_default.svg',
            view: VehicleImageView.EXTERIOR_FRONT_LEFT,
        },
        { url: 'some_url_7', view: VehicleImageView.EXTERIOR_FRONT_CENTER },
        { url: 'some_url_8', view: VehicleImageView.EXTERIOR_BACK_CENTER },
        { url: 'some_url_9', view: VehicleImageView.INTERIOR_CENTER },
        { url: 'some_url_10', view: VehicleImageView.INTERIOR_SIDE },
        { url: 'some_url_11', view: VehicleImageView.EXTERIOR_WHEEL },
        { url: 'some_url_12', view: VehicleImageView.UNKNOWN },
    ],
};

export const VehicleImageWithMissingValues: VehicleImageServiceResponse = {
    vin: 'SOME_VIN',
    images: [{ url: 'some_url_1', view: VehicleImageView.EXTERIOR_FRONT_RIGHT }],
};
