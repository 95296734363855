import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddressType, FiscalAddress, PostalAddress } from '@cp-sk/common';
import { DefinitionListItem, withAutoScroll } from '@cp-shared-5/frontend-ui';
import { ConfirmationView } from '../../../../confirmation-view';

type SummaryViewProps = {
    addressType: AddressType;
    newData: PostalAddress | FiscalAddress;
    onConfirm: () => void;
    onClose: () => void;
};

export const SummaryView: React.FC<SummaryViewProps> = withAutoScroll(
    ({ addressType, newData, onConfirm, onClose }) => {
        const { t } = useTranslation('my-profile-addresses-details');

        const getAddressList = (): DefinitionListItem[] => {
            const noValuePlaceholder = '-';
            const translationPrefixFiscal = 'edit-view.fiscal';
            const translationPrefixCommon = 'edit-view.common';
            const { street, city, postalCode, country } = newData as PostalAddress;

            switch (addressType) {
                case AddressType.POSTAL:
                    return [
                        {
                            label: t(`${translationPrefixCommon}.street.label`),
                            value: street ?? noValuePlaceholder,
                            testId: 'postal-address-street',
                        },
                        {
                            label: t(`${translationPrefixCommon}.city.label`),
                            value: city ?? noValuePlaceholder,
                            testId: 'postal-address-city',
                        },
                        {
                            label: t(`${translationPrefixCommon}.postal-code.label`),
                            value: postalCode ?? noValuePlaceholder,
                            testId: 'postal-address-postalCode',
                        },
                        {
                            label: t(`${translationPrefixCommon}.country.label`),
                            value: country ?? noValuePlaceholder,
                            testId: 'postal-address-country',
                        },
                    ];
                case AddressType.FISCAL:
                    const { secondLine } = newData as FiscalAddress;
                    return [
                        {
                            label: t(`${translationPrefixCommon}.street.label`),
                            value: street ?? noValuePlaceholder,
                            testId: 'fiscal-address-street',
                        },
                        {
                            label: t(`${translationPrefixFiscal}.second-line.label`),
                            value: secondLine ?? noValuePlaceholder,
                            testId: 'fiscal-address-secondLine',
                        },
                        {
                            label: t(`${translationPrefixCommon}.city.label`),
                            value: city ?? noValuePlaceholder,
                            testId: 'fiscal-address-city',
                        },
                        {
                            label: t(`${translationPrefixCommon}.postal-code.label`),
                            value: postalCode ?? noValuePlaceholder,
                            testId: 'fiscal-address-postalCode',
                        },
                        {
                            label: t(`${translationPrefixCommon}.country.label`),
                            value: country ?? noValuePlaceholder,
                            testId: 'fiscal-address-country',
                        },
                    ];
                default:
                    return [];
            }
        };

        return (
            <ConfirmationView
                items={getAddressList()}
                onConfirm={onConfirm}
                onCancel={onClose}
                subHeadline={t('edit-view.confirmation-modal.headline')}
            />
        );
    },
    'ChangeOfNameSummaryView',
);
