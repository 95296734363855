import * as Yup from 'yup';
import { LicenseError, LicensePlateError, TechnicalDocumentNumberError } from '../types';
import { UpdateLicensePlate } from '../../apis';
import { defaultLicenseError, defaultLicensePlateError, defaultTechnicalDocumentNumberError } from '../messages';
import { filesInfoValidator } from './FileInfoValidator';

const licensePlateFormat = /^[A-Za-z]{2}[A-Za-z0-9]{5}$/;
const technicalDocumentNumberFormat = /^[A-Za-z]{2}[0-9]{6}$/;

export const licenseValidator = (errors: LicenseError = defaultLicenseError): Yup.StringSchema =>
    Yup.string().trim().required(errors.required).matches(licensePlateFormat, errors.format);

export const technicalDocumentNumberValidator = (
    errors: TechnicalDocumentNumberError = defaultTechnicalDocumentNumberError,
): Yup.StringSchema =>
    Yup.string().trim().required(errors.required).matches(technicalDocumentNumberFormat, errors.format);

export const licensePlateTextInputsValidator = (
    errors: Omit<LicensePlateError, 'files'> = defaultLicensePlateError,
): Yup.SchemaOf<Omit<UpdateLicensePlate, 'files'>> =>
    Yup.object()
        .shape({
            newLicensePlate: licenseValidator(errors.newLicensePlate),
            technicalDocumentNumber: technicalDocumentNumberValidator(errors.technicalDocumentNumber),
        })
        .defined();

export const licensePlateValidator = (
    errors: LicensePlateError = defaultLicensePlateError,
): Yup.SchemaOf<UpdateLicensePlate> => licensePlateTextInputsValidator(errors).concat(filesInfoValidator(errors.files));
