import { useGetSimpleUpdateData } from '@cp-shared-5/frontend-integration';

import { CustomerType } from '@cp-sk/common';
import { updateCustomerType } from './CustomerTypeSlice';
import { CustomerTypeDataSelector } from './CustomerTypeDataSelector';

type UpdateCustomerDataType = {
    updateCustomerType: (data: CustomerType) => void;
    customerTypeData: CustomerType;
};

export function useUpdateCustomerType(): UpdateCustomerDataType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateCustomerType, CustomerTypeDataSelector);
    return {
        updateCustomerType: updateData,
        customerTypeData: data as CustomerType,
    };
}
