export type ManageInsurancePoliciesFormValues = {
    insurerName: string;
    policyNumber: string;
    insuredCarPrice: string;
    files: File[];
};

export const initialValues: ManageInsurancePoliciesFormValues = {
    insurerName: '',
    policyNumber: '',
    insuredCarPrice: '',
    files: [],
};
