import { CPDate } from '@cp-shared-5/common-utilities';
import { TFunction } from 'i18next';
import { AddressType, FiscalAddress, PostalAddress } from '@cp-sk/common';

export type AddressFormValues = {
    address: {
        street: string;
        secondLine?: string;
        postalCode: string;
        city: string;
        country: string;
    };
    personIdCardData: {
        type: string;
        number: string;
        issueDate: CPDate;
        issueCity: string;
    };
    frontOfDocument: File[];
    backOfDocument: File[];
    registryDocument: File[];
};

export const getInitialValues = (
    addressType: AddressType,
    address: PostalAddress | FiscalAddress,
    t: TFunction,
): AddressFormValues => {
    const translationPrefix = 'edit-view.common';
    return {
        personIdCardData: {
            issueCity: '',
            issueDate: '',
            type: '',
            number: '',
        },
        backOfDocument: [],
        frontOfDocument: [],
        registryDocument: [],
        address: {
            street: address.street ?? '',
            secondLine: (addressType === AddressType.FISCAL ? (address as FiscalAddress).secondLine : '') ?? '',
            postalCode: address.postalCode ?? '',
            city: address.city ?? '',
            country: address.country || t(`${translationPrefix}.country.default-value`),
        },
    };
};
