import { ProofOfInsuranceError, ProofOfInsuranceFeError } from '../types';
import { defaultProofOfInsuranceError, defaultProofOfInsuranceFeError } from '../messages';
import * as Yup from 'yup';
import { ProofOfInsurance } from '../../apis';
import { filesInfoValidator } from './FileInfoValidator';

export type ProofOfInsuranceFe = Pick<ProofOfInsurance, 'policyNumber'>;
export const proofOfInsuranceValidatorFE = (
    errors: ProofOfInsuranceFeError = defaultProofOfInsuranceFeError,
): Yup.SchemaOf<ProofOfInsuranceFe> =>
    Yup.object().shape({
        policyNumber: Yup.string().trim().required(errors.policyNumber.required),
    });

export const proofOfInsuranceValidator = (
    errors: ProofOfInsuranceError = defaultProofOfInsuranceError,
): Yup.SchemaOf<ProofOfInsurance> =>
    filesInfoValidator(errors.files)
        .concat(proofOfInsuranceValidatorFE(errors))
        .shape({
            insurerName: Yup.string().trim().required(errors.insurerName.required),
            insuredCarPrice: Yup.number()
                .required(errors.insuredCarPrice.required)
                .moreThan(0, errors.insuredCarPrice.moreThan),
            insurerId: Yup.number().required(errors.insurerId.required).moreThan(0, errors.insurerId.moreThan),
        });
