import { getContractDetailsEndpoint } from './contracts';

export function getEarlySettlementEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/early-settlement`;
}

export function getEarlySettlementPdfDownloadEndpoint(contractId: string): string {
    return `${getEarlySettlementEndpoint(contractId)}/document`;
}

export function getPartialEarlySettlementEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/partial-early-settlement`;
}

export function getAcceptPartialEarlySettlementEndpoint(contractId: string): string {
    return `${getPartialEarlySettlementEndpoint(contractId)}/accept`;
}
