import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LicensePlate, ProductInfo, Heading } from '@vwfs-bronson/bronson-react';
import { CollapsibleVehicleDetails } from './collapsible-vehicle-details';
import { DefinitionListHorizontal, DefinitionListItem, Skeleton } from '@cp-shared-5/frontend-ui';
import { VehicleImageServiceResponse, VehicleImageView, VehicleServiceResponse } from '@cp-shared-5/apis';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import {
    capitalizeWord,
    getBrand,
    getBrandTranslation,
    getColorFinishTranslation,
    getColorTranslation,
    getTransmissionTranslation,
} from './utils';
import { Brand } from './enums';

type VehicleDetailsAndImageUiProps = {
    vin: string;
    brandModelType?: string;
    licensePlate?: string;
    vehicleDetails?: VehicleServiceResponse;
    isLoadingVehicleDetails?: boolean;
    vehicleDetailsLoadingError: boolean;
    vehicleImages?: VehicleImageServiceResponse;
    isLoadingVehicleImage?: boolean;
    vehicleImageLoadingError: boolean;
    defaultView: VehicleImageView;
};

export const VehicleDetailsAndImageUi: React.FC<VehicleDetailsAndImageUiProps> = ({
    vin,
    brandModelType,
    licensePlate,
    vehicleDetails,
    isLoadingVehicleDetails,
    vehicleDetailsLoadingError,
    isLoadingVehicleImage,
    vehicleImageLoadingError,
    vehicleImages,
    defaultView,
}) => {
    const { t } = useTranslation('vehicle-details-and-image');
    const [title, setTitle] = useState<React.ReactNode | string>(<Skeleton height={40} />);
    const [subtitle, setSubtitle] = useState('');

    useEffect(() => {
        if (vehicleDetailsLoadingError) {
            setTitle(brandModelType);
        }
        if (vehicleDetails) {
            const {
                brand = '',
                modelName = '',
                modelVariant = '',
                transmission = '',
                bodyColorName = '',
                bodyColorFinish = '',
                trim = '',
            } = vehicleDetails || {};
            setTitle(
                [getBrandTranslation(brand, t), modelName, modelVariant, trim]
                    .filter(Boolean)
                    .map(capitalizeWord)
                    .join(' ') || brandModelType,
            );
            setSubtitle(
                [
                    getTransmissionTranslation(transmission, t),
                    getColorTranslation(bodyColorName, t),
                    getColorFinishTranslation(bodyColorFinish, t),
                ]
                    .filter(Boolean)
                    .map(capitalizeWord)
                    .join(' | '),
            );
        }
    }, [vehicleDetails, vehicleDetailsLoadingError, brandModelType, t]);

    const licensePlateComponent = licensePlate ? (
        <LicensePlate
            countryCode={t('translation:country-code')}
            size={'small'}
            registrationNumber={licensePlate}
            horizontalStripEu={true}
            euStars={true}
        />
    ) : null;

    const getBasicDetails = useCallback(
        (t: TFunction, vin: string, licensePlate?: string): DefinitionListItem[] => {
            return compact([
                licensePlate && {
                    label: t('license-plate'),
                    value: licensePlateComponent,
                    testId: 'license-plate',
                },
                vin && {
                    label: t('vin'),
                    value: vin,
                    testId: 'vin',
                },
            ]);
        },
        [licensePlateComponent],
    );

    const itemList: DefinitionListItem[] = useMemo(
        () => getBasicDetails(t, vin, licensePlate),
        [getBasicDetails, t, vin, licensePlate],
    );

    const getImageSrc = () => {
        const image = vehicleImages?.images?.find(({ view }) => view === defaultView);
        const showDefault = vehicleImageLoadingError || (!isLoadingVehicleImage && !image);
        if (!isLoadingVehicleImage && image) {
            return image.url;
        }
        if (showDefault) {
            const brand = getBrand(brandModelType);
            switch (brand) {
                case Brand.AUDI:
                    return '/contents/vwfs/audi_default.svg';
                case Brand.SEAT:
                    return '/contents/vwfs/seat_default.svg';
                case Brand.SKODA:
                    return '/contents/vwfs/skoda_default.svg';
                case Brand.VW:
                    return '/contents/vwfs/vw_default.svg';
                default:
                    return '/contents/vwfs/vw_default.svg';
            }
        }
        return '';
    };

    return (
        <>
            <Heading level={'3'} className={'c-product-info__title u-mb-none'}>
                {title}
            </Heading>
            <ProductInfo subtitle={subtitle} imgSrc={getImageSrc()} className={'u-mb'}>
                <div className={'u-mt u-hide@s'} />
                <DefinitionListHorizontal list={itemList} testId={'basic-details'} />
                <CollapsibleVehicleDetails
                    details={vehicleDetails}
                    isLoading={isLoadingVehicleImage && isLoadingVehicleDetails}
                />
            </ProductInfo>
        </>
    );
};
