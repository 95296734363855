import { CPDate } from '@cp-shared-5/common-utilities';

export type BundledProducts = {
    carInsurance?: CarInsurance[];
    otherInsurance?: OtherInsurance;
    serviceAndMaintenance?: ServiceAndMaintenance[];
};

export type BaseInsurance = {
    productName?: string;
    statusCode?: BundledProductStatusCode;
    status?: string;
};

export type CarInsurance = BaseInsurance & {
    productType?: BundledProductType;
    insurer?: string;
    policyNumber?: string;
    startDate?: CPDate;
    endDate?: CPDate;
    endLabel?: string;
    monthlyAmount?: string;
    participationRateOfKaskoInsuranceV?: string;
    participationRateOfKaskoInsuranceN?: string;
};

export type OtherInsurance = BaseInsurance & {
    productType?: BundledProductType;
    insurer?: string;
    monthlyAmount?: string;
};

export type ServiceAndMaintenance = BaseInsurance & {
    productType?: ServiceAndMaintenanceProductType;
    startDate?: CPDate;
    endDate?: CPDate;
    servicePackage?: string;
    tireClass?: string;
};

export enum BundledProductType {
    MOTOR_INSURANCE = 'MOTOR_INSURANCE',
    ACCIDENT_INSURANCE = 'ACCIDENT_INSURANCE',
    GAP_INSURANCE = 'GAP_INSURANCE',
    CREDIT_PROTECTION_INSURANCE = 'CREDIT_PROTECTION_INSURANCE',
}

export enum BundledProductStatusCode {
    A = 'part-of-the-payment',
    N = 'not-part-of-the-payment',
    E = 'ended',
}

export enum ServiceAndMaintenanceProductType {
    S = 'SERVICE_AND_MAINTENANCE',
    Q = 'TIRES',
}
