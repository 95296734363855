import { FileInfoError, FilesInfoError } from '../types';

export const defaultFileInfoError: FileInfoError = {
    docTypeId: { required: 'Doc type id for file info is required.' },
    fileId: { required: 'File id for file info is required.' },
};
export const defaultFilesInfoError: FilesInfoError = {
    file: defaultFileInfoError,
    max: 'To many Files info.',
    min: 'Not enough files.',
    required: 'Files info array is required.',
};
