import {
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-5/frontend-ui';
import { FormikErrors } from 'formik';
import { flattenObject } from 'utils/flattenObject';

export const IDENTIFICATION_SECTION = 'Identification';

type EditIdentificationActionTrackersNames =
    | 'onTyping'
    | 'onValidationError'
    | 'onShowConfirmation'
    | 'onConfirmationCancel';

export const useOnEditIdentificationActionTrackers = (): Record<EditIdentificationActionTrackersNames, Function> => {
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, IDENTIFICATION_SECTION);
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileAddressTypedIn',
    });
    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileIdentificationValidationError');
    const { onAction: onShowConfirmation } = useAnalyticsActionTracker('onEditProfileConfirmation');
    const { onAction: onConfirmationCancel } = useAnalyticsActionTracker('onEditProfileConfirmationCancel');

    return { onTyping, onValidationError, onShowConfirmation, onConfirmationCancel };
};

export const getErrors = (errors: { [k: string]: string | FormikErrors<File>[] | undefined | object }): string =>
    Object.keys(flattenObject(errors)).join(`, `);
