export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json`;
}

export const getLandingPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-teaser');
};

export const getLandingPageMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-marketing-cards');
};

export const getLegalFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-footer');
};

export const getIconFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'icon-footer');
};

export const getFaqEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq');
};

export const getNotFoundErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'not-found-error');
};

export const getForbiddenErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'forbidden-error');
};

export const getUnauthorizedErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'unauthorized-error');
};

export const getCookiePolicyEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'cookie-policy');
};

export const getLegalNoticeEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-notice');
};

export const getPrivacyPolicyEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'privacy-policy');
};

export const getDashboardMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'dashboard-marketing-card');
};

export const getLogoutSuccessEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'logout-success');
};

export const getRequestMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'request-marketing-card');
};
export const getGenericErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'generic-error');
};
