import { defaultFileInfoError } from './FileInfoMessages';
import { ProofOfCarOwnershipError } from '../types';
import { defaultLicenseError, defaultTechnicalDocumentNumberError } from './LicensePlateMessages';

export const defaultProofOfCarOwnershipError: ProofOfCarOwnershipError = {
    files: {
        file: defaultFileInfoError,
        max: 'Too much info files.',
        min: 'Not enough info files.',
        required: 'Files with info are required.',
    },
    licensePlate: defaultLicenseError,
    numberOfTechnicalDocument: defaultTechnicalDocumentNumberError,
    dateOfFirstRegistrationInSk: {
        required: 'Date of first registration in sk is required.',
        valid: 'Date of first registration in sk is not valid date.',
    },
    yearOfProduction: { required: 'Year of production is required.' },
};
