import React from 'react';
import { PaymentOverviewDetails } from '@cp-sk/common';
import { createDynamicTable, DynamicTableFormatOptions } from '@cp-shared-5/frontend-ui';
import { useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { generateRow, getColumnHeadings, sortOptions, ToColumnValues } from './utils';

export type PaymentOverviewTableProps = {
    PaymentOverviewDetails: PaymentOverviewDetails[];
};

const DynamicTable = createDynamicTable<PaymentOverviewDetails>();

export const PaymentOverviewTable: React.FC<PaymentOverviewTableProps> = ({ PaymentOverviewDetails }) => {
    const { t, f } = useTranslationWithFormatting('payment-overview');

    const formatOptions: DynamicTableFormatOptions = {
        auto: false,
        noScroll: false,
        colored: false,
        bordered: false,
        highlight: false,
        wide: true,
        narrow: false,
    };

    return (
        <DynamicTable
            rows={PaymentOverviewDetails}
            columnHeadings={getColumnHeadings(t)}
            formatOptions={formatOptions}
            sortOptions={sortOptions}
            toColumnValues={(row: PaymentOverviewDetails): ToColumnValues => generateRow(row, f)}
        />
    );
};
