export function getContractsEndpoint(): string {
    return '/contracts';
}

export function getContractDetailsEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}`;
}

export function getUpdateLicensePlateEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/update-license-plate`;
}

export function getOverpaymentManagementEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/overpayment`;
}

export function getChangePaymentDayEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/payment-day`;
}

export function getProofOfCarOwnershipEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/proof-of-car-ownership`;
}

export function getProofOfInsuranceEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}/proof-of-insurance`;
}

export function getPaymentOverviewPdfDownloadEndpoint(encryptedContractNumber: string): string {
    return `${getContractsEndpoint()}/${encryptedContractNumber}/payment-overview/download`;
}

export function getMileageEndpoint(encryptedContractNumber: string): string {
    return `${getContractsEndpoint()}/${encryptedContractNumber}/mileage`;
}

export function getAcceptMileageEndpoint(encryptedContractNumber: string): string {
    return `${getContractsEndpoint()}/${encryptedContractNumber}/accept-mileage`;
}
