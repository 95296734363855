import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Fieldset, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { CleaveInput, Spinner, ValidatedInput } from '@cp-shared-5/frontend-ui';
import Insurers from './insurer-selection/isurers.json';
import { InsurerSelection } from './insurer-selection';
import { useHistory } from 'react-router';
import { dashboardPagePath } from '../navigation/paths';
import { initialValues, ManageInsurancePoliciesFormValues } from './initailValues';
import { SummaryView } from './summary-view';
import {
    EditStatus,
    FileInfo,
    getProofOfInsuranceEndpoint,
    ProofOfInsurance,
    ProofOfInsuranceFeError,
} from '@cp-sk/common';
import { getValidationSchema } from './validationSchema';
import { FileUpload } from '../file-upload';
import { uploadFilesDirectToMediaStorage } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import { getInsurerId, transformCurrencyToNumber } from './utils';
import { InsurersType } from './insurer-selection/types';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';

const DOCTYPE_ID = 113;

type FormViewProps = { encryptedContractNumber?: string };

export const ManageInsurancePolicies: React.FC<FormViewProps> = ({ encryptedContractNumber }) => {
    const { t } = useTranslation('manage-insurance-policies');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const history = useHistory();

    if (!encryptedContractNumber) return null;

    const onConfirm = (values: ManageInsurancePoliciesFormValues) => {
        setIsSubmitting(true);

        const { insurerName, insuredCarPrice, policyNumber, files } = values;

        const documentFile = uploadFilesDirectToMediaStorage(files, CpDataApi);

        const doAfterSuccessfulUpload = (documentFile: string[]): void => {
            const body: ProofOfInsurance = {
                insurerName,
                insurerId: getInsurerId(Insurers as InsurersType, insurerName),
                insuredCarPrice: transformCurrencyToNumber(insuredCarPrice),
                policyNumber,
                files: [
                    ...documentFile.map(
                        (fileId: string): FileInfo => ({
                            docTypeId: DOCTYPE_ID,
                            fileId,
                        }),
                    ),
                ],
            };

            CpDataApi.put(getProofOfInsuranceEndpoint(encryptedContractNumber), body)
                .then(() => {
                    setLastEditStatus(EditStatus.SUCCESS);
                })
                .catch(() => {
                    setLastEditStatus(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        const doAfterUploadError = (): void => {
            setIsSubmitting(false);
            setLastEditStatus(EditStatus.ERROR);
        };

        Promise.all([documentFile])
            .then(([documentId]) => doAfterSuccessfulUpload(documentId))
            .catch(() => {
                doAfterUploadError();
            });
    };

    const onSubmit = (): void => {
        setShowConfirmation(true);
    };

    const onClose = (): void => {
        setShowConfirmation(false);
        setLastEditStatus(EditStatus.NOT_PERFORMED);
    };

    const errors: ProofOfInsuranceFeError = {
        insurerName: {
            required: t('form-view.insurer.validation.required'),
        },
        policyNumber: {
            required: t('form-view.policy-number.validation.required'),
        },
        insuredCarPrice: {
            required: t('form-view.insured-car-price.validation.required'),
            moreThan: t('form-view.insured-car-price.validation.required'),
        },
        files: {
            min: t('form-view.files-upload.validation.required'),
            max: t('form-view.files-upload.validation.maximum-exceeded'),
        },
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Heading level={1}>{t('form-view.title')}</Heading>
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={true}
                validationSchema={getValidationSchema(errors)}
            >
                {({ values, submitForm }) => (
                    <Form onSubmit={(e) => e.preventDefault()} data-testid="insurance-policies-form">
                        {showConfirmation ? (
                            <SummaryView
                                formValues={values}
                                onConfirm={(): void => onConfirm(values)}
                                onClose={onClose}
                                lastEditStatus={lastEditStatus}
                            />
                        ) : (
                            <>
                                <div
                                    className={'u-mb-small'}
                                    dangerouslySetInnerHTML={{ __html: t('form-view.subtitle') }}
                                />
                                <div
                                    className={'u-mb'}
                                    dangerouslySetInnerHTML={{ __html: t('form-view.description') }}
                                />
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <InsurerSelection
                                                    label={t('form-view.insurer.label')}
                                                    name="insurerName"
                                                    testId="insurer"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <ValidatedInput
                                                    label={t('form-view.policy-number.label')}
                                                    name={'policyNumber'}
                                                    inputMode={'text'}
                                                    testId="policy-number"
                                                />
                                            </Layout.Item>
                                            <Layout.Item default="1/2" s="1/1">
                                                <CleaveInput
                                                    cleaveOptions={{
                                                        numeral: true,
                                                        numeralDecimalMark: ',',
                                                        delimiter: '.',
                                                        numeralThousandsGroupStyle: 'thousand',
                                                        numericOnly: true,
                                                        numeralDecimalScale: 2,
                                                    }}
                                                    inputMode={'numeric'}
                                                    label={t('form-view.insured-car-price.label')}
                                                    name="insuredCarPrice"
                                                    testId="insured-car-price"
                                                    addonText={t('translation:currency')}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <FileUpload name={'files'} />
                                </Fieldset>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            secondary
                                            onClick={(): void => {
                                                history.push(dashboardPagePath());
                                            }}
                                            testId="cancel-button"
                                            type="button"
                                        >
                                            {t(`translation:editable-section-nav.cancel`)}
                                        </Button>
                                        <Button onClick={submitForm} testId="submit-button" type="submit">
                                            {t(`translation:editable-section-nav.submit`)}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};
