import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLastLocation } from 'react-router-last-location';
import { ExpandableContent, FormSectionGroup } from '@vwfs-bronson/bronson-react';
import { useAnalyticsDashboardPageTracker } from '@cp-shared-5/frontend-ui';
import { Contract } from '@cp-sk/common';
import { FinancialContract } from '../financial-contract';
import { ErrorNotification, ErrorNotificationProps } from '../../notifications/error/ErrorNotification';
import { groupContractsByVin, sortGroupedContractsByStartDate } from './utils';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image';

export const ContractsUi: React.FC<{ contracts?: Contract[] }> = ({ contracts }) => {
    const { t } = useTranslation('contracts');

    const unpaid = contracts && contracts.some((contract) => contract.unpaidAlert?.headline);
    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? '/authentication/callback' : lastLocation?.pathname;
    useAnalyticsDashboardPageTracker(!!contracts?.length, !!unpaid, lastPathname);

    const onlyValidContractsList = useMemo(
        () =>
            (contracts || []).filter(
                ({ brandModelType, contractNumber, contractType }) => brandModelType && contractNumber && contractType,
            ),
        [contracts],
    );

    const areAllContractsValid = useMemo(
        () => contracts?.length === onlyValidContractsList.length,
        [contracts, onlyValidContractsList.length],
    );

    const getListOfContracts = useCallback(
        (isActive: boolean) => {
            const filteredContracts = onlyValidContractsList.filter((contract) => contract.isActive === isActive);

            const groupedContractsByVin = groupContractsByVin(filteredContracts);
            const sortedGroupsOfContracts = sortGroupedContractsByStartDate(groupedContractsByVin);

            return sortedGroupsOfContracts.length ? (
                <FormSectionGroup>
                    {sortedGroupsOfContracts.map((contracts) =>
                        contracts.map((contract, contractIndex) => (
                            <React.Fragment key={contract.contractNumber}>
                                {contractIndex === 0 &&
                                    (!contract.vin || !contract.encryptedVin ? (
                                        <ErrorNotification testId={'missing-vin-error'} className={'u-mb-xsmall'} />
                                    ) : (
                                        <VehicleDetailsAndImage
                                            vin={contract.vin}
                                            encryptedVin={contract.encryptedVin}
                                            brandModelType={contract.brandModelType}
                                            licensePlate={contract.licensePlate}
                                        />
                                    ))}
                                <FinancialContract
                                    contract={contract}
                                    key={contract.contractNumber}
                                    className={contractIndex === contracts.length - 1 ? 'u-mb-large' : 'u-mb'}
                                    defaultExpanded={isActive && contractIndex === 0}
                                />
                            </React.Fragment>
                        )),
                    )}
                </FormSectionGroup>
            ) : null;
        },
        [onlyValidContractsList],
    );

    const activeContracts = useMemo(() => getListOfContracts(true), [getListOfContracts]);
    const inactiveContracts = useMemo(() => getListOfContracts(false), [getListOfContracts]);

    if (!contracts) {
        return null;
    }

    const getErrorNotificationProps = (): ErrorNotificationProps | undefined => {
        if (!contracts.length) {
            return {
                testId: 'no-contracts-for-account-error',
                text: t('no-contracts-for-account-error'),
            };
        }
        if (!areAllContractsValid) {
            return { testId: 'missing-contracts-fields-error', className: 'u-mb' };
        }
        return { shown: false };
    };

    return (
        <>
            <ErrorNotification {...getErrorNotificationProps()} />
            {activeContracts}
            {!!inactiveContracts && (
                <ExpandableContent
                    lazyRender={true}
                    triggerLabel={t('expand.trigger')}
                    noBackground
                    pageWrapClassName={'u-pl-none u-pr-none'}
                >
                    <h4 className="u-text-center">{t('expand.headline')}</h4>
                    <FormSectionGroup>{inactiveContracts}</FormSectionGroup>
                </ExpandableContent>
            )}
        </>
    );
};
