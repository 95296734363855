// The following URLs needs to be whitelisted within IDK (including domain and port)
export function logoutPath(): string {
    return '/logout';
}

export function notFoundPagePath(): string {
    return '/not-found';
}
export function errorPagePath(): string {
    return '/error';
}

export function registrationPagePath(): string {
    return '/register';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function privacyPolicyPath(): string {
    return '/privacy-policy';
}

export function landingPagePath(): string {
    return '/';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function changeLogRequestPagePath(): string {
    return '/change-log';
}

export function myDocumentsPagePath(): string {
    return '/my-documents';
}

export function changeFiscalAddressPath(): string {
    return `${myProfilePagePath()}/change-fiscal-address`;
}

export function changePostalAddressPath(): string {
    return `${myProfilePagePath()}/change-postal-address`;
}
export function changeIbanPath(): string {
    return `${myProfilePagePath()}/change-bank-details`;
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function changeMarketingSettingsPath(): string {
    return `${myProfilePagePath()}/change-marketing-settings`;
}

export function changeIdentificationPath(): string {
    return `${myProfilePagePath()}/change-identification`;
}

export function changeLicensePlatePath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-license-plate`;
}

export function changePaymentDayPath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-payment-day`;
}

export function changeContractDurationPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/change-contract-duration/${encryptedContractNumber}`;
}

export function myRequestsPagePath(): string {
    return '/my-requests';
}

export function amortizationTablePath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/amortization-table/${encryptedContractNumber}`;
}

export function earlySettlementPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/early-settlement/${encryptedContractNumber}`;
}

export function totalEarlySettlementPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/total-early-settlement/${encryptedContractNumber}`;
}

export function partialEarlySettlementPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/partial-early-settlement/${encryptedContractNumber}`;
}

export function changeMileagePath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/change-mileage/${encryptedContractNumber}`;
}

export function paymentDetailsPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/payment-details/${encryptedContractNumber}`;
}

export function manageInsurancePoliciesPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/manage-insurance-policies/${encryptedContractNumber}`;
}

export function overpaymentManagementPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/overpayment-management/${encryptedContractNumber}`;
}

export function proofOfCarOwnershipPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/proof-of-car-ownership/${encryptedContractNumber}`;
}

export function paymentOverviewPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/payment-overview/${encryptedContractNumber}`;
}
