import { ProofOfInsuranceError, ProofOfInsuranceFeError } from '../types';
import { defaultFilesInfoError } from './FileInfoMessages';

export const defaultProofOfInsuranceFeError: ProofOfInsuranceFeError = {
    insurerName: { required: 'Insurance company name is required.' },
    insuredCarPrice: {
        moreThan: 'Insured car price cannot be lower than 0.',
        required: 'Insured car price is required.',
    },
    policyNumber: { required: 'Number of insurance policy is required.' },
    files: { max: 'Too much info files.', min: 'Not enough info files.' },
};

export const defaultProofOfInsuranceError: ProofOfInsuranceError = {
    ...defaultProofOfInsuranceFeError,
    insurerId: {
        moreThan: 'Insurance company id cannot be lower than 0.',
        required: 'Insurance company id is required.',
    },
    files: defaultFilesInfoError,
};
