import React from 'react';
import { AddressType, CustomerType, EditStatus, FiscalAddress, PostalAddress } from '@cp-sk/common';
import { AddressList } from './AddressList';
import { useTranslation } from 'react-i18next';
import { DataOverview } from '@cp-shared-5/frontend-ui';
import { addressValuesAreEmpty } from '../../utils';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';

export type ConsultViewProps = {
    address?: PostalAddress | FiscalAddress;
    addressType: AddressType;
    customerType?: CustomerType;
    lastEditStatus?: EditStatus;
    startEditing?: () => void;
};

export const CommonConsultView: React.FC<ConsultViewProps> = ({
    address,
    addressType,
    customerType,
    lastEditStatus,
    startEditing,
}) => {
    const { t } = useTranslation('my-profile-addresses-details');
    const title =
        addressType === AddressType.FISCAL
            ? t('consult-view.fiscal.title')
            : customerType
            ? t(`consult-view.${addressType.toLocaleLowerCase()}.title.${customerType.toLocaleLowerCase()}`)
            : '';

    const isAddressDefined = address && !addressValuesAreEmpty(address);

    const buttonLabel = t(`consult-view.fiscal.${isAddressDefined ? 'edit' : 'add'}`);

    return (
        <DataOverview
            title={title}
            withoutCardEffect={true}
            className={'u-pt-none'}
            buttonLabel={buttonLabel}
            buttonProps={{
                onClick: () => {
                    startEditing && startEditing();
                },
                testId: 'edit-button',
            }}
        >
            {addressType === AddressType.POSTAL && !isAddressDefined ? null : (
                <AddressList address={address} addressType={addressType} />
            )}
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
        </DataOverview>
    );
};
