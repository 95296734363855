import { ChangeRequestLogEntryWithId } from '@cp-sk/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-5/frontend-integration';
import { fetchChangeLogs } from './ChangeLogSlice';
import { ChangeLogDataSelector } from './ChangeLogDataSelector';

export function useChangeLogs(): AbstractDataState<ChangeRequestLogEntryWithId[], DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchChangeLogs, ChangeLogDataSelector);
}
