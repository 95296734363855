import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { ChangeLogRequestUi } from './ui/ChangeLogRequestUi';
import { useChangeLogs } from './useChangeLogs';

const ChangeLogWithHandlers = withLoadingAndNoConnectionHandler(ChangeLogRequestUi);

export const ChangeLogEntry: React.FC = () => {
    const { data: changeRequestLogEntries, isLoading, loadingError } = useChangeLogs();

    return (
        <ChangeLogWithHandlers
            isLoading={isLoading}
            changeRequestLogEntries={changeRequestLogEntries || []}
            hasError={!!loadingError}
        />
    );
};
