import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const subjectMaxLength = 60;
export const messageMaxLength = 1000;

export const validationSchema = (t: TFunction) => {
    const requiredFieldError = t('validation-error.required-field');
    const maxLengthError = t('validation-error.max-length-error');
    return Yup.object().shape({
        subject: Yup.string().required(requiredFieldError).max(subjectMaxLength, maxLengthError),
        message: Yup.string().required(requiredFieldError).max(messageMaxLength, maxLengthError),
    });
};
