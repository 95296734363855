import React from 'react';
import { FiscalAddress, Addresses, AddressType, CustomerType, EditStatus } from '@cp-sk/common';
import { PostalAddressSection } from './postal-address/PostalAddressSection';
import { FiscalAddressSection } from './fiscal-address/FiscalAddressSection';
import { useTranslation } from 'react-i18next';
import { DataOverview, useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-5/frontend-ui';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { changeFiscalAddressPath, changePostalAddressPath } from '../../navigation/paths';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const AddressesSection: React.FC<{ addresses?: Addresses; customerType?: CustomerType }> = ({
    addresses,
    customerType,
}) => {
    const { t } = useTranslation('my-profile-addresses-details');
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileAddressSubmitFailed',
        confirmSuccess: 'onEditProfileAddressSuccess',
    });
    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    const setLastEditStatus = (
        addressType: AddressType,
        newEditStatus: EditStatus,
        updatedAddress?: FiscalAddress,
    ): void => {
        const reduxFieldName = addressType === AddressType.POSTAL ? 'postalAddress' : 'fiscalAddress';
        updateMyProfile({
            ...myProfileData,
            addresses: {
                ...myProfileData.addresses,
                ...(updatedAddress ? { [reduxFieldName]: updatedAddress } : {}),
            },
            lastEditStatus: {
                ...myProfileData?.lastEditStatus,
                addresses: {
                    ...myProfileData?.lastEditStatus?.addresses,
                    [reduxFieldName]: newEditStatus,
                },
            },
        });
    };

    const startEditingFiscalAddress = (): void => {
        onStart();
        setLastEditStatus(AddressType.FISCAL, EditStatus.NOT_PERFORMED);
        history.push(changeFiscalAddressPath());
    };

    const startEditingPostalAddress = (): void => {
        onStart();
        setLastEditStatus(AddressType.POSTAL, EditStatus.NOT_PERFORMED);
        history.push(changePostalAddressPath());
    };

    const cancelEditing = (addressType: AddressType): void => {
        onCancel('Address');
        setLastEditStatus(addressType, EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (
        addressType: AddressType,
        newEditStatus: EditStatus,
        updatedAddress?: FiscalAddress,
    ): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setLastEditStatus(addressType, newEditStatus, updatedAddress);
        history.push(path);
    };

    return (
        <DataOverview title={t(`consult-view.title`)} className={'u-pr-none u-pb-none'}>
            <PostalAddressSection
                address={addresses?.postalAddress}
                customerType={customerType}
                lastEditStatus={myProfileData?.lastEditStatus?.addresses?.postalAddress}
                startEditing={startEditingPostalAddress}
                cancelEditing={cancelEditing}
                finishEditing={finishEditing}
            />
            <FiscalAddressSection
                address={addresses?.fiscalAddress}
                customerType={customerType}
                lastEditStatus={myProfileData?.lastEditStatus?.addresses?.fiscalAddress}
                startEditing={startEditingFiscalAddress}
                cancelEditing={cancelEditing}
                finishEditing={finishEditing}
            />
        </DataOverview>
    );
};
