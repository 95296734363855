import * as Yup from 'yup';
import { ChangeMileageValidatorErrors } from '../types';
import { defaultChangeMileageError } from '../messages';

export type ChangeMileageFormTypes = {
    newMileageAmount: number | undefined;
};

export const changeMileageValidationSchema = (
    currentMileage: number,
    errors: ChangeMileageValidatorErrors = defaultChangeMileageError,
): Yup.SchemaOf<ChangeMileageFormTypes> =>
    Yup.object().shape({
        newMileageAmount: Yup.number().test(
            'isSameMileage',
            errors.newMileageAmount.sameAsPrevious,
            (newMileage: number): boolean => {
                return newMileage !== currentMileage;
            },
        ),
    });
