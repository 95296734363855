import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { OverpaymentManagement } from '../../components/overpayment-management';
import { useContract } from '../../components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from '../../components/integration-wrapper';

const OverpaymentManagementWithHandlers = withLoadingAndNoConnectionHandler(OverpaymentManagement);

export const OverpaymentManagementPage: React.FC = () => {
    const { t } = useTranslation('overpayment-management');
    const { encryptedContractNumber } = useParams();
    const [loadingStarted, setLoadingStarted] = useState(false);

    const {
        data: contract,
        isLoading,
        loadingError,
    } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });

    const onLoading = useCallback(() => {
        if (isLoading) setLoadingStarted(true);
    }, [isLoading]);

    useEffect(() => {
        onLoading();
    }, [onLoading]);

    const isAllowed = contract?.overpaymentAlert?.headline || contract?.overpaymentAlert?.description;

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('title')}</Heading>
            <OverpaymentManagementWithHandlers
                isLoading={isLoading}
                hasError={!!loadingError || (loadingStarted && !isLoading && !isAllowed)}
                encryptedContractNumber={contract?.encryptedContractNumber}
            />
        </ContentSection>
    );
};
