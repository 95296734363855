import {
    DataOverview,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    ValidatedCheckbox,
    withAutoScroll,
} from '@cp-shared-5/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus, getChangeMarketingSettingsEndpoint, MarketingSettings } from '@cp-sk/common';

export type EditViewProps = {
    marketingConsentSettings?: MarketingSettings;
    cancelEditing: () => void;
    finishEditing: (editStatus: EditStatus, updatedMarketingSettings?: MarketingSettings) => void;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ marketingConsentSettings, cancelEditing, finishEditing }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [editProcessStarted, setEditProcessStarted] = useState(false);
        const { t } = useTranslation('my-profile-marketing-consent-settings');
        const { onAction: onStartTyping } = useAnalyticsActionTracker('onEditProfileConsentSettingsTypedIn');
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, 'Consent settings');

        if (!marketingConsentSettings) {
            return null;
        }
        const initialValues: MarketingSettings = {
            email: marketingConsentSettings.email || false,
            phone: marketingConsentSettings.phone || false,
            sms: marketingConsentSettings.sms || false,
            post: marketingConsentSettings.post || false,
        };

        const marketingKeys = [
            { key: 'email', name: 'email' },
            { key: 'phone', name: 'phone' },
            { key: 'sms', name: 'sms' },
            { key: 'post', name: 'post' },
        ];

        const onSubmit = (values: MarketingSettings): void => {
            setIsSubmitting(true);
            CpDataApi.put(getChangeMarketingSettingsEndpoint(), values)
                .then(() => {
                    finishEditing(EditStatus.SUCCESS, values);
                })
                .catch(() => {
                    finishEditing(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        return (
            <DataOverview title={t('edit-view.title')}>
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        {({ submitForm }) => (
                            <Form
                                onSubmit={preventSubmit()}
                                data-testid="edit-form"
                                onChange={() => {
                                    if (!editProcessStarted) {
                                        setEditProcessStarted(true);
                                        onStartTyping();
                                    }
                                }}
                            >
                                <Fieldset>
                                    <Fieldset.Row>
                                        {marketingKeys.map(({ key, name }) => (
                                            <div className={'u-mb-xsmall'} key={key}>
                                                <ValidatedCheckbox
                                                    label={t(key)}
                                                    name={name}
                                                    testId={`${key}-checkbox`}
                                                />
                                            </div>
                                        ))}
                                    </Fieldset.Row>
                                </Fieldset>
                                {!!editProcessStarted && (
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    secondary
                                                    onClick={cancelEditing}
                                                    testId="cancel-button"
                                                    type="button"
                                                >
                                                    {t('translation:editable-section-nav.cancel')}
                                                </Button>
                                                <Button onClick={submitForm} testId="submit-button" type="submit">
                                                    {t('translation:editable-section-nav.submit')}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                )}
                            </Form>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            </DataOverview>
        );
    },
    'MarketingPreferencesSectionEditView',
);
