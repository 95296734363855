import React from 'react';
import { Layout, Modal, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ChangeRequestLogEntryDetails, formatAsDate } from '@cp-sk/common';

interface ChangeLogModalProps {
    showDetails: boolean;
    setShowDetails: (value: boolean) => void;
    details: ChangeRequestLogEntryDetails;
}

export const ChangeLogModal: React.FC<ChangeLogModalProps> = ({
    showDetails,
    setShowDetails,
    details: { contractNumber, licensePlate, dateOfApplication, effectiveDate },
}) => {
    const { t } = useTranslation('change-log-request');

    return (
        <Modal shown={showDetails} onClose={(): void => setShowDetails(false)}>
            <Layout className="u-text-left u-mt-none u-mb-large">
                {contractNumber && (
                    <Layout.Item className="u-mt-none">
                        <h3 className="u-mb-small">
                            {t('show-details-modal.sub-heading')} {contractNumber}
                        </h3>
                    </Layout.Item>
                )}
                {licensePlate && (
                    <Layout.Item className="u-mt-none o-split">
                        <span>{t('show-details-modal.column.licence-plate')}</span>
                        <b>{licensePlate}</b>
                    </Layout.Item>
                )}
                {dateOfApplication && (
                    <Layout.Item className="u-mt-none o-split">
                        <span>{t('show-details-modal.column.date-of-application')}</span>
                        <b>{formatAsDate(dateOfApplication)}</b>
                    </Layout.Item>
                )}
                <Layout.Item className="u-mt-none o-split">
                    <span>{t('show-details-modal.column.effective-date')}</span>
                    <b>{formatAsDate(effectiveDate)}</b>
                </Layout.Item>
            </Layout>
            <Button className="c-btn--secondary" onClick={(): void => setShowDetails(false)}>
                {t('show-details-modal.button')}
            </Button>
        </Modal>
    );
};
