import { ibanLengths } from '../../static';

const stripWhitespaces = (iban?: string | null): string => iban?.replace(/\s|_/g, '') ?? '';

const getCountryCodeFromIban = (iban: string): string => stripWhitespaces(iban).substr(0, 2);

const validLength = (iban: string): number => {
    const countryCode = getCountryCodeFromIban(iban);
    return countryCode in ibanLengths ? ibanLengths[countryCode] : Math.max(...Object.values(ibanLengths));
};

const getIbanNumber = (iban: string): string => stripWhitespaces(iban).substr(2);

const isValidLength = (iban?: string): boolean => (iban ? stripWhitespaces(iban).length === validLength(iban) : false);

const isValidCountryCode = (iban?: string): boolean => (iban ? getCountryCodeFromIban(iban) in ibanLengths : false);

export const ibanValidationUtils = {
    stripWhitespaces,
    getIbanNumber,
    isValidLength,
    isValidCountryCode,
};
