export function getMyProfileEndpoint(): string {
    return `/my-profile`;
}

export function getContactDetailsEndpoint(): string {
    return `${getMyProfileEndpoint()}/contact-details`;
}

export function getChangeMarketingSettingsEndpoint(): string {
    return `${getMyProfileEndpoint()}/marketing-settings`;
}

export function getChangeFiscalAddressEndpoint(): string {
    return `${getMyProfileEndpoint()}/fiscal-address`;
}

export function getDeleteFiscalAddressEndpoint(): string {
    return getChangeFiscalAddressEndpoint();
}

export function getChangePostalAddressEndpoint(): string {
    return `${getMyProfileEndpoint()}/postal-address`;
}

export function getCompanyIdentificationEndpoint(): string {
    return `${getMyProfileEndpoint()}/company-identification`;
}

export function getPrivateIdentificationEndpoint(): string {
    return `${getMyProfileEndpoint()}/private-identification`;
}

export function getChangeIbanEndpoint(): string {
    return `${getMyProfileEndpoint()}/iban`;
}

export function getDeleteIbanEndpoint(iban: string): string {
    return `${getChangeIbanEndpoint()}/${iban}`;
}
