import * as Yup from 'yup';
import { PostalAddress, UpdateFiscalAddress, UpdatePostalAddress } from '../../apis';
import { getCities } from '../../static';
import {
    defaultAddressValidationMessages,
    defaultFiscalAddressValidationMessages,
    defaultPostalAddressValidationMessages,
} from '../messages';
import { BaseAddressValidationError, FiscalAddressValidationError, PostalAddressValidationError } from '../types';
import { filesInfoValidator } from './FileInfoValidator';
import { idCardValidator } from './IdCardValidator';

const baseAddressValidation = (
    errors: BaseAddressValidationError = defaultAddressValidationMessages,
): Yup.SchemaOf<PostalAddress> =>
    Yup.object().shape({
        street: Yup.string().trim().required(errors.street.required).max(50, errors.street.max),
        postalCode: Yup.string()
            .trim()
            .required(errors.postalCode.required)
            .matches(new RegExp(/\b\d{5}\b/), errors.postalCode.match),

        city: Yup.string().trim().required(errors.city.required).oneOf(getCities(), errors.city.oneOf),
        country: Yup.string().trim().required(errors.country.required),
    });

export const updateFiscalAddressValidator = (
    errors: FiscalAddressValidationError = defaultFiscalAddressValidationMessages,
): Yup.SchemaOf<UpdateFiscalAddress> =>
    Yup.object().shape({
        address: baseAddressValidation(errors).shape({
            secondLine: Yup.string().trim().max(50, errors.secondLine.max),
        }),
    });

export const updatePostalAddressValidator = (
    errors: PostalAddressValidationError = defaultPostalAddressValidationMessages,
): Yup.SchemaOf<UpdatePostalAddress> =>
    filesInfoValidator(errors.files).shape({
        address: baseAddressValidation(errors),
        personIdCardData: idCardValidator(errors.personIdCardData).default(undefined).notRequired(),
    });
