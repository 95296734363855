import { CompanyIdentificationError, CompanyNameError, VatNumberError } from '../types';
import { defaultFilesInfoError } from './FileInfoMessages';

export const defaultCompanyNameError: CompanyNameError = {
    required: 'Company name is required.',
    max: 'Company name cannot be longer than 200 chars.',
};
export const defaultVatNumberError: VatNumberError = {
    required: 'Vat number is required.',
    max: 'Vat number cannot be longer than 12 chars.',
    matches: 'Vat number not match to RegExp.',
};

export const defaultCompanyIdentificationError: CompanyIdentificationError = {
    companyName: defaultCompanyNameError,
    files: defaultFilesInfoError,
    vatNumber: defaultVatNumberError,
};
