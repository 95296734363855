import { CPDate } from '@cp-shared-5/common-utilities';
import { VehicleDetails } from './vehicle-details';
import { FinancialDetails } from './financial-details';
import { ContractParties } from './contract-parties';

export enum ContractType {
    CONSUMER_CREDIT = 'CONSUMER_CREDIT',
    AUTO_CREDIT = 'AUTO_CREDIT',
    FINANCIAL_LEASING = 'FINANCIAL_LEASING',
    OPERATIVE_LEASING = 'OPERATIVE_LEASING',
    BALLON_CREDIT = 'BALLON_KREDIT',
}

export type ContractAlert = {
    headline?: string;
    description?: string;
};

export type UnpaidAlert = ContractAlert & {
    detailsAvailable?: boolean;
};

export type CarOwnershipAlert = ContractAlert & {
    docExpected?: boolean;
};

export type ManageInsurancePoliciesAlert = ContractAlert & {
    docExpected?: boolean;
};

export enum ContractStatus {
    P = 'early-terminated',
    R = 'properly-terminated',
    A = 'active',
}

export type BaseContract = {
    invoicesAvailable: boolean;
    isActive: boolean;
    contractNumber?: string;
    endDate?: CPDate;
    startDate?: CPDate;
    nextPaymentDate?: CPDate;
    nextPaymentAmount?: number;
    contractType: ContractType;
    productName: string;
    status: ContractStatus;
    statusLabel?: string;
    unpaidAlert?: UnpaidAlert;
    carOwnershipAlert?: CarOwnershipAlert;
    overpaymentAlert?: ContractAlert;
    hpAlert?: ManageInsurancePoliciesAlert[];
    paymentOverviewAvailable?: boolean;
    paymentDateAvailable?: boolean;
    partialEarlySettlementAvailable?: boolean;
    changeMileageAvailable?: boolean;
    changeContractDurationAvailable?: boolean;
    vin?: string;
    encryptedVin?: string;
};

export type ConsumerCreditContract = BaseContract & {
    outstandingBalance?: number;
    contractType: ContractType.CONSUMER_CREDIT;
};

export type AutoCreditContract = BaseContract & {
    outstandingBalance?: number;
    contractType: ContractType.AUTO_CREDIT;
};

export type FinancialLeasingContract = BaseContract & {
    lastPaymentDate?: CPDate;
    contractType: ContractType.FINANCIAL_LEASING;
};

export type OperativeLeasingContract = BaseContract & {
    lastPaymentDate?: CPDate;
    contractType: ContractType.OPERATIVE_LEASING;
};

export type BallonCreditContract = BaseContract & {
    outstandingBalance?: number;
    contractType: ContractType.BALLON_CREDIT;
};

export function buildContract(contract: ContractWithLinks): Contract {
    return {
        ...contract,
        ...extractLinks(contract),
    };
}

function extractLinks(contract: ContractWithLinks): ResourceLinks {
    return {
        _links: contract._links,
    };
}

export type ContractLinks = {
    contractDetails?: string;
    bundledProducts?: string;
    amortizationDetails?: string;
    paymentDetails?: string;
    paymentOverview?: string;
    earlySettlement?: string;
};

export type ResourceLinks = {
    _links?: ContractLinks;
};

export type EncryptedContractNumber = {
    encryptedContractNumber?: string;
};

export type ContractsError = 'NOT_FOUND';

export type ContractData =
    | ConsumerCreditContract
    | AutoCreditContract
    | FinancialLeasingContract
    | OperativeLeasingContract
    | BallonCreditContract;

export type Contract = ContractData & VehicleDetails & EncryptedContractNumber;
export type ContractWithLinks = Contract & ResourceLinks;

export type ContractDetails = {
    financial: FinancialDetails;
    vehicle: VehicleDetails;
    contractParties: ContractParties;
};
