import React, { useEffect, useMemo } from 'react';
import sha1 from 'sha1';
import { chatbotApiKey, currentBrand, mercuryCssUrl, mercuryBaseUrl } from '../../config';
import { getSessionStorage } from '../../utils/get-session-storage';

export const MERCURY_AI_USER_ID_KEY = 'MercuryAIUserId';

declare global {
    interface Window {
        mercuryUser?: { userId: string | undefined; context: Record<string, unknown> };
    }
}

const currentChatbot = {
    baseUrl: mercuryBaseUrl,
    version: 'v4',
};

const getMercuryBrand = (): string => {
    switch (currentBrand) {
        case 'vwfs':
            return 'VWFS';
    }
};

export type ChatbotComponentProps = {
    apiKey: string;
    isRegistered: boolean;
};

export const ChatbotComponent: React.FC<ChatbotComponentProps> = ({ apiKey, isRegistered }) => {
    const userId = useMemo(() => {
        const { getSessionValue, setSessionValue } = getSessionStorage();
        let userId = getSessionValue(MERCURY_AI_USER_ID_KEY);
        if (!userId) {
            const timestamp = new Date().valueOf().toString(36);
            const random1 = Math.random().toString(36).substring(2, 15);
            const random2 = Math.random().toString(36).substring(2, 15);
            userId = sha1(`${currentBrand}|${timestamp}|${random1}|${random2}`);
            setSessionValue(MERCURY_AI_USER_ID_KEY, userId);
        }
        return userId;
    }, []);

    useEffect(() => {
        const brand = getMercuryBrand();

        window.mercuryUser = {
            userId,
            context: {
                'global.brand': brand,
                'global.team': 'CP',
                'global.isRegistered': isRegistered,
            },
        };

        const externalWidgetScript = document.createElement('script');
        externalWidgetScript.type = 'text/javascript';
        externalWidgetScript.src = `${currentChatbot.baseUrl}/widget/${currentChatbot.version}/widget.js`;
        externalWidgetScript.id = 'mercury-widget-snippet';
        externalWidgetScript.setAttribute('data-key', apiKey || '');

        const externalStylesheetLink = document.createElement('link');
        externalStylesheetLink.rel = 'stylesheet';
        externalStylesheetLink.type = 'text/css';
        externalStylesheetLink.href = mercuryCssUrl;
        document.head.appendChild(externalWidgetScript);
        document.head.appendChild(externalStylesheetLink);

        return (): void => {
            window.mercuryUser = undefined;
            document.head.removeChild(externalWidgetScript);
            document.head.removeChild(externalStylesheetLink);
        };
    }, [userId, apiKey, isRegistered]);

    return (
        <section className={'c-chatbot'}>
            <div className={'c-chatbot__scripts'} />
            <div className={'c-chatbot__link'} />
        </section>
    );
};
type ChatbotProps = {
    isRegistered?: boolean;
};
export const Chatbot: React.FC<ChatbotProps> = ({ isRegistered }) => {
    if (!chatbotApiKey || isRegistered === undefined) {
        return null;
    }

    return <ChatbotComponent apiKey={chatbotApiKey} isRegistered={isRegistered} />;
};
