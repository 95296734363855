import React from 'react';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    ScrollToTopOnNavigation,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { RegistrationError, RegistrationSmsVerifyError } from '@cp-sk/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import { identificationFormValidationSchema } from './validation';
import { NoConnectionNotification } from '../../../../notifications/no-connection/NoConnectionNotification';

export type FormProps = {
    smsToken: string;
};

type SmsIdentificationFormProps = {
    onResendCode: () => void;
    onSmsCodeConfirmation: (values: FormProps) => void;
    phoneNumberEnding?: string;
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError | RegistrationSmsVerifyError>;
    errorMessage?: string;
};

export const SmsIdentificationForm: React.FC<SmsIdentificationFormProps> = ({
    onResendCode,
    onSmsCodeConfirmation,
    phoneNumberEnding,
    errorCode,
    errorMessage = '',
}) => {
    const { t } = useTranslation('registration');
    const displayForm = !(
        errorCode === RegistrationSmsVerifyError.WRONG_SMS_TOKEN ||
        errorCode === RegistrationSmsVerifyError.IS_LOCKED_OUT
    );
    const displayHelpText = !errorCode || errorCode !== RegistrationSmsVerifyError.IS_LOCKED_OUT;
    const displayResendButton = errorCode === RegistrationSmsVerifyError.WRONG_SMS_TOKEN;

    const getErrorNotificationLabel = (): string => {
        switch (errorCode) {
            case RegistrationSmsVerifyError.WRONG_SMS_TOKEN:
            case RegistrationSmsVerifyError.EXPIRED_SMS_TOKEN:
            case RegistrationSmsVerifyError.IS_LOCKED_OUT:
                return errorMessage;
        }
        return '';
    };

    const isVerificationErrorCode = (
        errorCode: WithDefaultCpIntegrationErrors<RegistrationError | RegistrationSmsVerifyError>,
    ) => {
        return errorCode in RegistrationSmsVerifyError;
    };

    return (
        <>
            <Layout.Item className={'u-mb u-text-center'}>
                <h1>{t('sms-form.head.title')}</h1>
                <p>{t('sms-form.head.info-identification', { phoneNumberEnding })}</p>
                {errorCode && !isVerificationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <NoConnectionNotification />
                    </ScrollToTopOnNavigation>
                )}
                {errorCode && isVerificationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <Notification
                            testId={'error-sms-identification-form'}
                            status={NotificationStatus.error}
                            text={getErrorNotificationLabel()}
                        />
                    </ScrollToTopOnNavigation>
                )}
            </Layout.Item>
            {displayResendButton && (
                <Layout.Item>
                    <Button full={true} onClick={onResendCode} testId={'resend-code-button'}>
                        {t('sms-form.button-labels.resend')}
                    </Button>
                </Layout.Item>
            )}
            {displayForm && (
                <Layout.Item>
                    <Formik
                        initialValues={{ smsToken: '' }}
                        validationSchema={identificationFormValidationSchema(t)}
                        onSubmit={onSmsCodeConfirmation}
                    >
                        {(formik) => (
                            <Form onSubmit={preventSubmit()}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            label={t('sms-form.input-labels.sms-code')}
                                            testId="sms-token"
                                            name="smsToken"
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Button full={true} onClick={formik.submitForm} testId={'submit-button'}>
                                            {t('sms-form.button-labels.submit')}
                                        </Button>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </Layout.Item>
            )}
            {displayHelpText && (
                <Layout.Item>
                    <p>
                        {t('sms-form.head.help-text-1')}
                        <Button link onClick={onResendCode} testId={'resend-code-link'}>
                            {t('sms-form.head.resend')}
                        </Button>
                        {t('sms-form.head.help-text-2')}
                    </p>
                </Layout.Item>
            )}
        </>
    );
};
