import { defaultPrivateIdentificationError } from '../messages';
import { PrivateIdentificationError } from '../types';
import * as Yup from 'yup';
import { ChangePrivateIdentificationRequest } from '../../apis';
import { idCardValidator } from './IdCardValidator';
import { filesInfoValidator } from './FileInfoValidator';

export const privateIdentificationValidator = (
    errors: PrivateIdentificationError = defaultPrivateIdentificationError,
): Yup.SchemaOf<ChangePrivateIdentificationRequest> =>
    filesInfoValidator(errors.files).shape({
        title: Yup.string().trim().max(20, errors.title.max),
        firstName: Yup.string().trim().required(errors.firstName.required).max(30, errors.firstName.max),
        surname: Yup.string().trim().required(errors.surname.required).max(30, errors.surname.max),
        idCard: idCardValidator(errors.idCard),
    });
