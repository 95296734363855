import { ErrorPage, getNotFoundErrorPageEndpoint } from '@cp-sk/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ErrorPage>({
    contentName: 'notFoundErrorPage',
    contentEndpoint: getNotFoundErrorPageEndpoint,
});

export default reducer;
export const fetchNotFoundErrorPage = fetchContent;
