import React from 'react';
import { Hr } from '@vwfs-bronson/bronson-react';
import { VehicleDetails } from '@cp-sk/common';
import { GeneralSection } from '../general-section';
import { LicensePlateSection } from '../license-plate-section';

type VehicleDetailsUiProps = { vehicleDetails?: VehicleDetails; encryptedContractNumber: string };

export const VehicleDetailsUi: React.FC<VehicleDetailsUiProps> = ({ vehicleDetails, encryptedContractNumber }) => {
    if (!vehicleDetails) {
        return null;
    }

    return (
        <>
            <GeneralSection vehicleDetails={vehicleDetails} />
            <Hr className="u-mb-none" />
            <LicensePlateSection vehicleDetails={vehicleDetails} encryptedContractNumber={encryptedContractNumber} />
        </>
    );
};
