import React from 'react';
import { Redirect } from 'react-router-dom';

import {
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-5/frontend-ui';

import { registrationPagePath, landingPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { getUserRegistryStatusEndpoint, UserRegistrationResult } from '@cp-sk/common';

const commonAuthorizationOptions = {
    onLoading: <Spinner center />,
    onError: <Redirect to={landingPagePath()} />,
};

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const { Authorization: UserRegistrationAuthorization } = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: async () => {
        try {
            const { data: registrationData } = await CpDataApi.get<UserRegistrationResult>(
                getUserRegistryStatusEndpoint(),
            );
            return { isAuthorized: registrationData.isRegistered };
        } catch (e) {
            return { isAuthorized: false };
        }
    },
    onMissingAuthorization: <Redirect to={registrationPagePath()} />,
    ...commonAuthorizationOptions,
});

export const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
export const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', UserRegistrationAuthorization);
