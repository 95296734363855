import { Contract, ContractType, ContractWithLinks } from '@cp-sk/common';

const allowedContractTypes = [ContractType.AUTO_CREDIT, ContractType.CONSUMER_CREDIT, ContractType.BALLON_CREDIT];

export const isTotalEarlySettlementAvailable = (contract: Contract) => {
    return (
        contract.isActive &&
        allowedContractTypes.some((allowedContractType: ContractType) => allowedContractType === contract.contractType)
    );
};

export const isPartialEarlySettlementAvailable = (contract: Contract) => {
    return (
        contract.isActive &&
        allowedContractTypes.some(
            (allowedContractType: ContractType) => allowedContractType === contract.contractType,
        ) &&
        contract.partialEarlySettlementAvailable
    );
};

export const isPaymentOverviewAvilable = (contract: ContractWithLinks) => {
    return contract.isActive && contract.paymentOverviewAvailable;
};
