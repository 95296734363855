import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import legalFooterReducer from 'components/footer/legal-footer/LegalFooterSlice';
import iconFooterReducer from 'components/footer/icon-footer/IconFooterSlice';
import faqReducer from 'components/faq/FaqSlice';
import notFoundErrorPageReducer from 'components/error-pages/not-found/NotFoundErrorPageSlice';
import forbiddenErrorPageReducer from 'components/error-pages/forbidden/ForbiddenErrorPageSlice';
import genericErrorPageReducer from 'components/error-pages/generic/GenericErrorPageSlice';
import unauthorizedErrorPageReducer from 'components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import cookiePolicyReducer from 'components/cookie-policy/CookiePolicySlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import privacyPolicyReducer from 'components/privacy-policy/PrivacyPolicySlice';
import landingPageTeaserReducer from 'components/landing-page-teaser/LandingPageTeaserSlice';
import landingPageMarketingCardsReducer from 'components/landing-page-marketing-cards/LandingPageMarketingCardsSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import myDocumentsReducer from 'components/my-documents/MyDocumentsSlice';
import contractsReducer from 'components/contracts/ContractsSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import dashboardMarketingCardReducer from 'components/dashboard-marketing-card/DashboardMarketingCardSlice';
import bundledProductsReducer from 'components/contracts/bundled-products/BundledProductsSlice';
import amortizationDetailsReducer from 'components/amortization-table/AmortizationTableSlice';
import paymentOverviewReducer from 'components/payment-overview/PaymentOverviewSlice';
import customerTypeReducer from 'components/customer-type/CustomerTypeSlice';
import autoLogoutSuccessReducer from 'components/auto-logout-success/LogoutSuccessSlice';
import contractDetailsReducer from 'components/contracts/contract-details/ContractDetailsSlice';
import paymentDetailsReducer from 'components/payment-details/PaymentDetailsSlice';
import requestMarketingCardReducer from 'components/request/marketing-card/RequestMarketingCardSlice';
import requestReducer from 'components/request/RequestSlice';
import vehicleImageReducer from 'components/contracts/vehicle-details-and-image/VehicleImageSlice';
import vehicleDetailsReducer from 'components/contracts/vehicle-details-and-image/VehicleDetailsSlice';
import changeLogsRedcuer from 'components/change-log-request/ChangeLogSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    customerType: customerTypeReducer,
    myProfile: myProfileReducer,
    myDocuments: myDocumentsReducer,
    contracts: contractsReducer,
    greeting: greetingReducer,
    contractDetails: contractDetailsReducer,
    bundledProducts: bundledProductsReducer,
    amortizationDetails: amortizationDetailsReducer,
    paymentDetails: paymentDetailsReducer,
    request: requestReducer,
    changeLogs: changeLogsRedcuer,
    paymentOverview: paymentOverviewReducer,
    vehicleDetailsAndImage: combineReducers({
        vehicleImages: vehicleImageReducer,
        vehicleDetails: vehicleDetailsReducer,
    }),
    content: combineReducers({
        legalFooter: legalFooterReducer,
        autoLogoutSuccess: autoLogoutSuccessReducer,
        iconFooter: iconFooterReducer,
        faq: faqReducer,
        genericError: genericErrorPageReducer,
        notFoundErrorPage: notFoundErrorPageReducer,
        forbiddenErrorPage: forbiddenErrorPageReducer,
        unauthorizedErrorPage: unauthorizedErrorPageReducer,
        cookiePolicy: cookiePolicyReducer,
        legalNotice: legalNoticeReducer,
        privacyPolicy: privacyPolicyReducer,
        landingPageTeaser: landingPageTeaserReducer,
        landingPageMarketingCards: landingPageMarketingCardsReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
        requestMarketingCard: requestMarketingCardReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
