import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import {
    BundledProductsWithAllValues,
    BundledProductsWithMissingValues,
    BundledProductsWithNoActiveProducts,
} from './ExampleData';
import { ContractsWithAllValues as Contracts } from 'components/contracts/ExampleData';
import { ContractsMock } from './contractsMock';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: BundledProductsWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: BundledProductsWithMissingValues,
    },
    'With no active products': {
        status: 200,
        responseBody: BundledProductsWithNoActiveProducts,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const BundledProductsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Bundled Products Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: Contracts[0]._links?.bundledProducts || '',
};

export const BundledProductsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, BundledProductsMock]);

    return <div>{storyFn()}</div>;
};
