export type PersonFormProps = {
    privateCustomerNumber: string;
    firstName: string;
    surname: string;
    dateOfBirth: string;
    confirmTermsAndConditions: boolean;
    confirmPrivacyPolicy: boolean;
};

export type BusinessFormProps = {
    businessCustomerNumber: string;
    companyName: string;
    companyIdentificationNumber: string;
    confirmTermsAndConditions: boolean;
    confirmPrivacyPolicy: boolean;
};

export enum CustomerType {
    PRIVATE = 'PRIVATE',
    BUSINESS = 'BUSINESS',
}
