export type Brand = 'vwfs';
export const currentBrand = (process.env.REACT_APP_WEBSITE_BRAND || 'vwfs') as Brand;
export const integrationApiBaseUrl = process.env.REACT_APP_INTEGRATION_API_BASE_URL;
export const integrationApiBaseUrlLocal = process.env.REACT_APP_API_BASE_URL_LOCAL;
export const apiGatewayConsumerName = 'vwfs-du-cpsk';
export const maxFileSize = 4 * 1024 * 1024;
export const currentBronson = {
    baseUrl: process.env.REACT_APP_WEBSITE_BRONSON_BASE_URL,
    theme: process.env.REACT_APP_WEBSITE_BRONSON_THEME,
};
export const chatbotApiKey = process.env.REACT_APP_CHATBOT_API_KEY;
export const mercuryCssUrl = process.env.REACT_APP_MERCURY_CSS_URL || '';
export const mercuryBaseUrl = process.env.REACT_APP_MERCURY_BASE_URL;
