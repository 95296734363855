import { getMyDocumentsEndpoint, MyDocument } from '@cp-sk/common';
import { getSimpleDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<Array<MyDocument>, DefaultBusinessMarketApiErrorCode>({
    dataName: 'myDocuments',
    fetchCallback() {
        return CpDataApi.get(getMyDocumentsEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchMyDocuments = fetchData;
