import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { getMyDocumentsEndpoint } from '@cp-sk/common';
import { DocumentsEmptyList, DocumentsWithAllValues, DocumentsWithMissigValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: DocumentsWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: DocumentsWithMissigValues,
    },
    'With no documents': {
        status: 200,
        responseBody: DocumentsEmptyList,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyDocumentsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptions,
    url: getMyDocumentsEndpoint(),
};

export const MyDocumentsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyDocumentsMock]);

    return <div>{storyFn()}</div>;
};
