import { PrivateIdentificationError } from '../types';
import { defaultIdCardError } from './IdCardMessages';
import { defaultFilesInfoError } from './FileInfoMessages';

export const defaultPrivateIdentificationError: PrivateIdentificationError = {
    files: defaultFilesInfoError,
    firstName: { max: 'First name is to long.', required: 'First name is required.' },
    idCard: defaultIdCardError,
    surname: { max: 'Surname is to long.', required: 'Surname is required.' },
    title: { max: 'Title is to long.' },
};
