import React, { useState } from 'react';
import { DocumentNode, formatAsDate, MyDocument, formatAsCurrency } from '@cp-sk/common';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import {
    Notification,
    NotificationStatus,
    Postbox,
    PostboxDocument,
    PostboxFilterProps,
    useAnalyticsActionTracker,
} from '@cp-shared-5/frontend-ui';
import {
    getContractFilterItems,
    getDocumentTypeFilterItems,
    getTableLocalization,
    getTimeFrameFilterItems,
    sortDocuments,
} from '../utils';
import { CpDataApi } from '../../../cp-xhr';
import { Modal } from '@vwfs-bronson/bronson-react';
import moment from 'moment';

export const MyDocumentsUi: React.FC<{ myDocumentsData?: MyDocument[]; contractNumber?: string; docType?: string }> = ({
    myDocumentsData,
    contractNumber,
    docType,
}) => {
    const { t } = useTranslation('my-documents');
    const [documents] = useState<MyDocument[]>(sortDocuments(myDocumentsData || []));
    const [downloadError, setDownloadError] = useState(false);
    const { onAction } = useAnalyticsActionTracker('onPostboxDownload');

    if (!myDocumentsData) {
        return null;
    }

    const getNoDucumentsNotification = (): React.ReactNode => {
        return (
            <Notification
                status={NotificationStatus.info}
                headline={t('no-documents-from-api-error.headline')}
                testId={'no-documents-from-api-error'}
            >
                {t('no-documents-from-api-error.description')}
            </Notification>
        );
    };

    const getContractFilterItem = (document: MyDocument): React.ReactNode => {
        return (
            <>
                <div className="u-block">
                    <span>
                        <b>
                            {document.productName} ({document.contractNumber})
                        </b>
                    </span>
                    <br />
                    {(document.brandModelType || document.licensePlate) && (
                        <span>
                            {document.brandModelType} {document.licensePlate ? `(${document.licensePlate})` : ''}
                        </span>
                    )}
                </div>
            </>
        );
    };

    const downloadPdfFile = ({ _downloadLink, contractNumber, documentType, uploadDate }: MyDocument): void => {
        if (!_downloadLink) {
            setDownloadError(true);
            return;
        }
        const evidenceDate = formatAsDate(uploadDate);
        CpDataApi.get(_downloadLink)
            .then((response) => {
                const fileName = `${documentType}_${contractNumber}_${evidenceDate}.pdf`;
                const { data }: DocumentNode = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(data, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                onAction(documentType);
            })
            .catch(() => {
                setDownloadError(true);
            });
    };

    const filters: PostboxFilterProps = {
        documentTypeFilter: {
            label: t('filters.document-type.label'),
            filterItems: getDocumentTypeFilterItems(documents),
            allLabel: t('filters.document-type.all-label'),
        },
        contractIdentifierFilter: {
            label: t('filters.contract-identifier.label'),
            filterItems: getContractFilterItems(documents, getContractFilterItem),
            allLabel: t('filters.contract-identifier.all-label'),
            withGeneralOption: true,
            generalLabel: t('filters.contract-identifier.general-label'),
        },
        timeFrameFilter: {
            label: t('filters.time-frame.label'),
            filterItems: getTimeFrameFilterItems(t),
            allLabel: t('filters.time-frame.all-label'),
        },
    };

    const mapDocuments = (documents: MyDocument[]): PostboxDocument[] => {
        return documents.map((myDocument, index) => {
            const additionalSubjectInformation = [
                myDocument.contractNumber,
                myDocument.invoiceAmount && formatAsCurrency(myDocument.invoiceAmount),
                myDocument.invoiceDueDate && formatAsDate(myDocument.invoiceDueDate),
            ]
                .filter((v) => v !== undefined)
                .join(', ');

            return {
                documentId: index,
                documentType: myDocument.categoryType || '',
                contractIdentifier: myDocument.contractNumber,
                date: moment(myDocument.uploadDate),
                subject: `${myDocument.documentType} (${additionalSubjectInformation})`,
                onClick: (): void => downloadPdfFile(myDocument),
            };
        });
    };

    const tableProps = {
        documents: mapDocuments(documents),
        tableHeaderColumnLabels: { date: t('date'), subject: t('subject') },
        localization: getTableLocalization(t),
        noDocumentsSelectedErrorText: t('no-documents-selected-error'),
    };

    return (
        <>
            <h3>{t('subtitle')}</h3>
            {!tableProps.documents.length && getNoDucumentsNotification()}
            {!!tableProps.documents.length && (
                <Postbox
                    filters={filters}
                    table={tableProps}
                    resetButtonText={t('reset')}
                    withPaging
                    itemsPerPage={10}
                    defaultContractIdentifier={contractNumber}
                    defaultDocumentType={docType}
                />
            )}

            <Modal
                shown={Boolean(downloadError)}
                status="error"
                title={t('document-download-error.headline')}
                onClose={(): void => setDownloadError(false)}
                testId={'document-download-error'}
            >
                {t('document-download-error.text')}
            </Modal>
        </>
    );
};
