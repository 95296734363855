export type FormValues = {
    title: string;
    firstName: string;
    surname: string;
    idCard: {
        type: string;
        number: string;
        issueDate: string;
        issueCity: string;
    };
    frontOfDocument: File[];
    backOfDocument: File[];
};

export const getInitialValues = (
    title: string,
    firstName: string,
    surname: string,
    idCardNumber: string,
): FormValues => ({
    title,
    firstName,
    surname,
    idCard: {
        number: idCardNumber,
        type: '',
        issueDate: '',
        issueCity: '',
    },
    frontOfDocument: [],
    backOfDocument: [],
});
