import React, { useState } from 'react';
import { Layout, ContentSection } from '@vwfs-bronson/bronson-react';
import { ChangeRequestLogEntryWithId, ChangeRequestLogEntryDetails } from '@cp-sk/common';
import { useTranslation } from 'react-i18next';
import { Paging } from '../paging/Paging';
import { EmptyChangeLogs } from '../empty-change-logs/EmptyChangeLogs';
import { ChangeLogModal } from '../modal/ChangeLogModal';
import { ChangeLogTable } from '../table/ChangeLogTable';
import { ChangeLogFilters } from '../filters/ChangeLogFilters';

type Props = { changeRequestLogEntries: ChangeRequestLogEntryWithId[] };

export const ChangeLogRequestUi: React.FC<Props> = ({ changeRequestLogEntries }) => {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [contractDetails, setContractDetails] = useState<ChangeRequestLogEntryDetails>({
        contractNumber: '',
        dateOfApplication: '',
        effectiveDate: '',
        licensePlate: '',
    });

    const [filteredEntries, setFilteredEntries] = useState<ChangeRequestLogEntryWithId[]>(changeRequestLogEntries);

    const [page, setPage] = useState<number>(1);
    const itemsPerPage = 20;

    const { t } = useTranslation('change-log-request');

    const getDocumentsForPage = (documents: ChangeRequestLogEntryWithId[]): ChangeRequestLogEntryWithId[] => {
        const from = (page - 1) * itemsPerPage;
        return documents.slice(from, from + itemsPerPage);
    };

    function resetPage(): void {
        setPage(1);
    }

    const handleShowDetails = (clickedContractDetails: ChangeRequestLogEntryDetails): void => {
        setContractDetails({
            contractNumber: clickedContractDetails.contractNumber,
            dateOfApplication: clickedContractDetails.dateOfApplication,
            effectiveDate: clickedContractDetails.effectiveDate,
            licensePlate: clickedContractDetails.licensePlate,
        });
        setShowDetails(true);
    };

    return (
        <>
            {changeRequestLogEntries.length > 0 ? (
                <>
                    <ContentSection pageWrapSize="medium">
                        <Layout className={'u-text-left'}>
                            <Layout.Item>
                                <h1>{t('heading')}</h1>
                            </Layout.Item>
                        </Layout>
                        <ChangeLogFilters
                            setFilteredEntries={setFilteredEntries}
                            changeRequestLogEntries={changeRequestLogEntries}
                            resetPage={resetPage}
                            page={page}
                        />
                        <ChangeLogTable
                            filteredEntries={getDocumentsForPage(filteredEntries)}
                            handleShowDetails={handleShowDetails}
                        />
                        <Paging
                            items={filteredEntries.length}
                            itemsPerPage={itemsPerPage}
                            activePage={page}
                            onPageClick={setPage}
                        />
                    </ContentSection>
                    <ChangeLogModal
                        showDetails={showDetails}
                        setShowDetails={setShowDetails}
                        details={contractDetails}
                    />
                </>
            ) : (
                <EmptyChangeLogs />
            )}
        </>
    );
};
