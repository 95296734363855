import { BaseAddressValidationError, FiscalAddressValidationError, PostalAddressValidationError } from '../types';
import { defaultFilesInfoError } from './FileInfoMessages';
import { defaultIdCardError } from './IdCardMessages';

export const defaultAddressValidationMessages: BaseAddressValidationError = {
    city: {
        oneOf: 'City must be a one of valid cities list.',
        required: 'City is required.',
    },
    country: {
        required: 'Country is required.',
    },
    postalCode: {
        match: 'Postal code must be exactly 5 digits long.',
        required: 'Postal code is required.',
    },
    street: {
        max: 'Street name is too long.',
        required: 'Street is required.',
    },
};

export const defaultFiscalAddressValidationMessages: FiscalAddressValidationError = {
    ...defaultAddressValidationMessages,
    secondLine: {
        max: 'Address second line is to long.',
    },
};

export const defaultPostalAddressValidationMessages: PostalAddressValidationError = {
    ...defaultAddressValidationMessages,
    personIdCardData: defaultIdCardError,
    files: defaultFilesInfoError,
};
