import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const businessIdentificationFormValidationSchema = (t: TFunction) => {
    const translationPrefis = 'registration-form.business.validation-errors';
    return Yup.object().shape({
        businessCustomerNumber: Yup.string()
            .trim()
            .required(t(`${translationPrefis}.customer-number-missing`)),
        companyIdentificationNumber: Yup.string()
            .required(t(`${translationPrefis}.company-id-missing`))
            .matches(/^[0-9]+$/, t(`${translationPrefis}.company-id-invalid-format`)),
        companyName: Yup.string()
            .trim()
            .required(t(`${translationPrefis}.company-name`)),
        confirmTermsAndConditions: Yup.bool().oneOf([true], t(`${translationPrefis}.terms-and-conditions`)),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t(`${translationPrefis}.privacy-policy`)),
    });
};
