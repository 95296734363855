import { WithFileInfos } from '../file-info';
import { IdCard } from '../id-card-type';

export type Addresses = {
    postalAddress?: PostalAddress;
    fiscalAddress?: FiscalAddress;
};

export type FiscalAddress = PostalAddress & {
    secondLine?: string;
};

export type PostalAddress = {
    street?: string;
    postalCode?: string;
    city?: string;
    country?: string;
};

export enum AddressType {
    POSTAL = 'POSTAL',
    FISCAL = 'FISCAL',
}

export type UpdateFiscalAddress = {
    address: FiscalAddress;
};

export type UpdatePostalAddress = WithFileInfos & {
    address: PostalAddress;
    personIdCardData?: IdCard;
};

export type UpdateAddressErrors = 'WRONG_ADDRESS_TYPE';
