import React, { useState } from 'react';
import { ContractType, FinancialDetails } from '@cp-sk/common';
import { useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import { changePaymentDayPath } from 'components/navigation/paths';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { EditView } from './edit-view/EditView';
import { NonEditView } from './non-edit-view/NonEditView';
import { EditStatus } from '../utils';

type FinancingSectionProps = {
    contractType: ContractType;
    details: FinancialDetails;
    paymentDateAvailable?: boolean;
    isActive?: boolean;
    encryptedContractNumber: string;
};

export const FinancingSection: React.FC<FinancingSectionProps> = ({
    contractType,
    details,
    encryptedContractNumber,
    paymentDateAvailable,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [editStatus, setEditStatus] = useState(EditStatus.NOT_PERFORMED);
    const { onAction } = useAnalyticsActionTracker('financialDetailsEditDueDateSection');

    const startEditing = (): void => {
        onAction();
        history.push(changePaymentDayPath(encryptedContractNumber));
        setEditStatus(EditStatus.NOT_PERFORMED);
    };

    const finishEditing = (editStatus: EditStatus): void => {
        setEditStatus(editStatus);
        history.push(path);
    };

    return (
        <>
            <Hr className={'u-mb-none'} />
            <Switch>
                <Route exact path={changePaymentDayPath(encryptedContractNumber)}>
                    <EditView
                        details={details}
                        encryptedContractNumber={encryptedContractNumber}
                        finishEditing={finishEditing}
                    ></EditView>
                </Route>
                <Route path={path}>
                    <NonEditView
                        details={details}
                        paymentDateAvailable={paymentDateAvailable}
                        startEditing={startEditing}
                        editStatus={editStatus}
                        contractType={contractType}
                    ></NonEditView>
                </Route>
            </Switch>
        </>
    );
};
