import React from 'react';
import { JointEarlySettlementUi } from './ui/JointEarlySettlementUi';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useContract } from '../useContract';
import { Skeleton } from '@cp-shared-5/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';

export type EarlySettlementProps = {
    encryptedContractNumber: string;
};

const JointEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(JointEarlySettlementUi);

export const JointEarlySettlement: React.FC<EarlySettlementProps> = ({ encryptedContractNumber }) => {
    const {
        data: contract,
        isLoading,
        loadingError,
    } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });

    return (
        <JointEarlySettlementWithHandlers
            contract={contract}
            encryptedContractNumber={encryptedContractNumber}
            isLoading={isLoading}
            hasError={!!loadingError}
            loadingPlaceholder={
                <>
                    <Layout.Item default={'1/1'} s={'1/1'}>
                        <Skeleton height={50} />
                    </Layout.Item>
                    <Layout.Item default={'1/2'} s={'1/1'}>
                        <Skeleton height={120} />
                    </Layout.Item>
                    <Layout.Item default={'1/2'} s={'1/1'}>
                        <Skeleton height={120} />
                    </Layout.Item>
                    <Layout.Item className="u-text-center" default={'1/1'} s={'1/1'}>
                        <Skeleton height={40} width={120} />
                    </Layout.Item>
                </>
            }
        />
    );
};
