import React from 'react';
import { Button } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';

export type AlertNotificationProps = {
    headline?: string;
    text?: string;
    buttonLabel?: string;
    onClick?: Function;
    testId?: string;
};

export const AlertNotification: React.FC<AlertNotificationProps> = ({
    headline,
    text,
    buttonLabel,
    onClick,
    testId,
}) => (
    <Notification
        status={NotificationStatus.warning}
        headline={headline}
        text={text}
        testId={testId}
        className={'u-mb-small'}
    >
        {buttonLabel && (
            <Button secondary className="u-bg-white u-mt" testId={'button'} onClick={onClick}>
                {buttonLabel}
            </Button>
        )}
    </Notification>
);
