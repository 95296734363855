import { TFunction } from 'i18next';
import { DefinitionListItem } from '@cp-shared-5/frontend-ui';
import {
    AutoCreditFinancialDetails,
    BallonCreditFinancialDetails,
    ConsumerCreditFinancialDetails,
    ContractType,
    FinancialDetails,
    FinancialLeasingFinancialDetails,
    OperativeLeasingFinancialDetails,
} from '@cp-sk/common';
import { FormatterFunction, TranslationFormat } from 'localization/useTranslationWithFormatting';

export enum EditStatus {
    SUCCESS = 'SUCCESS',
    NOT_PERFORMED = 'NOT_PERFORMED',
    NO_CHANGE = 'NO_CHANGE',
    ERROR = 'ERROR_SYSTEM',
}

export const getGeneralSectionListItems = (
    t: TFunction,
    f: FormatterFunction,
    financialDetails: FinancialDetails,
): DefinitionListItem[] => {
    const { contractStartDate, contractEndDate, firstPaymentDate, lastPaymentDate, term } = financialDetails;
    const startDateItem: DefinitionListItem = {
        label: t('general-section.start-date'),
        value: contractStartDate ? f(contractStartDate, TranslationFormat.DATE) : '-',
        testId: 'general-section-start-date',
    };
    const endDateItem: DefinitionListItem = {
        label: t('general-section.end-date'),
        value: contractEndDate ? f(contractEndDate, TranslationFormat.DATE) : '-',
        testId: 'general-section-end-date',
    };
    const firstPaymentDateItem: DefinitionListItem = {
        label: t('general-section.first-date'),
        value: firstPaymentDate ? f(firstPaymentDate, TranslationFormat.DATE) : '-',
        testId: 'general-section-first-date',
    };
    const lastPaymentDateItem: DefinitionListItem = {
        label: t('general-section.last-date'),
        value: lastPaymentDate ? f(lastPaymentDate, TranslationFormat.DATE) : '-',
        testId: 'general-section-last-date',
    };
    const termItem: DefinitionListItem = {
        label: t('general-section.term'),
        value: term ? `${term}` : '-',
        testId: 'general-section-term',
    };

    return [startDateItem, endDateItem, firstPaymentDateItem, lastPaymentDateItem, termItem];
};

const getListItem = (
    fieldName: string,
    value: string,
    t: TFunction,
    f: FormatterFunction,
): DefinitionListItem | null => {
    const items: {
        listItem: DefinitionListItem;
        key: string;
        format?: 'date' | 'percentage' | 'currency' | 'number' | 'ordinal-number';
    }[] = [
        {
            key: 'financeValueOfContract',
            format: 'currency',
            listItem: {
                label: t('financing-section.finance-value.label'),
                tooltip: t('financing-section.finance-value.tooltip'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'downPayment',
            format: 'percentage',
            listItem: {
                label: t('financing-section.down-payment.label'),
                tooltip: t('financing-section.down-payment.tooltip'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'balloonAmount',
            format: 'currency',
            listItem: {
                label: t('financing-section.balloon-amount.label'),
                tooltip: t('financing-section.balloon-amount.tooltip'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'monthlyDueDay',
            format: 'ordinal-number',
            listItem: {
                label: t('financing-section.monthly-due-day.label'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'nominalInterestRate',
            format: 'percentage',
            listItem: {
                label: t('financing-section.nominal-interest-rate.label'),
                tooltip: t('financing-section.nominal-interest-rate.tooltip'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'pendingCapital',
            format: 'currency',
            listItem: {
                label: t('financing-section.pending-capital.label'),
                tooltip: t('financing-section.pending-capital.tooltip'),
                value: '',
                testId: 'financing-section-start-date',
            },
        },
        {
            key: 'contractedMileAge',
            format: 'number',
            listItem: {
                label: t('mileage-section.contracted-mileage.label'),
                tooltip: t('mileage-section.contracted-mileage.tooltip'),
                value: '',
                testId: 'mileage-section-contracted-mileage',
            },
        },
        {
            key: 'expectedMileAgeToDate',
            format: 'number',
            listItem: {
                label: t('mileage-section.expected-mileage-to-date.label'),
                tooltip: t('mileage-section.expected-mileage-to-date.tooltip'),
                value: '',
                testId: 'mileage-section-expected-mileage-to-date',
            },
        },
    ];
    const item = items.find(({ key }) => key === fieldName);
    let formattedValue;
    switch (item?.format) {
        case 'date':
            formattedValue = value ? f(value, TranslationFormat.DATE) : '-';
            break;
        case 'percentage':
            formattedValue = Number(value) >= 0 ? f(Number(value), TranslationFormat.PERCENTAGE) : '-';
            break;
        case 'currency':
            formattedValue = Number(value) ? f(Number(value), TranslationFormat.CURRENCY) : '-';
            break;
        case 'number':
            formattedValue = Number(value) ? f(Number(value), TranslationFormat.NUMBER) : '-';
            break;
        case 'ordinal-number':
            formattedValue = value ? f(value, TranslationFormat.ORDINAL_NUMBER) : '-';
            break;
        default:
            formattedValue = value || '-';
    }
    return item ? { ...item.listItem, value: formattedValue } : null;
};

const getListItems = (fields: string[], values: string[], t: TFunction, f: FormatterFunction): DefinitionListItem[] => {
    return fields.reduce((acc: DefinitionListItem[], field: string, index: number): DefinitionListItem[] => {
        const item = getListItem(field, values[index], t, f);
        if (item) acc.push(item);
        return acc;
    }, []);
};

export const getFinancingSectionListItems = (
    t: TFunction,
    f: FormatterFunction,
    contractType: ContractType,
    financialDetails: FinancialDetails,
): DefinitionListItem[] => {
    switch (contractType) {
        case ContractType.CONSUMER_CREDIT:
        case ContractType.AUTO_CREDIT:
        case ContractType.FINANCIAL_LEASING: {
            type validFields =
                | 'financeValueOfContract'
                | 'downPayment'
                | 'monthlyDueDay'
                | 'nominalInterestRate'
                | 'pendingCapital';
            const details = financialDetails as
                | ConsumerCreditFinancialDetails
                | AutoCreditFinancialDetails
                | FinancialLeasingFinancialDetails;
            const fields: validFields[] = financialDetails.isActive
                ? ['financeValueOfContract', 'downPayment', 'monthlyDueDay', 'nominalInterestRate', 'pendingCapital']
                : ['financeValueOfContract', 'downPayment', 'nominalInterestRate'];
            const values: string[] = fields.map((field) => (details[field] ? `${details[field]}` : ''));
            return getListItems(fields, values, t, f);
        }
        case ContractType.BALLON_CREDIT: {
            type validFields =
                | 'financeValueOfContract'
                | 'downPayment'
                | 'balloonAmount'
                | 'monthlyDueDay'
                | 'nominalInterestRate'
                | 'pendingCapital';
            const details = financialDetails as BallonCreditFinancialDetails;
            const fields: validFields[] = financialDetails.isActive
                ? [
                      'financeValueOfContract',
                      'downPayment',
                      'balloonAmount',
                      'monthlyDueDay',
                      'nominalInterestRate',
                      'pendingCapital',
                  ]
                : ['financeValueOfContract', 'downPayment', 'balloonAmount', 'nominalInterestRate'];
            const values: string[] = fields.map((field) => (details[field] ? `${details[field]}` : ''));
            return getListItems(fields, values, t, f);
        }
        case ContractType.OPERATIVE_LEASING: {
            type validFields = 'downPayment' | 'monthlyDueDay' | 'nominalInterestRate';
            const details = financialDetails as OperativeLeasingFinancialDetails;
            const fields: validFields[] = financialDetails.isActive
                ? ['downPayment', 'monthlyDueDay', 'nominalInterestRate']
                : ['downPayment', 'nominalInterestRate'];
            const values: string[] = fields.map((field) => (details[field] ? `${details[field]}` : ''));
            return getListItems(fields, values, t, f);
        }
    }
    return [];
};
export const getMileageSectionListItems = (
    t: TFunction,
    f: FormatterFunction,
    contractType: ContractType,
    financialDetails: FinancialDetails,
): DefinitionListItem[] => {
    switch (contractType) {
        case ContractType.OPERATIVE_LEASING:
        case ContractType.BALLON_CREDIT: {
            type validFields = 'contractedMileAge' | 'expectedMileAgeToDate';
            const details = financialDetails as BallonCreditFinancialDetails | OperativeLeasingFinancialDetails;
            const fields: validFields[] = financialDetails.isActive
                ? ['contractedMileAge', 'expectedMileAgeToDate']
                : ['contractedMileAge'];
            const values: string[] = fields.map((field) => {
                return details[field] ? `${details[field]}` : '';
            });
            return getListItems(fields, values, t, f);
        }
    }
    return [];
};
