import React from 'react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DescriptionListAccordion, SectionHeading } from '@vwfs-bronson/bronson-react';
import { ContractDurationCalculationResponse, formatAsDate } from '@cp-sk/common';
import { isEmpty } from 'lodash';

type NewContractDetailsProps = {
    calculatedTerms?: ContractDurationCalculationResponse;
};

export const OverviewTable: React.FC<NewContractDetailsProps> = ({ calculatedTerms }) => {
    const { t, f } = useTranslationWithFormatting('change-contract-duration');

    const resetTextTransformStyle = {
        textTransform: 'none' as const,
    };

    return (
        <div>
            <SectionHeading level="3" subtitle={t('overview-table.description')}>
                {t('overview-table.title')}
            </SectionHeading>
            <DescriptionListAccordion
                headerSplit
                totalLabel={t('overview-table.total-settlement-amount.text')}
                totalValue={f(calculatedTerms?.totalSettlementAmount, TranslationFormat.CURRENCY)}
            >
                {(isEmpty(calculatedTerms) || (calculatedTerms && calculatedTerms?.administrationFee > 0)) && (
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        icon="semantic-info"
                        tooltip={
                            <div
                                style={resetTextTransformStyle}
                                dangerouslySetInnerHTML={{
                                    __html: t('overview-table.administration-fee.tooltip'),
                                }}
                            />
                        }
                        title={
                            <span style={resetTextTransformStyle}>{t('overview-table.administration-fee.text')}</span>
                        }
                        titleSplit={f(calculatedTerms?.administrationFee, TranslationFormat.CURRENCY)}
                    />
                )}

                <DescriptionListAccordion.Item
                    nonCollapsable
                    title={<span style={resetTextTransformStyle}>{t('overview-table.new-monthly-rate.text')}</span>}
                    titleSplit={f(calculatedTerms?.newMonthlyRate, TranslationFormat.CURRENCY)}
                />

                {(isEmpty(calculatedTerms) ||
                    (calculatedTerms && typeof calculatedTerms?.totalDueAmount === 'number')) && (
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        icon="semantic-info"
                        title={<span style={resetTextTransformStyle}>{t('overview-table.total-due-amount.text')}</span>}
                        tooltip={
                            <span style={resetTextTransformStyle}>{t('overview-table.total-due-amount.tooltip')}</span>
                        }
                        titleSplit={f(calculatedTerms?.totalDueAmount, TranslationFormat.CURRENCY)}
                    />
                )}

                <DescriptionListAccordion.Item
                    nonCollapsable
                    title={<span style={resetTextTransformStyle}>{t('overview-table.payment-date.text')}</span>}
                    titleSplit={formatAsDate(calculatedTerms?.paymentDate)}
                />
            </DescriptionListAccordion>
        </div>
    );
};
