import React, { useEffect, useState } from 'react';
import {
    ContractDurationChangeCombination,
    ContractDurationCalculationResponse,
    getContractDurationChangeEndpoint,
    getCalculateContractDurationChangeEndpoint,
    ContractDurationChangeRequest,
    getAcceptContractDurationChangeEndpoint,
    ContractDurationChangeOptions,
    ChangeContractDurationValidatorErrors,
    changeContractDuarationValidationSchema,
} from '@cp-sk/common';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ButtonContainer,
    Card,
    Layout,
    Modal,
    LicensePlate as LicensePlateShared,
} from '@vwfs-bronson/bronson-react';
import { ContractHeader, Spinner, preventSubmit, Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';

import { useContract } from '../useContract';
import { useContractDetails } from '../contract-details';
import { ComparisonDurationSection } from './comparison-section';
import { OverviewTable } from './overview-table';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import { CurrentTerms } from './ChangeContractDurationTypes';
import { isEmpty } from 'lodash';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { useFormik } from 'formik';

export type ChangeContractDurationProps = {
    encryptedContractNumber: string;
};

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    NONE = 'NONE',
}

export const ChangeContractDuration: React.FC<ChangeContractDurationProps> = ({ encryptedContractNumber }) => {
    const { t } = useTranslation('change-contract-duration');
    const history = useHistory();
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const [chosenOption, setChosenOption] = useState<number>();
    const [chosenDate, setChosenDate] = useState<string>();
    const [isChangeContractDurationAvailableLoading, setIsChangeContractDurationLoading] = useState<boolean>(true);
    const [changeContractDurationLoadingError, setChangeContractDurationLoadingError] = useState<boolean>(false);
    const [isCurrentTermsLoading, setIsCurrentTermsLoading] = useState<boolean>(false);
    const [newTermsCalculated, setNewTermsCalculated] = useState<ContractDurationCalculationResponse>();
    const [currentTerms, setCurrentTerms] = useState<CurrentTerms>();
    const [changeContractDurationAvailable, setChangeContractDurationAvailable] =
        useState<ContractDurationChangeCombination[]>();
    const {
        data: contract,
        isLoading,
        loadingError,
    } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });

    const {
        data: contractDetails,
        isLoading: isContractDetailsLoading,
        loadingError: loadingErrorContractDetails,
    } = useContractDetails(contract?.contractNumber || '');

    const validationErrors: ChangeContractDurationValidatorErrors = {
        newContractDuration: {
            sameAsPrevious: t('validation.sameContractDuration'),
        },
    };

    const formik = useFormik({
        initialValues: { newContractDuration: undefined },
        onSubmit: () => {
            preventSubmit();
        },
        validationSchema:
            contractDetails?.financial.term &&
            changeContractDuarationValidationSchema(contractDetails?.financial.term, validationErrors),
    });

    useEffect(() => {
        setNewTermsCalculated(undefined);
        if (chosenOption && chosenOption !== contractDetails?.financial.term && isEmpty(formik?.errors)) {
            setIsChangeContractDurationLoading(true);
            const date = changeContractDurationAvailable?.map((item) => {
                if (item?.newContractDurationField?.map((duration) => duration === chosenOption))
                    return item?.settlementDate;
            });

            if (typeof chosenOption !== 'undefined' && date?.[0] && contract?.contractNumber) {
                setChosenDate(date?.[0]);
                const requestBody: ContractDurationChangeRequest = {
                    newContractDurationField: chosenOption,
                    settlementDate: formatCpDate(date?.[0]).toCpDate(),
                    contractNumber: contract?.contractNumber,
                    currentContractDuration: contractDetails?.financial?.term,
                };
                CpDataApi.post(getCalculateContractDurationChangeEndpoint(encryptedContractNumber), requestBody)
                    .then((res) => {
                        setNewTermsCalculated(res.data);
                    })
                    .catch((_) => {
                        setChangeContractDurationLoadingError(true);
                    })
                    .finally(() => {
                        setIsChangeContractDurationLoading(false);
                    });
            }
        }
        // eslint-disable-next-line
    }, [chosenOption, encryptedContractNumber, formik.errors, changeContractDurationAvailable]);
    useEffect(() => {
        if (contractDetails) {
            setIsChangeContractDurationLoading(true);

            CpDataApi.get<ContractDurationChangeOptions>(getContractDurationChangeEndpoint(encryptedContractNumber))
                .then((res) => {
                    setChangeContractDurationAvailable(res.data?.allowedCombinations);
                    setChangeContractDurationLoadingError(false);
                    setIsCurrentTermsLoading(true);
                    CpDataApi.post<ContractDurationCalculationResponse>(
                        getCalculateContractDurationChangeEndpoint(encryptedContractNumber),
                        {
                            settlementDate: res.data?.allowedCombinations?.[0].settlementDate,
                            currentContractDuration: contractDetails?.financial?.term,
                            contractNumber: contract?.contractNumber,
                            newContractDurationField: res.data?.allowedCombinations?.[0].newContractDurationField?.[0],
                        },
                    )
                        .then((res) => {
                            setCurrentTerms({
                                monthlyRate: res?.data?.monthlyRate,
                            });
                            setChangeContractDurationLoadingError(false);
                        })
                        .catch((_) => {
                            setChangeContractDurationLoadingError(true);
                        })
                        .finally(() => setIsCurrentTermsLoading(false));
                })
                .catch((_) => {
                    setChangeContractDurationLoadingError(true);
                })
                .finally(() => {
                    setIsChangeContractDurationLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [contractDetails]);

    const acceptChangeContractDuration = () => {
        setIsChangeContractDurationLoading(true);
        if (chosenOption && chosenDate && contract?.contractNumber) {
            const requestBody: ContractDurationChangeRequest = {
                newContractDurationField: chosenOption,
                settlementDate: chosenDate,
                contractNumber: contract?.contractNumber,
                currentContractDuration: contractDetails?.financial?.term,
            };
            CpDataApi.post(getAcceptContractDurationChangeEndpoint(encryptedContractNumber), requestBody)
                .then(() => {
                    setMessageType(MessageType.SUCCESS);
                })
                .catch((_) => {
                    setMessageType(MessageType.ERROR);
                })
                .finally(() => {
                    setIsChangeContractDurationLoading(false);
                });
        }
    };

    const LicensePlate = contract?.licensePlate ? (
        <LicensePlateShared
            countryCode={t('country-code')}
            size={'small'}
            registrationNumber={contract?.licensePlate}
            horizontalStripEu={true}
            euStars={true}
        />
    ) : null;

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleSuccessModalConfirm = (): void => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    return (
        <Layout>
            {(isLoading ||
                isContractDetailsLoading ||
                isCurrentTermsLoading ||
                isChangeContractDurationAvailableLoading) &&
                !(loadingError || loadingErrorContractDetails) && <Spinner fullPage />}
            {contract && contractDetails && (
                <>
                    <Layout.Item default="1/1">
                        <Card element="article">
                            <ContractHeader
                                carInformation={contract.brandModelType!}
                                contractCategory={contract.productName}
                                contractNumber={contract.contractNumber!}
                                contractIdentifier={LicensePlate}
                            />
                        </Card>
                    </Layout.Item>
                    <Layout.Item className="u-mt" default="1/1">
                        <ComparisonDurationSection
                            contractDetails={contractDetails}
                            allowedCombinations={changeContractDurationAvailable}
                            calculatedTerms={newTermsCalculated}
                            setChosenOption={setChosenOption}
                            chosenOption={chosenOption}
                            currentTerms={currentTerms}
                            setFieldValue={formik.setFieldValue}
                            validationErrors={formik.errors?.newContractDuration}
                        />
                    </Layout.Item>

                    {changeContractDurationLoadingError && (
                        <Layout.Item className="u-mt" default="1/1" s="1/1">
                            <NoConnectionNotification testId={'no-connection'} />
                        </Layout.Item>
                    )}

                    <Layout.Item className="u-mt" default="1/1">
                        <OverviewTable calculatedTerms={newTermsCalculated} />
                    </Layout.Item>
                </>
            )}
            <Layout.Item className="u-mt" default="1/1" s="1/1">
                {(loadingError || loadingErrorContractDetails) && (
                    <div className="u-mb">
                        <NoConnectionNotification testId={'no-connection'} />
                    </div>
                )}
                {newTermsCalculated && (
                    <Notification
                        status={NotificationStatus.warning}
                        headline={t('warning-notification.title')}
                        text={t('warning-notification.text')}
                        className={'u-mb'}
                    />
                )}
                <Modal
                    shown={messageType === MessageType.ERROR}
                    status="error"
                    title={t('modal.error.title')}
                    onClose={handleModalClose}
                    onCancel={handleModalClose}
                    onClickOutside={handleModalClose}
                    buttonCancelText={t('modal.error.button')}
                    testId={'error-modal'}
                >
                    {t('modal.error.description')}
                </Modal>
                <Modal
                    shown={messageType === MessageType.SUCCESS}
                    status="success"
                    title={t('modal.success.title')}
                    onClose={handleModalClose}
                    onConfirm={handleSuccessModalConfirm}
                    onClickOutside={handleModalClose}
                    buttonConfirmText={t('modal.success.button')}
                    testId={'success-modal'}
                >
                    {t('modal.success.description')}
                </Modal>
                <ButtonContainer center>
                    <Button secondary onClick={(): void => history.push(dashboardPagePath())} testId="cancelButton">
                        {t('cancel-button')}
                    </Button>
                    <Button
                        testId="submitButton"
                        type="btn"
                        onClick={acceptChangeContractDuration}
                        disabled={isEmpty(newTermsCalculated)}
                    >
                        {t('submit-button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
