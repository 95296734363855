import { createGetContractBasedDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { VehicleServiceResponse } from '@cp-shared-5/apis';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    VehicleServiceResponse,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'vehicleDetails',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then((response) => response.data);
    },
});

export default reducer;
export const fetchVehicleDetails = fetchData;
