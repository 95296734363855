import React from 'react';

import { SimplePage } from '@cp-sk/common';
import { HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';

export const PrivacyPolicyUi: React.FC<{ privacyPolicy?: SimplePage }> = ({ privacyPolicy }) => {
    if (!privacyPolicy) {
        return null;
    }
    const { teaser, content } = privacyPolicy;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={false} />
    ) : null;

    const title = content?.title ? <h3>{content.title}</h3> : null;

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">
                {title}
                <div dangerouslySetInnerHTML={{ __html: content.text }} />
            </ContentSection>
        </>
    );
};
