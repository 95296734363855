import { MyDocument } from '@cp-sk/common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const DocumentOneWithAllValues: MyDocument = {
    documentType: 'VWFS zmluvná dokumentácia',
    productName: 'Product name one',
    categoryType: 'Dokumenty týkajúce sa uzavretia zmluvy',
    contractNumber: '1000',
    uploadDate: formatCpDate('1990-01-06').toCpDate(),
    brandModelType: 'Audi S3 2.0 TFSI',
    licensePlate: 'BA 987PA ',
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentTwoWithAllValues: MyDocument = {
    documentType: 'Document type one',
    productName: 'Product name two',
    categoryType: 'Category type one',
    contractNumber: '2000',
    uploadDate: formatCpDate('1991-02-06').toCpDate(),
    brandModelType: 'Audi S3 2.0 TFSI',
    licensePlate: 'BA 987PA ',
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentThreeWithAllValues: MyDocument = {
    documentType: 'Document type two',
    productName: 'Product name three',
    categoryType: 'Category type two',
    contractNumber: '123123',
    uploadDate: formatCpDate('1990-03-06').toCpDate(),
    brandModelType: 'Audi S3 2.0 TFSI',
    licensePlate: 'BA 987PA ',
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentFourWithAllValues: MyDocument = {
    documentType: 'Document type two',
    productName: 'Product name three',
    categoryType: 'Category type two',
    contractNumber: '123123',
    uploadDate: formatCpDate('2020-06-06').toCpDate(),
    brandModelType: 'Audi S3 2.0 TFSI',
    licensePlate: 'BA 987PA ',
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentFiveWithAllValues: MyDocument = {
    documentType: 'Document type one',
    productName: 'Product name four',
    categoryType: 'Category type one',
    contractNumber: '3000',
    uploadDate: formatCpDate('2020-01-06').toCpDate(),
    brandModelType: 'Audi S3 2.0 TFSI',
    licensePlate: 'BA 987PA ',
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentWithMissingBrandModelType: MyDocument = {
    documentType: 'Document type one',
    productName: 'Product without brandModelType',
    categoryType: 'Category type one',
    contractNumber: '5000',
    uploadDate: formatCpDate('2020-01-06').toCpDate(),
    licensePlate: 'BA 987PA ',
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentWithMissinglicensePlate: MyDocument = {
    documentType: 'Document type one',
    productName: 'Product without licensePlate',
    categoryType: 'Category type one',
    contractNumber: '6000',
    uploadDate: formatCpDate('2020-01-06').toCpDate(),
    brandModelType: 'Audi S3 2.0 TFSI',
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentWithMissingValues: MyDocument = {
    documentType: 'Document type with missing values',
    productName: 'Product name with missing values',
    categoryType: 'Category type with missing values',
    contractNumber: '4000',
    uploadDate: formatCpDate('1990-04-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentWithoutContractNumber: MyDocument = {
    documentType: 'Document type without contract',
    categoryType: 'Category type without contract',
    uploadDate: formatCpDate('2020-10-06').toCpDate(),
    invoiceAmount: 2000,
    invoiceDueDate: formatCpDate('1991-02-06').toCpDate(),
    _downloadLink: '/postbox-document-123-download',
};

export const DocumentsWithAllValues: MyDocument[] = [
    DocumentOneWithAllValues,
    DocumentTwoWithAllValues,
    DocumentThreeWithAllValues,
    DocumentFourWithAllValues,
    DocumentFiveWithAllValues,
];

export const DocumentsWithMissigValues: MyDocument[] = [DocumentWithMissingValues];

export const DocumentsEmptyList: MyDocument[] = [];
