import {
    AbstractDataState,
    useGetContractBasedApiData,
    hasFetchedSuccessfully,
} from '@cp-shared-5/frontend-integration';
import { BundledProducts } from '@cp-sk/common';
import { useContract } from 'components/contracts/useContract';
import { fetchBundledProducts } from './BundledProductsSlice';
import { BundledProductsDataSelector } from './BundledProductsDataSelector';
import { useMemo } from 'react';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

export function useBundledProducts(
    contractNumber: string,
): AbstractDataState<BundledProducts, DefaultBusinessMarketApiErrorCode> {
    const contractState = useContract(contractNumber);
    const bundledProductsState = useGetContractBasedApiData(
        contractNumber,
        fetchBundledProducts,
        BundledProductsDataSelector,
        contractState.data?._links?.bundledProducts,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || bundledProductsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && bundledProductsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? bundledProductsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: bundledProductsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? bundledProductsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, bundledProductsState],
    );
}
