import { MyDocumentsLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { getUrlSearchParams } from 'utils/getUrlSearchParams';
import { MyDocumentsUi } from './ui';
import { useMyDocuments } from './useMyDocuments';

const MyDocumentsWithHandlers = withLoadingAndNoConnectionHandler(MyDocumentsUi);

export const MyDocuments: React.FC = () => {
    const { data: myDocumentsData, isLoading, loadingError } = useMyDocuments();
    const contractNumber = getUrlSearchParams().get('filter') || '';
    const docType = getUrlSearchParams().get('docType') || '';
    useAnalyticsPageViewTracker('postbox', !loadingError);

    return (
        <MyDocumentsWithHandlers
            isLoading={isLoading}
            myDocumentsData={myDocumentsData}
            contractNumber={contractNumber}
            docType={docType}
            hasError={!!loadingError}
            loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
        />
    );
};
