import React from 'react';
import { Pagination } from '@vwfs-bronson/bronson-react';

type PagingProps = {
    items: number;
    itemsPerPage: number;
    activePage: number;
    onPageClick: (page: number) => void;
};

export const Paging: React.FC<PagingProps> = ({ items, itemsPerPage, activePage, onPageClick }) => {
    if (items <= itemsPerPage) return null;
    const pages = Math.ceil(items / itemsPerPage);
    const onPrevClick = (): void => {
        onPageClick(activePage - 1);
    };
    const onNextClick = (): void => {
        onPageClick(activePage + 1);
    };
    const paginationItems = new Array(pages).fill(null).map((_, index) => {
        const pageNumber = ++index;
        return (
            <Pagination.Item
                key={pageNumber}
                active={pageNumber === activePage}
                onClick={(e: MouseEvent): void => {
                    onPageClick(pageNumber);
                    e.preventDefault();
                }}
            >
                {pageNumber}
            </Pagination.Item>
        );
    });

    return (
        <div className={'u-mt-small o-split'}>
            <div></div>
            <Pagination
                prevDisabled={activePage === 1}
                nextDisabled={activePage === pages}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
            >
                {paginationItems}
            </Pagination>
            <div></div>
        </div>
    );
};
