import { CustomerType } from '@cp-sk/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-5/frontend-integration';
import { fetchCustomerType } from './CustomerTypeSlice';
import { CustomerTypeDataSelector } from './CustomerTypeDataSelector';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

export function useCustomerType(
    isAuthenticated?: boolean,
): AbstractDataState<CustomerType, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(
        fetchCustomerType,
        CustomerTypeDataSelector,
        false,
        undefined,
        undefined,
        isAuthenticated,
    );
}
