export type ProofOfCarOwnershipFormValues = {
    licensePlate: string;
    numberOfTechnicalDocument: string;
    yearOfProduction: string;
    dateOfFirstRegistrationInSk: string;
    frontOfDocument: File[];
    backOfDocument: File[];
};

export const getInitialValues = (): ProofOfCarOwnershipFormValues => ({
    licensePlate: '',
    numberOfTechnicalDocument: '',
    yearOfProduction: '',
    dateOfFirstRegistrationInSk: '',
    frontOfDocument: [],
    backOfDocument: [],
});
