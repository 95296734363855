import React from 'react';
import { ValidatedSelectItem, ValidatedSelect } from '@cp-shared-5/frontend-ui';
import Insurers from './isurers.json';

type InsurerSelectionProps = {
    name: string;
    testId: string;
    label: string;
};

export const InsurerSelection: React.FC<InsurerSelectionProps> = ({ name, testId, label }) => {
    const options: ValidatedSelectItem[] = Insurers.map(({ name }, index) => ({
        testId: `insurer-selection-item-${index}`,
        value: name,
        key: name,
        label: name,
    }));

    return (
        <>
            <div className={'u-text-fs-1 u-mb-xxsmall'}>{label}</div>
            <ValidatedSelect name={name} testId={testId} selectItems={options} emptyByDefault />
        </>
    );
};
