import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerType, IdCardType } from '@cp-sk/common';
import { DefinitionListItem, withAutoScroll } from '@cp-shared-5/frontend-ui';
import { FormValues as PrivateFormValues } from '../private-form/initialValues';
import { FormValues as BusinessFormValues } from '../company-form/initialValues';
import { ConfirmationView } from '../../../../confirmation-view';

export type ConsultViewProps = {
    customerType: CustomerType;
    newData: PrivateFormValues | BusinessFormValues;
    onConfirm: () => void;
    onClose: () => void;
};

export const SummaryView: React.FC<ConsultViewProps> = withAutoScroll(
    ({ customerType, newData, onConfirm, onClose }) => {
        const { t } = useTranslation(['my-profile', 'id-card-type']);
        const translationPrefix = `identification.edit-view.${customerType.toLowerCase()}.summary-view`;

        const getIdentificationList = (): DefinitionListItem[] => {
            const noValuePlaceholder = '-';
            let prefix;
            const mapCardType = (cardType: IdCardType): string => {
                return {
                    [IdCardType.NATIONAL_ID]: 'national-id',
                    [IdCardType.PASSPORT]: 'passport',
                    [IdCardType.RESIDENCE_CARD]: 'residence-card',
                }[cardType];
            };
            switch (customerType) {
                case 'Private':
                    prefix = `identification.consult-view.items.label.privateCustomerData`;
                    const { title, firstName, surname, idCard } = newData as PrivateFormValues;
                    return [
                        {
                            label: t(`${prefix}.title`),
                            value: title ?? noValuePlaceholder,
                            testId: 'identification-details-title',
                        },
                        {
                            label: t(`${prefix}.firstName`),
                            value: firstName ?? noValuePlaceholder,
                            testId: 'identification-details-first-name',
                        },
                        {
                            label: t(`${prefix}.surname`),
                            value: surname ?? noValuePlaceholder,
                            testId: 'identification-details-surname',
                        },
                        {
                            label: t(`${prefix}.idCardType`),
                            value: t(`id-card-type:${mapCardType(idCard.type as IdCardType)}`) ?? noValuePlaceholder,
                            testId: 'identification-details-id-card-type',
                        },
                        {
                            label: t(`${prefix}.idCardNumber`),
                            value: idCard.number ?? noValuePlaceholder,
                            testId: 'identification-details-id-card-number',
                        },
                        {
                            label: t(`${prefix}.idCardIssueDate`),
                            value: idCard.issueDate ?? noValuePlaceholder,
                            testId: 'identification-details-id-card-issue-date',
                        },
                        {
                            label: t(`${prefix}.idCardIssueCity`),
                            value: idCard.issueCity ?? noValuePlaceholder,
                            testId: 'identification-details-id-card-issue-city',
                        },
                    ];
                case 'Business':
                    prefix = `identification.consult-view.items.label.businessCustomerData`;
                    const { companyName, vatNumber } = newData as BusinessFormValues;
                    return [
                        {
                            label: t(`${prefix}.companyName`),
                            value: companyName ?? noValuePlaceholder,
                            testId: 'identification-details-company-name',
                        },
                        {
                            label: t(`${prefix}.vatNumber`),
                            value: vatNumber ?? noValuePlaceholder,
                            testId: 'identification-details-vat',
                        },
                    ];
                default:
                    return [];
            }
        };

        return (
            <ConfirmationView
                items={getIdentificationList()}
                onConfirm={onConfirm}
                onCancel={onClose}
                subHeadline={t(`${translationPrefix}.headline`)}
            />
        );
    },
    'ChangeOfAddressSummaryView',
);
