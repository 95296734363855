import {
    HeroImage,
    openInSameWindow,
    StaticPageLoadingPlaceholder,
    useAnalyticsErrorPageTracker,
} from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useUnauthorizedErrorPage } from './useUnauthorizedErrorPage';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const UnauthorizedErrorPage: React.FC = () => {
    const { cmsContent: unauthorizedErrorPage, isLoading, loadingError } = useUnauthorizedErrorPage();

    useAnalyticsErrorPageTracker('unauthorizedError', !!unauthorizedErrorPage);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={unauthorizedErrorPage?.title || ''}
            subTitle={unauthorizedErrorPage?.subTitle}
            buttonText={unauthorizedErrorPage?.buttonText}
            clickHandler={openInSameWindow(unauthorizedErrorPage?.buttonUrl)}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
            inverted
        />
    );
};
