import { useGetContractBasedApiData } from '@cp-shared-5/frontend-integration';
import { AmortizationTableLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { dashboardPagePath } from 'components/navigation/paths';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContractDetails } from '../contracts/contract-details';
import { useContract } from '../contracts/useContract';
import { AmortizationDetailsDataSelector } from './AmortizationTableSelector';
import { fetchAmortizationDetails } from './AmortizationTableSlice';
import { AmortizationTableUi } from './ui/AmortizationTableUi';

const AmortizationTableWithHandlers = withLoadingAndNoConnectionHandler(AmortizationTableUi);

export type AmortizationTableProps = {
    encryptedContractNumber: string;
};

export const AmortizationTable: React.FC<AmortizationTableProps> = ({ encryptedContractNumber }) => {
    const [loadingStarted, setLoadingStarted] = useState(false);
    const history = useHistory();

    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractNumber, { encryptedContractNumber: true });

    const onLoading = useCallback(() => {
        if (isLoadingContracts) setLoadingStarted(true);
    }, [isLoadingContracts]);

    useEffect(() => {
        onLoading();
    }, [onLoading]);

    const checkContract = useCallback(() => {
        if (loadingStarted && !isLoadingContracts && !loadingErrorContract && !contract) {
            history.push(dashboardPagePath());
        }
    }, [isLoadingContracts, loadingErrorContract, loadingStarted, contract, history]);

    useEffect(() => {
        checkContract();
    }, [checkContract]);

    const {
        data: contractDetails,
        isLoading: isLoadingContractDetails,
        loadingError: loadingErrorContractDetails,
    } = useContractDetails(contract?.contractNumber || '');

    const {
        data: amortizationDetails,
        isLoading: isLoadingAmortizationDetails,
        loadingError: loadingErrorAmortizationDetails,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchAmortizationDetails,
        AmortizationDetailsDataSelector,
        contract?._links?.amortizationDetails,
    );

    useAnalyticsPageViewTracker(
        'amortizationTable',
        !!contract && !!amortizationDetails && !!contractDetails?.financial,
    );

    const isLoading = isLoadingContracts || isLoadingAmortizationDetails || isLoadingContractDetails;
    const hasError = !!loadingErrorContract || !!loadingErrorAmortizationDetails || !!loadingErrorContractDetails;

    return (
        <AmortizationTableWithHandlers
            contract={contract}
            amortizationDetails={amortizationDetails}
            financialDetails={contractDetails?.financial}
            isLoading={isLoading}
            hasError={!!hasError}
            loadingPlaceholder={<AmortizationTableLoadingPlaceholder tileElements={3} />}
        />
    );
};
