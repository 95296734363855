import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { PartialEarlySettlement } from 'components/contracts/early-settlement/partial-early-settlement';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const PartialEarlySettlementPage: React.FC = () => {
    const { t } = useTranslation('partial-early-settlement');
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();
    const title = t('headline');
    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <PartialEarlySettlement encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
