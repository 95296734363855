import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { MarketingCardUi } from '../../marketing-card-ui';
import { useRequestMarketingCard } from './useRequestMarketingCard';

const RequestMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardUi);

export const RequestMarketingCard: React.FC = () => {
    const { cmsContent: requestMarketingCard, isLoading, loadingError } = useRequestMarketingCard();

    return (
        <RequestMarketingCardWithHandlers
            isLoading={isLoading}
            marketingCards={requestMarketingCard}
            hasError={!!loadingError}
        />
    );
};
